<template id="partiteaggiungi">
	<section class="partiteaggiungi">
		
		<headapp></headapp>

		<b-overlay :show="loading" no-wrap>
		</b-overlay>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="card-title">
					{{label}}
					<div class="pull-right">
						<b-dropdown variant="outline-primary" v-if="auth.isUserEnable('match_lock') || auth.isUserEnable('accesso_convocazioni') || auth.isUserEnable('accesso_formazioni')" class="mr-1">
							<template #button-content>
								<i class="fa fa-lock" aria-hidden="true"></i>
							</template>
							<b-dropdown-item v-if="auth.isUserEnable('match_lock')" @click="form.partita.enabled ? disabilita() : ripristina()"> <i v-if="form.partita.enabled" class="fa fa-unlock" aria-hidden="true"></i><i v-if="!form.partita.enabled" class="fa fa-lock" aria-hidden="true"></i> {{ form.partita.enabled ? 'blocca modifiche partita' : 'sblocca modifiche partita' }}</b-dropdown-item>
							<b-dropdown-item v-if="auth.isUserEnable('accesso_convocazioni')" @click="rawPartita.isHideConvocazione == true ? mostraConvocazioni() : nascondiConvocazioni()"><i v-if="rawPartita.isHideConvocazione == false" class="fa fa-unlock" aria-hidden="true"></i> <i v-if="rawPartita.isHideConvocazione == true" class="fa fa-lock" aria-hidden="true"></i> {{ rawPartita.isHideConvocazione == true ? 'mostra convocazioni' : 'nascondi convocazioni' }}</b-dropdown-item>
							<b-dropdown-item v-if="auth.isUserEnable('accesso_formazioni')" @click="rawPartita.isHideFormazione == true ? mostraFormazione() : nascondiFormazione()"><i v-if="rawPartita.isHideFormazione == false" class="fa fa-unlock" aria-hidden="true"></i> <i v-if="rawPartita.isHideFormazione == true" class="fa fa-lock" aria-hidden="true"></i> {{ rawPartita.isHideFormazione == true ? 'mostra formazione' : 'nascondi formazione' }}</b-dropdown-item>
						</b-dropdown>
						<b-button variant="outline-primary" v-if="auth.isUserEnable('football_partite_mng') && auth.isLastSeason(season_id)" @click="elimina" :disabled="!form.partita.enabled">Elimina</b-button>
						<b-dropdown variant="outline-primary" class="mr-1">
							<template #button-content>
								+Info
							</template>
							<b-dropdown-item v-b-toggle.infoMatch >Dati della partita</b-dropdown-item>
							<b-dropdown-item v-b-toggle.staff >Staff</b-dropdown-item>
							<b-dropdown-item v-b-toggle.arbitri >Arbitri</b-dropdown-item>
							<b-dropdown-item v-b-toggle.disciplinare >Disciplinare</b-dropdown-item>
							<b-dropdown-item v-b-toggle.providers v-if="auth.isUserEnable('partita_providers')">Providers</b-dropdown-item>
							<b-dropdown-item target="_blank" v-if="auth.isUserEnable('edit_redazione') && rosaTeamId !== 13 && redazione" @click.prevent="ux.rankAndResults = !ux.rankAndResults">Gestisci Risultati Giornata</b-dropdown-item>
						</b-dropdown>
						<b-button variant="outline-primary" :disabled="rawPartita.hideConvocazione" v-b-toggle.maggioriInfoPartita >Convocazione</b-button>
						<b-dropdown variant="outline-primary" v-if="auth.isUserEnable('video_partite') || auth.isUserEnable('prepost_partita')" class="mr-1">
							<template #button-content>
								<i class="fa fa-video-camera" aria-hidden="true"></i> Media
							</template>
							<b-dropdown-item v-if="auth.isUserEnable('video_partite')" :to="{ name: 'football_video_partita', params: { id: $route.params.id }}">Video</b-dropdown-item>
							<b-dropdown-item v-if="auth.isUserEnable('prepost_partita')" :to="{ name: 'football_pre_partita', params: { id: $route.params.id }}">Pre partita</b-dropdown-item>
							<b-dropdown-item v-if="auth.isUserEnable('prepost_partita')" :to="{ name: 'football_post_partita', params: { id: $route.params.id }}">Post partita</b-dropdown-item>
						</b-dropdown>
						<b-dropdown variant="outline-primary" class="mr-1">
							<template #button-content>
								<i class="fa fa-file-pdf-o" aria-hidden="true"></i> Stampa
							</template>
							<b-dropdown-item :disabled="rawPartita.hideFormazione" @click="downloadPdf">Cartellino</b-dropdown-item>
							<b-dropdown-item :disabled="rawPartita.hideFormazione" @click="distintaPdf">Distinta</b-dropdown-item>
							<b-dropdown-item :disabled="rawPartita.hideFormazione" @click="distintaSingolaPdf">Distinta Singola</b-dropdown-item>
							<b-dropdown-item v-if="auth.isUserEnable('distinta_preview')" @click="anteprimaDistinta">Anteprima distinta</b-dropdown-item>
							<b-dropdown-item :disabled="rawPartita.hideConvocazione" @click="convocatiPdf">Convocati</b-dropdown-item>
							<b-dropdown-item @click="arbitriPdf">Arbitri</b-dropdown-item>
						</b-dropdown>
						<b-button variant="outline-primary" :to="{ name: 'football_sessione', params: { id: training_id, tipo: 15 }}" v-if="auth.isUserEnable('football_allenamenti') && training_id"> <i class="fa fa-clock-o" aria-hidden="true"></i> Allenamento 
						</b-button>
					</div>
					<div style="clear:right"></div>	
				</div>
				<div class="content">
					<form @submit.stop.prevent="form.partita.enabled ? handleSubmit() : ''">

						<b-collapse id="infoMatch" class="mb-3">
							<div class="row">
								<div class="col">

									<div class="row">
										<div class="col" v-if="!rawPartita.hideConvocazione && !rawPartita.hideFormazione">
											<fieldset class="form-group">
												<label>Data della partita</label>
												<date-picker :date="form.partita.date" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.partita.date.time" :disabled="true"></b-form-input>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Fuso orario</label>
												<b-form-input v-model="form.partita.fuso_orario" :disabled="!form.partita.enabled" type="number"></b-form-input>
											</fieldset>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Tipo partita</label>
												<multiselect v-model="form.partita.type" :options="opts_partite" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false" :disabled="!form.partita.enabled || (form.partita.type && form.partita.type.id == 5)"></multiselect>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Turno</label>
												<b-form-select v-model="form.partita.day" :disabled="!form.partita.enabled">
													<option :value="null">seleziona</option>
													<option v-for="(value, index) in 99" :value="value" :key="'ng_' + index">{{value}}</option>
												</b-form-select>
											</fieldset>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Competizione</label>
												<b-form-select v-model="form.partita.competition_id" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option v-for="comp in filtered_competizioni" :value="comp.id">{{ comp.nome }}</option>
												</b-form-select>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Fase</label>
												<b-form-select v-model="form.partita.fase_id" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option v-for="fase in competizione_fasi" :value="fase.id">{{ fase.nome }}</option>
												</b-form-select>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Stadio</label>
												<b-form-input type="text" v-model="form.partita.stadium" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Interruzione/Recupero</label>
												<b-form-select v-model="form.partita.start_stop_status" :disabled="!form.partita.enabled">
													<option :value="null">Partita giocata regolarmente</option>
													<option v-for="item in tipi_start_stop" :value="item.id">{{ item.nome }}</option>
												</b-form-select>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.start_stop_status == 'stop'">
												<label>Interrota al minuto</label>
												<input class="form-control" type="number" min="0" max="90" v-model="form.partita.stopped_at_minute" :disabled="!form.partita.enabled">
											</fieldset>
											<fieldset class="form-group" v-else-if="form.partita.start_stop_status == 'restart'">
												<label>Ripresa dal minuto</label>
												<input class="form-control" type="number" min="0" max="90" v-model="form.partita.restarted_from_minute" :disabled="!form.partita.enabled">
											</fieldset>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<b-form-checkbox :disabled="!form.partita.enabled" v-model="form.partita.has_rigori" :value="1" :unchecked-value="0">
													Calci di rigore
												</b-form-checkbox>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<b-form-checkbox :disabled="!form.partita.enabled" v-model="form.partita.has_supplementari" :value="1" :unchecked-value="0">
													Tempi supplementari
												</b-form-checkbox>
											</fieldset>	
										</div>
									</div>
								</div>
								<div class="col">
									<div class="row" >
										<div class="col">
											<fieldset class="form-group">
												<label>Dove si gioca la partita</label>
												<b-form-select v-model="form.partita.home" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option value="0">casa</option>
													<option value="1">trasferta</option>
												</b-form-select>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Città</label>
												<b-form-input type="text" v-model="form.partita.citta" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>
										</div>
									</div>
									<div class="row" v-if="form.partita.type && form.partita.type.id == 5">
										<div class="col">
											<fieldset class="form-group">
												<label>Nazionale casa / Goal</label>
												<b-row>
													<b-col>
														<b-form-input type="text" v-model="form.partita.team_home"></b-form-input>
													</b-col>
													<b-col cols="3">
														<b-form-input type="text" v-model="form.partita.score_home"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Nazionale ospite / Goal</label>
												<b-row>
													<b-col>
														<b-form-input type="text" v-model="form.partita.team_away"></b-form-input>
													</b-col>
													<b-col cols="3">
														<b-form-input type="text" v-model="form.partita.score_away"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>
										</div>
									</div>

									<fieldset class="form-group" v-else>
										<label>Avversario</label>
										<b-row>
											<b-col v-if="form.partita.opponent_team">
												<b-form-input type="text" v-model="form.partita.opponent_team.name" disabled></b-form-input>
											</b-col>
											<b-col>
												<b-button variant="outline-primary" style="width:100%;" @click="selectTeam" :disabled="!form.partita.enabled"> cambia avversario <i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
											</b-col>
										</b-row>
									</fieldset>

									<fieldset class="form-group" v-if="form.partita.type && form.partita.type.id == 5">
										<label>Durata partita</label>
										<input class="form-control" type="number" min="1" 
											v-model="form.partita.duration" 
											:disabled="!form.partita.enabled"
											@keyup="updateDuration('from_total')"
										>
									</fieldset>	
									<div class="row" v-else>
										<div class="col">
											<fieldset class="form-group">
												<label>Durata partita</label>
												<input class="form-control" type="number" min="1" 
													v-model="form.partita.duration" 
													:disabled="!form.partita.enabled"
													@keyup="updateDuration('from_total')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>D.1°tempo</label>
												<input class="form-control" type="number" min="0" 
													v-model="form.partita.duration_first_half" 
													:disabled="!form.partita.enabled"
													:readonly="!['restart','stop'].includes(form.partita.start_stop_status)"
													@keyup="updateDuration('from_partial')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>D.2°tempo</label>
												<input class="form-control" type="number" min="0" 
													v-model="form.partita.duration_second_half" 
													:disabled="!form.partita.enabled"
													:readonly="!['restart','stop'].includes(form.partita.start_stop_status)"
													@keyup="updateDuration('from_partial')"
												>
											</fieldset>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.has_supplementari">
												<label>D.supplementari</label>
												<input class="form-control" type="number" min="1" 
													v-model="form.partita.duration_suppl" 
													:disabled="!form.partita.enabled"
													@keyup="updateDuration('from_total_suppl')"
												>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.has_supplementari">
												<label>D.1°tempo suppl.</label>
												<input class="form-control" type="number" min="1" 
													v-model="form.partita.duration_suppl_first_half" 
													:disabled="!form.partita.enabled"
													:readonly="!['restart','stop'].includes(form.partita.start_stop_status)"
													@keyup="updateDuration('from_partial_suppl')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.has_supplementari">
												<label>D.2°tempo suppl.</label>
												<input class="form-control" type="number" min="1" 
													v-model="form.partita.duration_suppl_second_half" 
													:disabled="!form.partita.enabled"
													:readonly="!['restart','stop'].includes(form.partita.start_stop_status)"
													@keyup="updateDuration('from_partial_suppl')"
												>
											</fieldset>
										</div>
									</div>
								</div>

								<div class="col">
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Condizioni climatiche</label>
												<b-form-select v-model="form.partita.meteo_id" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option v-for="item in meteo" :value="item.id">{{ item.nome }}</option>
												</b-form-select>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Condizioni terreno</label>
												<b-form-select v-model="form.partita.condizioni_campo_id" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option v-for="cond in condizioni_campo" :value="cond.id">{{ cond.nome }}</option>
												</b-form-select>
											</fieldset>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Tipologia manto</label>
												<b-form-select v-model="form.partita.tipologia_campo_id" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option v-for="item in tipi_campo" :value="item.id">{{ item.nome }}</option>
												</b-form-select>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group" :disabled="!form.partita.enabled">
												<label>Diretta TV</label>
												<b-form-input type="text" v-model="form.partita.diretta_tv"></b-form-input>
											</fieldset>	
										</div>
									</div>									
									<!--fieldset class="form-group">
										<label>Diretta TV</label>
										<b-form-input type="text" v-model="form.partita.diretta_tv" :disabled="!form.partita.enabled"></b-form-input>
									</fieldset-->	
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Rec. 1° tempo</label>
												<b-form-input type="number" min="0" v-model="form.partita.recovery_first_half" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Rec. 2° tempo</label>
												<b-form-input type="number" min="0" v-model="form.partita.recovery_second_half" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>	
										</div>
									</div>
									<div class="row" v-if="form.partita.has_supplementari">
										<div class="col">
											<fieldset class="form-group">
												<label>Rec.1°tempo suppl.</label>
												<b-form-input type="number" min="0" v-model="form.partita.recovery_first_half_suppl" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Rec.2°tempo suppl.</label>
												<b-form-input type="number" min="0" v-model="form.partita.recovery_second_half_suppl" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>	
										</div>
									</div>
								</div>
							</div>
						</b-collapse>

						<b-collapse id="staff" class="mb-3">
							<div class="card mb-2">
								<div class="card-body">
									<div class="card-title">Staff</div>
									<div class="row">
										<div class="col pr-5">
											<fieldset class="form-group">
												<div class="mb-2">In campo</div>		
												<b-row v-for="(item, index) in form.partita.staff_titolari" class="mb-2" :key="'stt_' + index">
													<b-col>
														<multiselect v-model="item.staff" :options="staff_opts" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" :key="'sttit_' + index" :disabled="!form.partita.enabled"></multiselect>
													</b-col>
													<b-col cols="1">
														<b-button variant="outline-danger" @click="removeStaffTitolare(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</b-col>
												</b-row>
												<b-button variant="outline-primary" @click="addStaffTitolare()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi membro dello staff</b-button>
											</fieldset>	
										</div>

										<div class="col">
											<fieldset class="form-group">
												<div class="mb-2">Panchina aggiuntiva</div>
												<b-row v-for="(item, index) in form.partita.staff_panchina" class="mb-2" :key="'stp_' + index">
													<b-col>
														<multiselect v-model="item.staff" :options="staff_opts" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" :key="'stpanc_' + index" :disabled="!form.partita.enabled"></multiselect>
													</b-col>
													<b-col cols="1">
														<b-button variant="outline-danger" @click="removeStaffPanchina(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</b-col>
												</b-row>
												<b-button variant="outline-primary" @click="addStaffPanchina()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi membro dello staff</b-button>
											</fieldset>	
										</div>
									</div>
								</div>
							</div>
						</b-collapse>

						<b-collapse id="arbitri" class="mb-3">
							<div class="card mb-2">
								<div class="card-body">

									<div class="card-title">Arbitri</div>
									<div class="row">
										<div class="col pr-5">
											<fieldset class="form-group">
												<label>Arbitro</label>
												<b-row v-for="item, index in form.partita.arbitro" class="mb-1">
													<b-col>
														<autocomplete-nome :suggestions="arbitri" :id="'arbitro' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'arbitro'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="arbitro" :disabled="!form.partita.enabled" :model="item.cognome"></autocomplete-nome>

													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.nome" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.sezione" placeholder="Sezione" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.stato" placeholder="Stato" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>	
											<fieldset class="form-group">
												<label>1° Assistente</label>
												<b-row v-for="item, index in form.partita.primo_assistente" class="mb-1">
													<b-col>
														<autocomplete-nome :suggestions="arbitri" :id="'primo_assistente' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'primo_assistente'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="primo_assistente" :disabled="!form.partita.enabled" :model="item.cognome"></autocomplete-nome>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.nome" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.sezione" placeholder="Sezione" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.stato" placeholder="Stato" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>	
											<fieldset class="form-group">
												<label>2° Assistente</label>
												<b-row v-for="item, index in form.partita.secondo_assistente" class="mb-1">
													<b-col>
														<autocomplete-nome :suggestions="arbitri" :id="'secondo_assistente' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'secondo_assistente'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="secondo_assistente" :disabled="!form.partita.enabled" :model="item.cognome"></autocomplete-nome>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.nome" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.sezione" placeholder="Sezione" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.stato" placeholder="Stato" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>	
										</div>

										<div class="col">
											<fieldset class="form-group">
												<label>4° Ufficiale</label>
												<b-row v-for="item, index in form.partita.quarto_ufficiale" class="mb-1">
													<b-col>
														<autocomplete-nome :suggestions="arbitri" :id="'secondo_assistente' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'quarto_ufficiale'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="quarto_ufficiale" :disabled="!form.partita.enabled" :model="item.cognome"></autocomplete-nome>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.nome" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.sezione" placeholder="Sezione" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.stato" placeholder="Stato" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>	
											<fieldset class="form-group">
												<label>VAR</label>
												<b-row v-for="item, index in form.partita.var" class="mb-1">
													<b-col>
														<autocomplete-nome :suggestions="arbitri" :id="'var' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'var'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="var" :disabled="!form.partita.enabled" :model="item.cognome"></autocomplete-nome>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.nome" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.sezione" placeholder="Sezione" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.stato" placeholder="Stato" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>	
											<fieldset class="form-group">
												<label>AVAR <b-button size="xs" :disabled="!form.partita.enabled" @click="addArbitro('avar')"><i class="fa fa-plus" aria-hidden="true"></i></b-button></label>
												<b-row v-for="item, index in form.partita.avar" class="mb-1">
													<b-col>
														<autocomplete-nome :suggestions="arbitri" :id="'avar' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'avar'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="avar" :disabled="!form.partita.enabled" :model="item.cognome"></autocomplete-nome>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.nome" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.sezione" placeholder="Sezione" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="item.stato" placeholder="Stato" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</fieldset>	
										</div>
									</div>
								</div>
							</div>
						</b-collapse>

						<b-collapse id="disciplinare" class="mb-3">
							<div class="card mb-2">
								<div class="card-body">

									<div class="card-title">Area Disciplinare</div>
									<div class="row">
										<div class="col-6 pr-3">
											<fieldset class="form-group">
												<label>Data del comunicato</label>
												<b-form-input type="date" v-model="form.partita.data_comunicato" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>
											<fieldset class="form-group">
												<label>Allegati</label>
												<DocumentiManager collapsed="false" entity="Partita" :entityId="$route.params.id" :tipoId="16" path="" :readonly="!form.partita.enabled"></DocumentiManager>
											</fieldset>
											
										</div>
										<div class="col-6 pl-3 border-left">
											<div class="row">
												<div class="col-8">
													Giocatori
												</div>
												<div class="col-4">
													Giorni di squalifica
												</div>
											</div>
											<div class="row align-items-end" v-for="(item, index) in form.partita.es" :key="'es_days_' + index" v-if="item.player && item.player.id">
												<div class="col-8 form-group">
													<b-form-input v-model="item.player.name" readonly></b-form-input>
												</div>
												<div class="col-4 form-group">
													<b-form-input type="number" :min="0" v-model="item.days" :disabled="!form.partita.enabled"></b-form-input>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-collapse>

						<b-collapse id="providers" class="mb-3" v-if="auth.isUserEnable('partita_providers')">
							<div class="card mb-2">
								<div class="card-body">
									<div class="card-title">Providers</div>
									<entita-api-manager :entityId="$route.params.id" entity="Partita" :readonly="!auth.isUserEnable('partita_providers_edit')"/>
								</div>
							</div>
						</b-collapse>
						
						<b-collapse id="maggioriInfoPartita" class="mb-3">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-8 pr-3">
											<label>Lista Giocatori</label>
											<div class="list-group">
												<div class="list-group-item d-flex align-items-center" v-for="(player, index) in players" :key="'conv_' + player.id">
													<span class="lead mr-auto" :title="player.prova ? 'In prova' : ''">{{player.name}} {{ player.prova ? '(*)' : ''}}</span>
													<b-form-radio-group :name="'convocated_'+index" v-model="form.convocazione.convocated[player.id]" :disabled="!form.partita.enabled">
														<b-form-radio value="convocato">convocato</b-form-radio>
														<b-form-radio value="non convocato">non convocato</b-form-radio>
														<b-form-radio value="recupero">Recupero</b-form-radio>
													</b-form-radio-group>
												</div>
											</div>
										</div>
										<div class="col-4 pl-3 border-left">
											<fieldset class="form-group">
												<label>Tipo partita</label>
												<multiselect v-model="form.partita.type" :options="opts_partite" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false" :disabled="!form.partita.enabled || (form.partita.type && form.partita.type.id == 5)"></multiselect>
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Turno</label>
												<b-form-select v-model="form.partita.day" :disabled="!form.partita.enabled">
													<option :value="null">seleziona</option>
													<option v-for="(value, index) in 99" :value="value" :key="'ng_' + index">{{value}}</option>
												</b-form-select>
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Durata partita</label>
												<input class="form-control" type="text" 
													v-model="form.partita.duration" 
													:disabled="!form.partita.enabled"
													@keyup="updateDuration('from_total')"
												>
											</fieldset>
											<fieldset class="form-group col-sm-6">
												<label>Numero giocatori per squadra</label>
												<multiselect v-model="form.partita.players" :options="opts_num_player_partite" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false" :disabled="!form.partita.enabled"></multiselect>
											</fieldset>
											<hr/>
											<fieldset class="form-group">
												<label>Data della partita</label>
												<date-picker :date="form.partita.date" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.partita.date.time" :disabled="true"></b-form-input>
											</fieldset>
											<fieldset class="form-group">
												<label>Dove si gioca la partita</label>
												<div>
													<div class="form-check-inline">
														<label class="form-check-label">
															<input type="radio" value="0" class="form-check-input" v-model="form.partita.home" :disabled="!form.partita.enabled"> Casa
														</label>
													</div>
													<div class="form-check-inline">
														<label class="form-check-label">
															<input type="radio" value="1" class="form-check-input" v-model="form.partita.home" :disabled="!form.partita.enabled"> Trasferta
														</label>
													</div>
												</div>
											</fieldset>
											<fieldset class="form-group">
												<label>Luogo</label>
												<input ref="autocomplete" class="form-control" type="text" v-model="form.partita.luogo" :disabled="!form.partita.enabled">
											</fieldset>
											<b-row>
												<b-col>
													<div id="map" ref="map" style="height: 250px;"></div>
												</b-col>
											</b-row>
											<hr />
											<fieldset class="form-group">
												<label>Dress code</label>
												<b-form-input v-model="form.convocazione.dress_code" :disabled="!form.partita.enabled"></b-form-input>	
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Arrivo</label>
												<date-picker :date="form.convocazione.arrive" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.convocazione.arrive.time" :disabled="true"></b-form-input>
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Riunione tecnica</label>
												<date-picker :date="form.convocazione.technical_meeting" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.convocazione.technical_meeting.time" :disabled="true"></b-form-input>
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Pasto </label>
												<date-picker :date="form.convocazione.meal" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.convocazione.meal.time" :disabled="true"></b-form-input>
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Partenza dallo stadio</label>
												<date-picker :date="form.convocazione.stadium_departure" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.convocazione.stadium_departure.time" :disabled="true"></b-form-input>
											</fieldset>
											<fieldset class="form-group col-6">
												<label>Ritorno</label>
												<date-picker :date="form.convocazione.return_base" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.convocazione.return_base.time" :disabled="true"></b-form-input>
											</fieldset>
											<fieldset class="form-group">
												<label>Note</label>
												<b-form-textarea v-model="form.convocazione.note" :disabled="!form.partita.enabled" :rows="3"></b-form-textarea>	
											</fieldset>
											<!--hr />
											<fieldset class="form-group">
												<label>Allenamento per i nn convocati</label>
												<multiselect v-model="form.convocazione.not_conv_training_id" :options="trainings" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="false" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false" :disabled="!form.partita.enabled"></multiselect>
											</fieldset>
											<fieldset class="form-group">
												<label>Allenamento successivo alla partita</label>
												<multiselect v-model="form.convocazione.post_match_training_id" :options="trainings" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="false" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false" :disabled="!form.partita.enabled"></multiselect>
											</fieldset>
											<fieldset class="form-group">
												<label>Orario Allenamento</label>
												<date-picker :date="form.convocazione.post_match_training" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.convocazione.post_match_training.time" :disabled="true"></b-form-input>
											</fieldset-->
										</div>
									</div>
								</div>
							</div>
						</b-collapse>

						<b-card class="mb-2" v-if="ux.rankAndResults">
							<template slot="header">
								<b-row>
									<b-col>
										Gestione Classifiche &amp; Risultati
									</b-col>
									<b-col cols="3" class="text-right">
										<a href="javascript:;" @click.prevent="ux.rankAndResults = false" class="small">Chiudi</a>
									</b-col>
								</b-row>
							</template>
							<gestione-risultati :competition-id="redazione[0]" :stage-id="redazione[1]" :match-day="form.partita.day" :next-day="form.partita.day+1"></gestione-risultati>
							<!--
							<pre>{{redazione}}</pre>
							<pre>{{form.partita.day}}</pre>
							-->
						</b-card>

						<div v-if="!loading">
							<div class="row">
								<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')" v-if="!rawPartita.hideFormazione">
									<div class="card h-100">
										<div class="card-body">

											<div v-if="form.partita.type && form.partita.type.id == 5" class="card-title">{{ form.partita.team_home ? form.partita.team_home : '&nbsp;' }}</div>
											<div v-else class="card-title">AS. Roma</div>

											<formazione @updatePartita="formazioneUpdate" :modulo="form.partita.modulo" :giocatori="parseInt(form.partita.players.name)"  dove="casa" :disabled="!form.partita.enabled"></formazione>

											<div class="form-group" v-if="!form.partita.type || (form.partita.type && form.partita.type.id != 5)">
												<label>Allenatore</label>
												<b-row>
													<b-col>
														<b-form-select v-model="form.partita.coach_id" :disabled="!form.partita.enabled">
															<option :value="null">Seleziona</option>
															<option v-for="member in staff_members" :value="member.id">{{ member.name  }}</option>
														</b-form-select>
													</b-col>
												</b-row>
											</div>	

											<div :class="'form-group' + (n == num_players ? ' mb-5' : '')" v-for="n in n_players" :key="'p_' + n">
												<div class="row">
													<label class="col-1 text-sm-center col-form-label">
														{{ n }}
													</label>
													<div class="col-9">
														<multiselect v-model="form.partita.match_players[n-1]" :options="options_players_conv" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" @select="selectPlayer" :id="'plsel_' + (n - 1)" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-sm-2">
														<b-form-select v-model="form.partita.match_players_shirt[n-1]" :disabled="!form.partita.enabled">
															<option :value="null">maglia</option>
															<option v-for="s in 99" :value="s" :key="'s_' + s">{{s}}</option>
														</b-form-select>
													</div>
												</div>
											</div>
											<b-button variant="outline-primary" @click="addPLayer" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>

										</div>
									</div>
								</div>

								<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')" v-else>
									<div class="card h-100">
										<div class="card-body">
											<div v-if="form.partita.type && form.partita.type.id == 5" class="card-title">{{ form.partita.team_home ? form.partita.team_home : '&nbsp;' }}</div>
											<div v-else class="card-title">AS. Roma</div>


											<b-alert :visible="true" :show="true" class="mt-2" variant="success">
												Le formazione della partita sarà presto disponibile.
											</b-alert>
										</div>
									</div>
								</div>

								<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
									<div class="card h-100">
										<div class="card-body">

											<div v-if="form.partita.type && form.partita.type.id == 5" class="card-title">{{ form.partita.team_away ? form.partita.team_away : '&nbsp;' }}</div>
											<div v-else class="card-title">{{ form.partita.opponent_team ? form.partita.opponent_team.name : 'Avversario'}}</div>

											<formazione @updatePartita="formazioneUpdateOpponent" :modulo="form.partita.modulo_opponent" dove="fuori" shirt="avversario" :giocatori="parseInt(form.partita.players.name)" :disabled="!form.partita.enabled"></formazione>

											<div class="form-group" v-if="!form.partita.type || (form.partita.type && form.partita.type.id != 5)">
												<label>Allenatore</label>
												<b-row>
													<b-col>
														<b-form-input type="text" v-model="form.partita.opp_coach.name" placeholder="Nome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
													<b-col>
														<b-form-input type="text" v-model="form.partita.opp_coach.surname" placeholder="Cognome" :disabled="!form.partita.enabled"></b-form-input>
													</b-col>
												</b-row>
											</div>
											
											<div :class="'form-group' + (n == num_players ? ' mb-5' : '')" v-for="n in n_opponents" :key="'fo_' + n">
												<div class="row">
													<label class="col-1 text-sm-center col-form-label">
														{{ n }}
													</label>
													<div class="col-9">
														<multiselect v-model="form.partita.match_players_opponent[n - 1]" :options="options_opponents" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false"  @select="selectOpponent" :id="'oplsel_' + (n - 1)" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-sm-2">
														<b-form-select v-model="form.partita.match_players_opponent_shirt[n-1]" :disabled="!form.partita.enabled">
															<option :value="null">maglia</option>
															<option v-for="s in 99" :value="s" :key="'os_' + s">{{s}}</option>
														</b-form-select>
													</div>
												</div>
											</div>
											<b-button variant="outline-primary" @click="addOpponent" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>

											<b-button v-if="(!form.partita.type || (form.partita.type && form.partita.type.id != 5)) && auth.isLastSeason(season_id)" variant="outline-primary" @click="addNewPlayerOpponent" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi nuovo giocatore alla squadra</b-button>

										</div>
									</div>
								</div>
							</div>

							<div v-show="!auth.isUserEnable('match_card_only')">

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title mt-0">Goal</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-5">
														<label>Giocatore</label>
													</div>
													<div class="col-2">
														<label>Rigore</label>
													</div>
													<div class="col-2">
														<label>Autogoal</label>
													</div>
												</div>
												<div class="row" v-for="(item, index) in form.partita.goals" :key="'goal_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'gm_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-5 form-group">

														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'gpl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.penalty" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.autogoal" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeGoal(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addGoal()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Goal</b-button>

											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title mt-0">Goal</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-5">
														<label>Giocatore</label>
													</div>
													<div class="col-2">
														<label>Rigore</label>
													</div>
													<div class="col-2">
														<label>Autogoal</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.goals_opponent" :key="'go_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'gmo_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-5 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<!--option :value="-1">Anonimo</option-->
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'gplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.penalty" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.autogoal" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-primary" @click="removeGoalOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addGoalOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Goal</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1" v-if="form.partita.has_rigori">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title mt-0">Calci di rigore</div>
												<div class="row">
													<div class="col-9">
														<label>Giocatore</label>
													</div>
													<div class="col-2">
														<label>Goal</label>
													</div>
												</div>
												<div class="row" v-for="(item, index) in form.partita.rigori" :key="'goal_' + index">
													<div class="col-9 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'rpl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.goal" :disabled="!form.partita.enabled">
															<option :value="1">si</option>
															<option :value="0">no</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeRigore(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addRigore()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Rigore</b-button>

											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title mt-0">Calci di rigore</div>
												<div class="row">
													<div class="col-9">
														<label>Giocatore</label>
													</div>
													<div class="col-2">
														<label>Goal</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.rigori_opponent" :key="'ro_' + index" >
													<div class="col-9 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<!--option :value="-1">Anonimo</option-->
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'gplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.goal" :disabled="!form.partita.enabled">
															<option :value="1">si</option>
															<option :value="0">no</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-primary" @click="removeRigoreOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addRigoreOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Rigore</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title">Assists</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.assists" :key="'ass_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'mass_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null " v-for="(value, index) in form.partita.match_players" :value="value" :key="'plass_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeAssist(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addAssist()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Assist</b-button>
												
											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Assists</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.assists_opponent" :key="'oass_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'moass_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-if="value != null " v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'oplass_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeAssistOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addAssistOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Assist</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title">Ammonizioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.amm" :key="'amm_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'mamm_' + index">{{ value.label }}</option>
															<option :value="900" key="mamm_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'ammpl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeAmm(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addAmm()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Ammonizione</b-button>
												
											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Ammonizioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.amm_opponent" :key="'ammo_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'ammom_' + index">{{ value.label }}</option>
															<option :value="900" key="ammom_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'ammplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeAmmOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addAmmOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Ammonizione</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title">Espulsioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.es" :key="'es_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esm_' + index">{{ value.label }}</option>
															<option :value="900" key="esm_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'espl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeEs(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addEs()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Espulsione</b-button>
												
											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Espulsioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.es_opponent" :key="'eso_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esmo_' + index">{{ value.label }}</option>
															<option :value="900" key="esmo_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'esplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeEsOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addEsOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Espulsione</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title">Sostituzioni</div>
												<div class="row">
													<div class="col-1">
														<label>Slot</label>
													</div>
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-4">
														<label>Esce</label>
													</div>
													<div class="col-4">
														<label>Entra</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.sub" :key="'sost_' + index">
													<div class="col-1 form-group">
														<b-form-select v-model="item.slot" :disabled="!form.partita.enabled">
															<option :value="null"></option>
															<option v-for="value in max_slot" :value="value">{{ value }}</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti_with_intervallo" :value="value.minuto" :key="'smin_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_end" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'splout_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_start" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'splin_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeSub(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addSub()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Sostituzione</b-button>
												
											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Sostituzioni</div>
												<div class="row">
													<div class="col-1">
														<label>Slot</label>
													</div>
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-4">
														<label>Esce</label>
													</div>
													<div class="col-4">
														<label>Entra</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.sub_opponent" :key="'so_' + index" >
													<div class="col-1 form-group">
														<b-form-select v-model="item.slot" :disabled="!form.partita.enabled">
															<option :value="null"></option>
															<option v-for="value in max_slot" :value="value">{{ value }}</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti_with_intervallo" :value="value.minuto" :key="'smo_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_end" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'splouto_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_start" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'splino_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeSubOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addSubOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Sostituzione</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title">Eventi</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-5">
														<label>Giocatore</label>
													</div>
													<div class="col-5">
														<label>Evento</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.events" :key="'e_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'em_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-5 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'ev_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.event" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option value="wrong_penalty">rigore sbagliato</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeEvent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addEvent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Evento</b-button>
												
											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Eventi</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-5">
														<label>Giocatore</label>
													</div>
													<div class="col-5">
														<label>Evento</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.events_opponent" :key="'eo_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esm_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-5 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'evo_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.event" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option value="wrong_penalty">rigore sbagliato</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeEventOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addEventOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Evento</b-button>

											</div>
										</div>
									</div>
								</div>

								<div v-if="!form.partita.type || (form.partita.type && form.partita.type.id != 5)">
									<div class="row mt-1">
										<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
											<div class="card h-100" v-if="!rawPartita.hideFormazione">
												<div class="card-body">
													<div class="card-title">Eventi Staff</div>
													<div class="row">
														<div class="col-2">
															<label>Minuto</label>
														</div>
														<div class="col-5">
															<label>Membro staff</label>
														</div>
														<div class="col-5">
															<label>Evento</label>
														</div>
													</div>
													<div class="row align-items-end" v-for="(item, index) in form.partita.staff_events" :key="'se_' + index">
														<div class="col-2 form-group">
															<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esm_' + index">{{ value.label }}</option>
																<option :value="900" key="esm_900">Fuori gara</option>
															</b-form-select>
														</div>
														<div class="col-5 form-group">
															<b-form-select v-model="item.staff" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-for="(value, index) in staff_members" :value="value" :key="'sm_' + index">{{ value.name }}</option>
															</b-form-select>
														</div>
														<div class="col-4 form-group">
															<b-form-select v-model="item.event" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option value="yellow">ammonizione</option>
																<option value="red">espulsione</option>
															</b-form-select>
														</div>
														<div class="col-1 form-group">
															<b-button variant="outline-danger" @click="removeStaffEvent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
														</div>
													</div>
													<b-button variant="outline-primary" @click="addStaffEvent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Evento</b-button>
													
												</div>
											</div>
										</div>
										<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
											<div class="card h-100">
												<div class="card-body">	
													<div class="card-title">Eventi Staff</div>
													<div class="row">
														<div class="col-2">
															<label>Minuto</label>
														</div>
														<div class="col-6">
															<label>Membro staff</label>
														</div>
														<div class="col-4">
															<label>Evento</label>
														</div>
													</div>
													<div class="row align-items-end" v-for="(item, index) in form.partita.staff_events_opponent" :key="'es_' + index">
														<div class="col-2 form-group">
															<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esm_' + index">{{ value.label }}</option>
																<option :value="900" key="esm_900">Fuori gara</option>
															</b-form-select>
														</div>
														<div class="col-3 form-group">
															<b-form-input type="text" v-model="item.name" :disabled="!form.partita.enabled" placeholder="Nome"/>
														</div>
														<div class="col-3 form-group">
															<b-form-input type="text" v-model="item.surname" :disabled="!form.partita.enabled" placeholder="Cognome"/>
														</div>
														<div class="col-3 form-group">
															<b-form-select v-model="item.event" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option value="yellow">ammonizione</option>
																<option value="red">espulsione</option>
															</b-form-select>
														</div>
														<div class="col-1 form-group">
															<b-button variant="outline-danger" @click="removeStaffEventOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
														</div>
													</div>
													<b-button variant="outline-primary" @click="addStaffEventOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Evento</b-button>

												</div>
											</div>
										</div>
									</div>
								</div>

							</div>

							<div v-if="!form.partita.type || (form.partita.type && form.partita.type.id != 5)">

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100" v-if="!rawPartita.hideFormazione">
											<div class="card-body">
												<div class="card-title">Capitano</div>
												<div class="row">
													<div class="col-12">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.capitani" :key="'cap_' + index">
													<div class="col-12 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null " v-for="(value, index) in form.partita.match_players" :value="value" :key="'plcap_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
												</div>

											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Capitano</div>
												<div class="row">
													<div class="col-12">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.capitani_opponent" :key="'capopp_' + index">
													<div class="col-12 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null " v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'oppcap_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Diffidati</div>
												<div class="row">
													<div class="col-12">
														<label>Giocatore</label>
													</div>
												</div>

												<div class="row align-items-end" v-for="(item, index) in form.partita.diffidati" :key="'diff_' + index">
													<div class="col-11 form-group">
														<multiselect v-model="item.player" :options="players_opts" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" :key="'pldiff_' + index" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeDiffidato(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>		
												</div>
												<b-button variant="outline-primary" @click="addDiffidato" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>
												<b-button v-if="form.partita.competition_id" variant="outline-primary" @click="loadPlayersMatches" :disabled="!form.partita.enabled"><i class="fa fa-pencil fa-fw"></i> Carica Diffidati / Squalificati</b-button>

											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Diffidati</div>
												<div class="row">
													<div class="col-12">
														<label>Giocatore</label>
													</div>
												</div>

												<div class="row align-items-end" v-for="(item, index) in form.partita.diffidati_opponent" :key="'diffopp_' + index">
													<div class="col-11 form-group">
														<multiselect v-model="item.player" :options="opponents_opts" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" :key="'oppdiff_' + index" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeDiffidatoOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>		
												</div>
												<b-button variant="outline-primary" @click="addDiffidatoOpponent" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div :class="'col order-' + (form.partita.home == 1 ? '2' : '1')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Squalificati</div>
												<div class="row">
													<div class="col-12">
														<label>Giocatore</label>
													</div>
												</div>

												<div class="row align-items-end" v-for="(item, index) in form.partita.squalificati" :key="'squal_' + index">
													<div class="col-11 form-group">
														<multiselect v-model="item.player" :options="players_opts" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" :key="'plsqual_' + index" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeSqualificato(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>		
												</div>
												<b-button variant="outline-primary" @click="addSqualificato" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>
												<b-button v-if="form.partita.competition_id" variant="outline-primary" @click="loadPlayersMatches" :disabled="!form.partita.enabled"><i class="fa fa-pencil fa-fw"></i> Carica Diffidati / Squalificati</b-button>

											</div>
										</div>
									</div>
									<div :class="'col order-' + (form.partita.home == 1 ? '1' : '2')">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Squalificati</div>
												<div class="row">
													<div class="col-12">
														<label>Giocatore</label>
													</div>
												</div>

												<div class="row align-items-end" v-for="(item, index) in form.partita.squalificati_opponent" :key="'squalopp_' + index">
													<div class="col-11 form-group">
														<multiselect v-model="item.player" :options="opponents_opts" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" :key="'oppsqual_' + index" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeSqualificatoOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>		
												</div>
												<b-button variant="outline-primary" @click="addSqualificatoOpponent" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>

											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div class="row mt-3" v-if="auth.isUserEnable('football_partite_mng') && auth.isLastSeason(season_id)">
							<div class="col-sm-12 text-right">
								<b-button type="reset" variant="outline-primary" :disabled="!form.partita.enabled">Annulla</b-button>
								<b-button type="submit" variant="primary" :disabled="!form.partita.enabled"><strong class="text-uppercase">Salva partita</strong></b-button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<b-modal id="selectTeamModal" ref="selectTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
			<div v-if="teams.data.length == 0"><h5>Nessuna squadra trovata!</h5></div>
			<div>
				<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
					<b-row class="mb-1">
						<b-col sm="6">
							<b-form-select v-model="filters.categoria">
								<option value="0">-- Categoria --</option>
								<option v-for="item in categories_opt" :value="item.id" :key="'cat_' + item.id">{{item.name}}</option>
							</b-form-select>
						</b-col>
						<b-col sm="6">
							<b-input-group>
								<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
								<b-input-group-append>
									<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
								</b-input-group-append>
							</b-input-group>  	
						</b-col>
					</b-row>	
					<b-row>
						<b-col cols="4" v-for="team in teams.data" v-bind:key="'team_' + team.id" style="padding: 0.2rem">
							<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="setTeam(team)">
								<img :src="team.logo_url" width="50" /><br/>
								<strong>{{team.officialName}}</strong><br/>
								<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
							</b-card>
						</b-col>
					</b-row>	
					<b-row>
						<b-col cols="6" style="padding: 0.2rem">
							<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
						</b-col>	
						
						<b-col cols="6" style="padding: 0.2rem">
							<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
						</b-col>	
					</b-row>				
				</div>
			</div>
		</b-modal>
		<footerapp></footerapp>

	</section>
</template>

<style>
.partiteaggiungi input[type="text"], .partiteaggiungi select {
	height: 40px !important;
}
</style>

<script>
import moment from 'moment'
import Multiselect from 'vue-multiselect';
import myDatepicker from 'vue-datepicker';
import axios from 'axios';
import { mapState } from 'vuex';
import * as types from '../../../../store/types';
var Auth = require('../../../../auth.js');
import cache from '@/mixins/cache.js';

export default {
	components: {
		Multiselect,
		'date-picker': 		myDatepicker,
		headapp:            require('../../../layout/headapp.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		formazione:         require('../../../layout/formazione.vue').default,
		card:               require('../../../layout/card.vue').default,
		flag:               require('../../../layout/flag.vue').default,
		autocompleteNome:  require('../../../layout/autocomplete_nome.vue').default,
		DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
		EntitaApiManager: 	require('@/components/layout/entita_api/manager.vue').default,
		GestioneRisultati: require('@/components/redazione/components/match-day-inline.vue').default
	},

	mixins: [ cache ],

	data () {
		return {
			redazione: null,
			categories_opt: [],
			ux: {
				rankAndResults: null
			},
			teams: {
				data: []
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
			loading: true,
			rawPartita: false,
			num_players: 0,
			label: '',
			n_players: 24,
			n_opponents: 24,
			opponents: [],
			players: [],
			options_players: [],
			selected_players: [],
			options_opponents: [],
			selected_opponents: [],
			opponents_opts: [],
			players_opts: [],
			trainings: [],
			opts_partite: [
				{id:1, name:'Campionato'},
				{id:2, name:'Coppa'},
				{id:3, name:'Amichevole'},
				{id:4, name:'Torneo'},
				{id:5, name:'Nazionale'}
				],
			opts_num_player_partite: [
				{id:3, name:'7'},
				{id:4, name:'8'},
				{id:5, name:'9'},
				{id:6, name:'11'},
				],			
			option: {
				type: 'min',
				week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
				month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				format: 'DD-MM-YYYY HH:mm',
				color: {
					header: 	'#a80532',
					headerText: '#ffffff'
				},
				buttons: {
					ok: 'Ok',
					cancel: 'Cancel'
				},
				overlayOpacity: 0.5, // 0.5 as default
				dismissible: true // as true as default
			},
			limit: [
				],
			form: {
				partita: {
					enabled: null,
					modulo:	null,
					modulo_opponent: null,
					modul: null,
					type: null,
					day: null,
					players: {id:6, name:'11'},
					duration: 90,
					duration_first_half: 45,
					duration_second_half: 45,
					formation: 3,
					formation_opponent:	3,
					goals: [
						{time: '', player: [], autogoal: 0, penalty: 0}
						],
					goals_opponent:	[
						{time: '', player: [], autogoal: 0, penalty: 0}
						],
					assists: [
						{time: '', player: []}
						],
					assists_opponent: [
						{time: '', player: []}
						],
					amm: [
						{time: '', player: []}
						],
					amm_opponent: [
						{time: '', player: []}
						],
					es:	[
						{time: '', player: [], days: 1}
						],
					es_opponent: [
						{time: '', player: []}
						],
					sub: [
						{time: '', player_end: [], player_start: [], slot: null}
						],
					sub_opponent: [
						{time: '', player_end: [], player_start: [], slot: null}
						],
					events:	[
						{time: '', player: [], event: null}
						],
					events_opponent: [
						{time: '', player: [], event: null}
						],
					staff_events:	[
						{time: '', staff: [], event: null}
						],
					staff_events_opponent: [
						{time: '', surname: null, name: null, event: null}
						],
					rigori: [
						{time: 1000, player: [], goal: 1}
						],
					rigori_opponent:	[
						{time: 1000, player: [], goal: 1}
						],
					home: null,
					match_players: [],
					match_players_opponent: [],
					match_players_opponent_shirt: [],
					match_players_role: [],
					match_players_shirt: [],
					match_players_grade: [],
					opponent_team_id: null,
					opponent_team: null,
					squadra: '',					
					date: {
						time: ''
					},
					categoria: {
						id:'',
						name:''
					},
					luogo: '',
					competition_id: null,
					fase_id: null,
					stadium: null,
					recovery_first_half: null,
					recovery_second_half: null,
					meteo_id: null,
					condizioni_campo_id: null,
					tipologia_campo_id: null,
					diretta_tv: null,
					data_comunicato: null,
					citta: null,
					fuso_orario: null,
					arbitro: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					primo_assistente: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					secondo_assistente: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					quarto_ufficiale: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					var: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					avar: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					coach_id: null,
					opp_coach: {
						surname: null,
						name: null,
					},
					team_home: null,
					team_away: null,
					score_home: null,
					score_away: null,
					capitani: [{player: []}],
					capitani_opponent: [{player: []}],
					diffidati: [],
					diffidati_opponent: [],
					squalificati: [],
					squalificati_opponent: [],
					staff_titolari:	[{staff: []}],
					staff_panchina:	[{staff: []}],
					has_rigori: 0,
					has_supplementari: 0,
					duration_suppl: 30,
					duration_suppl_first_half: 15,
					duration_suppl_second_half: 15,
					start_stop_status: null,
					restarted_from_minute: null,
					stopped_at_minute: null,
					recovery_first_half_suppl: null,
					recovery_second_half_suppl: null,
				},
				convocazione: {
					data:	null,
					time:	null,
					convocated: {},
					meals: [],
					arrive: {
						time: ''
					},
					technical_meeting: {
						time: ''
					},
					meal: {
						time: ''
					},
					stadium_departure: {
						time: ''
					},
					return_base: {
						time: ''
					},
					post_match_training: {
						time: ''
					},
					not_conv_training_id: null,
					post_match_training_id: null,
					dress_code: null,
					note: null,
				}
			},
			staff_selected: [],
			season_id: null,
			competizioni: [],
			competizione_fasi: [],
			meteo: [],
			condizioni_campo: [],
			tipi_campo: [],
			tipi_start_stop: [],
			staff_members: [],
			training_id: null,
			players_matches: null,
			arbitri: [],
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		logo_squadra: function() {
			if(this.form.partita.opponent_team) {
				return this.form.partita.opponent_team.logo_url;
			}
			return '/assets/varie/team.png';
		},
		minuti: function() {

			var minuti = [];
			var minuti_regolari_primo_tempo = parseInt(this.form.partita.duration_first_half);
			var minuto = 1;
			
			const startStopStatus   = this.form.partita.start_stop_status
			let i_1_tempo = 1
			let i_2_tempo = 1
			let i_1_tempo_suppl = 1
			let i_2_tempo_suppl = 1
			let restartFromMinute = null
			if( this.form.partita.start_stop_status == 'restart' ){
				
				restartFromMinute = +this.form.partita.restarted_from_minute
				
				if( restartFromMinute <= 45 ){
					i_1_tempo = restartFromMinute
				} 
				else if( restartFromMinute > 45 && restartFromMinute <= 90 ){
					i_2_tempo = restartFromMinute - 45
				} 
				else if( restartFromMinute > 90 && restartFromMinute <= 105 ){
					i_1_tempo_suppl = restartFromMinute - 90
				}
				else if( restartFromMinute > 105 && restartFromMinute <= 120 ){
					i_2_tempo_suppl = restartFromMinute - 105
				}
			}

			var primo_tempo = parseInt(minuti_regolari_primo_tempo) 
							+ (this.form.partita.recovery_first_half > 0 ? parseInt(this.form.partita.recovery_first_half) : 0)
							+ (( startStopStatus == 'restart' && restartFromMinute <= 45 ) ? i_1_tempo : 0 );
			for(var i = i_1_tempo; i <= primo_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 1T',
				}
				minuti.push(min);
				minuto ++;
			}
			var minuti_regolari_sec_tempo = parseInt(this.form.partita.duration_second_half);
			var sec_tempo = parseInt(minuti_regolari_sec_tempo) 
						  + (this.form.partita.recovery_second_half > 0 ? parseInt(this.form.partita.recovery_second_half) : 0)
						  + (( startStopStatus == 'restart' && restartFromMinute > 45 && restartFromMinute <= 90 ) ? i_2_tempo : 0 );
			for(var i = i_2_tempo; i <= sec_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 2T',
				}
				minuti.push(min);
				minuto ++;
			}
			if(this.form.partita.has_supplementari) {
				var minuti_regolari_primo_tempo_suppl = parseInt(this.form.partita.duration_suppl_first_half);
				var primo_tempo_suppl = parseInt(minuti_regolari_primo_tempo_suppl) 
									  + (this.form.partita.recovery_first_half_suppl > 0 ? parseInt(this.form.partita.recovery_first_half_suppl) : 0)
									  + (( startStopStatus == 'restart' && restartFromMinute > 90 && restartFromMinute <= 105 ) ? i_1_tempo_suppl : 0 );
				for(var i = i_1_tempo_suppl; i <= primo_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 1T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
				var minuti_regolari_sec_tempo_suppl = parseInt(this.form.partita.duration_suppl_second_half);
				var sec_tempo_suppl = parseInt(minuti_regolari_sec_tempo_suppl) 
									+ (this.form.partita.recovery_second_half_suppl > 0 ? parseInt(this.form.partita.recovery_second_half_suppl) : 0)
									+ (( startStopStatus == 'restart' && restartFromMinute > 105 && restartFromMinute <= 120 ) ? i_2_tempo_suppl : 0 );
				for(var i = i_2_tempo_suppl; i <= sec_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 2T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
			}
			return minuti;
		},

		minuti_with_intervallo: function() {
			var minuti = [];
			var minuti_regolari_primo_tempo = parseInt(this.form.partita.duration_first_half);
			var minuto = 1;

			const startStopStatus   = this.form.partita.start_stop_status
			let i_1_tempo = 1
			let i_2_tempo = 1
			let i_1_tempo_suppl = 1
			let i_2_tempo_suppl = 1
			let restartFromMinute = null
			if( this.form.partita.start_stop_status == 'restart' ){
				
				restartFromMinute = +this.form.partita.restarted_from_minute
				
				if( restartFromMinute <= 45 ){
					i_1_tempo = restartFromMinute
				} 
				else if( restartFromMinute > 45 && restartFromMinute <= 90 ){
					i_2_tempo = restartFromMinute - 45
				} 
				else if( restartFromMinute > 90 && restartFromMinute <= 105 ){
					i_1_tempo_suppl = restartFromMinute - 90
				}
				else if( restartFromMinute > 105 && restartFromMinute <= 120 ){
					i_2_tempo_suppl = restartFromMinute - 105
				}
			}

			var primo_tempo = parseInt(minuti_regolari_primo_tempo) 
							+ (this.form.partita.recovery_first_half > 0 ? parseInt(this.form.partita.recovery_first_half) : 0)
							+ (( startStopStatus == 'restart' && restartFromMinute <= 45 ) ? i_1_tempo : 0 );

			for(var i = i_1_tempo; i <= primo_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 1T',
				}
				minuti.push(min);
				minuto ++;
			}
			var min = {
				minuto: minuto - 0.5,
				label: 'Intervallo - 1T',
			}
			minuti.push(min);
			var minuti_regolari_sec_tempo = parseInt(this.form.partita.duration_second_half);
			var sec_tempo = parseInt(minuti_regolari_sec_tempo) 
						  + (this.form.partita.recovery_second_half > 0 ? parseInt(this.form.partita.recovery_second_half) : 0)
						  + (( startStopStatus == 'restart' && restartFromMinute > 45 && restartFromMinute <= 90 ) ? i_2_tempo : 0 );
			for(var i = i_2_tempo; i <= sec_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 2T',
				}
				minuti.push(min);
				minuto ++;
			}
			if(this.form.partita.has_supplementari) {
				
				var min = {
					minuto: minuto - 0.5,
					label: 'Intervallo - 2T',
				}
				minuti.push(min);

				var minuti_regolari_primo_tempo_suppl = parseInt(this.form.partita.duration_suppl_first_half);
				var primo_tempo_suppl = parseInt(minuti_regolari_primo_tempo_suppl) 
									  + (this.form.partita.recovery_first_half_suppl > 0 ? parseInt(this.form.partita.recovery_first_half_suppl) : 0)
									  + (( startStopStatus == 'restart' && restartFromMinute > 90 && restartFromMinute <= 105 ) ? i_1_tempo_suppl : 0 );
				for(var i = i_1_tempo_suppl; i <= primo_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 1T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
				var min = {
					minuto: minuto - 0.5,
					label: 'Intervallo - 1T suppl',
				}
				minuti.push(min);
				var minuti_regolari_sec_tempo_suppl = parseInt(this.form.partita.duration_suppl_second_half);
				var sec_tempo_suppl = parseInt(minuti_regolari_sec_tempo_suppl) 
									+ (this.form.partita.recovery_second_half_suppl > 0 ? parseInt(this.form.partita.recovery_second_half_suppl) : 0)
									+ (( startStopStatus == 'restart' && restartFromMinute > 105 && restartFromMinute <= 120 ) ? i_2_tempo_suppl : 0 );
				for(var i = i_2_tempo_suppl; i <= sec_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 2T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
			}
			return minuti;
		},

		max_slot: function() {
			var slot = 0;
			for(var i in this.form.partita.sub) {
				var sub = this.form.partita.sub[i];
				if(sub.slot && sub.slot > slot) {
					slot = sub.slot;
				}
			}
			for(var i in this.form.partita.sub_opponent) {
				var sub = this.form.partita.sub_opponent[i];
				if(sub.slot && sub.slot > slot) {
					slot = sub.slot;
				}
			}
			return slot + 1;
		},

		filtered_competizioni: function() {
			if(this.form.partita.type) {
				return this.competizioni.filter((item) => item.match_type == this.form.partita.type.id);
			}
			return this.competizioni;
		},

		staff_opts: function() {
			return this.staff_members.filter((item) => this.form.partita.staff_titolari.findIndex((item2) => item2.staff.id ==  item.id ) == -1 && this.form.partita.staff_panchina.findIndex((item2) => item2.staff.id ==  item.id ) == -1);
		},

		options_players_conv: function() {
			if(!this.rawPartita.isHideConvocazione) {
				return this.options_players.filter((item) => item.id == null || (item.id > 0 && this.form.convocazione.convocated[item.id] && this.form.convocazione.convocated[item.id] == 'convocato'));
			} else {
				return this.options_players;
			}
		},

		...mapState(['rosaTeamId']),
	},

/*	watch: {
		rosaTeamId() {
			this.getPlayers();
			this.getCompetizioni();
			this.getStaff();
		}, 	
	},  */

	created() {
		for(var i = 0; i < this.n_players; i++) {
			this.selected_players[i] = null;
		}
		for(var i = 0; i < this.n_opponents; i++) {
			this.selected_opponents[i] = null;
		}
		this.getCategories();
	//	this.getTeams();
		this.getStaff();
		this.get();
		this.getCompetizioni();
		this.getMeteo();
		this.getCondizioniCampo();
		this.getTipiCampo();
		this.getTipiStartStop();
		this.getCompetizioneFasi();
		this.getArbitri();
	},

	methods: {
		updateDuration: function( type ) {

			switch ( type ) {
				case 'from_total':
					if( this.form.partita.duration !== '' && this.form.partita.duration !== null && this.form.partita.duration !== undefined && this.form.partita.duration != 0 ){
						this.form.partita.duration_first_half  = ( Math.floor(+this.form.partita.duration / 2) )
						this.form.partita.duration_second_half = ( Math.floor(+this.form.partita.duration / 2 )) + ( +this.form.partita.duration % 2 )
					}
					break;
				case 'from_total_suppl':
					if( this.form.partita.duration_suppl !== '' && this.form.partita.duration_suppl !== null && this.form.partita.duration_suppl !== undefined && this.form.partita.duration_suppl != 0 ){
						this.form.partita.duration_suppl_first_half  = ( Math.floor(+this.form.partita.duration_suppl / 2) )
						this.form.partita.duration_suppl_second_half = ( Math.floor(+this.form.partita.duration_suppl / 2 )) + ( +this.form.partita.duration_suppl % 2 )
					}
					break;
				case 'from_partial':
					let firstHalf = 0
					if( this.form.partita.duration_first_half !== '' && this.form.partita.duration_first_half !== null && this.form.partita.duration_first_half !== undefined && this.form.partita.duration_first_half != 0 ){
						firstHalf = +this.form.partita.duration_first_half
					}
					
					let secondHalf = 0
					if( this.form.partita.duration_second_half !== '' && this.form.partita.duration_second_half !== null && this.form.partita.duration_second_half !== undefined && this.form.partita.duration_second_half != 0 ){
						secondHalf = +this.form.partita.duration_second_half
					}

					this.form.partita.duration  = firstHalf + secondHalf

					break;
				case 'from_partial_suppl':
					let firstHalfSuppl = 0
					if( this.form.partita.duration_suppl_first_half !== '' && this.form.partita.duration_suppl_first_half !== null && this.form.partita.duration_suppl_first_half !== undefined && this.form.partita.duration_suppl_first_half != 0 ){
						firstHalfSuppl = +this.form.partita.duration_suppl_first_half
					}
					
					let secondHalfSuppl = 0
					if( this.form.partita.duration_suppl_second_half !== '' && this.form.partita.duration_suppl_second_half !== null && this.form.partita.duration_suppl_second_half !== undefined && this.form.partita.duration_suppl_second_half != 0 ){
						secondHalfSuppl = +this.form.partita.duration_suppl_second_half
					}

					this.form.partita.duration_suppl  = firstHalfSuppl + secondHalfSuppl
					break;
			}

		},
		fetchRankingParams: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition-params/'+this.rosaTeamId).then(function(data) {
				this.redazione = data.data;
			});
		},
		paginate(page) {
			this.page = page;
			this.getTeams();
		},

		setTeam: function(team) {
			this.form.partita.squadra = team.officialName + ' - ' + (team.categoria ? team.categoria.name : '&nbsp;');
			this.form.partita.opponent_team_id = team.id;
			this.form.partita.opponent_team = team;
			this.form.partita.categoria.id 	= team.categoria ? team.categoria.id : '';
			this.form.partita.categoria.name = team.categoria ? team.categoria.name : ''; 
			if(team.allenatore && team.allenatore.length > 0) {
				this.form.partita.opp_coach.surname = team.allenatore[0].cognome;
				this.form.partita.opp_coach.name = team.allenatore[0].nome;
			} else {
				this.form.partita.opp_coach.surname = null;
				this.form.partita.opp_coach.name = null;
			}
			this.form.partita.goals_opponent = [{time: '', player: [], autogoal: 0, penalty: 0}];
			this.form.partita.assists_opponent = [{time: '', player: []}];
			this.form.partita.amm_opponent = [{time: '', player: []}];
			this.form.partita.es_opponent = [{time: '', player: []}];
			this.form.partita.sub_opponent = [{time: '', player_end: [], player_start: [], slot: null}];
			this.form.partita.events_opponent = [{time: '', player: [], event: null}];
			this.form.partita.staff_events_opponent = [{time: '', surname: null, name: null, event: null}];
			this.form.partita.match_players_opponent = [];
			this.form.partita.match_players_opponent_shirt = [];
			this.form.partita.capitani_opponent = [{player: []}];
			this.form.partita.diffidati_opponent = [];
			this.form.partita.squalificati_opponent = [];
			this.getOpponents(this.$route.params.id, team.id);
			this.$refs.selectTeamModal.hide();
		},

		selectTeam: function(squadra) {	
			if(this.teams.data.length == 0) {
				this.getTeams();
			}		
			this.$refs.selectTeamModal.show();
		},

		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.teams = response.data;						
				}
			}, (response) => {
				// error callback
			});
		},

		getCategories() {
			var categories = this.getCache('categories');
			if(categories) {
				this.categories_opt = categories;
			} else {
				axios.get(`/exercise/categories`)
				.then((res) => {
					this.categories_opt = res.data.map(item => ({
						name: item.name,
						id: item.id,
					}));
					this.setCache('categories', this.categories_opt);
				}).catch((err) => {
				});
			}
		},

		filtraArchivio() {
			this.teams = {
				data: []
			};
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then((response) => {
				if (response.ok)
				{
					this.teams = response.data;
				}
			}, (response) => {
			});
		},

		handleSubmit: function () {
			if(Auth.isUserEnable('football_partite_mng') && Auth.isLastSeason(this.season_id)) {
				this.$http.post(this.$store.state.apiEndPoint + '/football/partita/'+this.$route.params.id, this.form).then((response) => {

					if (response.ok){
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.updateSqualificati();
					//	this.$router.push({ name: 'football_partite'});
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			} else {
				alert("Non puoi modificare una partita di una stagione archiviata!");
			}
		},

		saveMatch: function () {
			if(Auth.isUserEnable('football_partite_mng') && Auth.isLastSeason(this.season_id)) {
				this.$http.post(this.$store.state.apiEndPoint + '/football/partita/'+this.$route.params.id, this.form).then((response) => {

					if (response.ok){
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			} else {
				alert("Non puoi modificare una partita di una stagione archiviata!");
			}
		},

		elimina() {
			if(confirm("Sei sicuro di voler eliminare la partita selezionata?")) {
				axios.post(`/football/partita/elimina`, { partita_id: this.$route.params.id })
				.then((res) => {		
					this.$store.commit(types.ALERTS_PUSH, { msg: 'eliminazione avvenuta con successo', status: 1 });
					this.updateSqualificati();
					this.$router.push({ name: 'football_partite'});
				}).catch((err) => {
				});
			}
		},
		disabilita(){
			axios.post(`/football/partita/disabilita`, { partita_id: this.$route.params.id })
			.then((res) => {		
				this.form.partita.enabled = 0;		        
			}).catch((err) => {
			});
		},
		ripristina(){
			axios.post(`/football/partita/ripristina`, { partita_id: this.$route.params.id })
			.then((res) => {		
				this.form.partita.enabled = 1;		        
			}).catch((err) => {
			});
		},

		nascondiFormazione(){
			axios.post(`/football/partita/nascondi/formazione`, { partita_id: this.$route.params.id })
			.then((res) => {		
				this.rawPartita.isHideFormazione = true;		        
			}).catch((err) => {
			});
		},
		mostraFormazione(){
			axios.post(`/football/partita/mostra/formazione`, { partita_id: this.$route.params.id })
			.then((res) => {		
				this.rawPartita.isHideFormazione = false;		        
			}).catch((err) => {
			});
		},

		nascondiConvocazioni(){
			axios.post(`/football/partita/nascondi/convocazioni`, { partita_id: this.$route.params.id })
			.then((res) => {		
				this.rawPartita.isHideConvocazione = true;		        
			}).catch((err) => {
			});
		},
		mostraConvocazioni(){
			axios.post(`/football/partita/mostra/convocazioni`, { partita_id: this.$route.params.id })
			.then((res) => {		
				this.rawPartita.isHideConvocazione = false;		        
			}).catch((err) => {
			});
		},

		findAndReplace: function(string, target, replacement) {
			var i = 0, length = string.length;
			for (i; i < length; i++) {
				string = string.replace(target, replacement);
			}
			return string;
		},

		formazioneUpdate: function(modulo){
			this.form.partita.formation = modulo.id;
		},
		formazioneUpdateOpponent: function(modulo){
			this.form.partita.formation_opponent = modulo.id;
		},

		addGoal: function () {
			this.form.partita.goals.push({ time: '', player: [], autogoal: 0, penalty: 0 });
		},
		removeGoal: function (item) {
			var index = this.form.partita.goals.indexOf(item);
			this.form.partita.goals.splice(index, 1);
		},
		addGoalOpponent: function () {
			this.form.partita.goals_opponent.push({ time: '', player: [], autogoal: 0, penalty: 0 });
		},
		removeGoalOpponent: function (item) {
			var index = this.form.partita.goals_opponent.indexOf(item);
			this.form.partita.goals_opponent.splice(index, 1);
		},

		addRigore: function () {
			this.form.partita.rigori.push({ time: 1000, player: [], goal: 1 });
		},
		removeRigore: function (item) {
			var index = this.form.partita.rigori.indexOf(item);
			this.form.partita.rigori.splice(index, 1);
		},
		addRigoreOpponent: function () {
			this.form.partita.rigori_opponent.push({ time: 1000, player: [], goal: 1 });
		},
		removeRigoreOpponent: function (item) {
			var index = this.form.partita.rigori_opponent.indexOf(item);
			this.form.partita.rigori_opponent.splice(index, 1);
		},

		addAssist: function () {
			this.form.partita.assists.push({ time: '', player: [] });
		},
		removeAssist: function (item) {
			var index = this.form.partita.assists.indexOf(item);
			this.form.partita.assists.splice(index, 1);
		},
		addAssistOpponent: function () {
			this.form.partita.assists_opponent.push({ time: '', player: [] });
		},
		removeAssistOpponent: function (item) {
			var index = this.form.partita.assists_opponent.indexOf(item);
			this.form.partita.assists_opponent.splice(index, 1);
		},

		addAmm: function () {
			this.form.partita.amm.push({ time: '', player: [] });
		},
		removeAmm: function (item) {
			var index = this.form.partita.amm.indexOf(item);
			this.form.partita.amm.splice(index, 1);
		},
		addAmmOpponent: function () {
			this.form.partita.amm_opponent.push({ time: '', player: [] });
		},
		removeAmmOpponent: function (item) {
			var index = this.form.partita.amm_opponent.indexOf(item);
			this.form.partita.amm_opponent.splice(index, 1);
		},

		addEs: function () {
			this.form.partita.es.push({ time: '', player: [], days: 1 });
		},
		removeEs: function (item) {
			var index = this.form.partita.es.indexOf(item);
			this.form.partita.es.splice(index, 1);
		},
		addEsOpponent: function () {
			this.form.partita.es_opponent.push({ time: '', player: [] });
		},
		removeEsOpponent: function (item) {
			var index = this.form.partita.es_opponent.indexOf(item);
			this.form.partita.es_opponent.splice(index, 1);
		},

		addSub: function () {
			this.form.partita.sub.push({ time: '', player_end: [], player_start: [], slot: null });
		},
		removeSub: function (item) {
			var index = this.form.partita.sub.indexOf(item);
			this.form.partita.sub.splice(index, 1);
		},
		addSubOpponent: function () {
			this.form.partita.sub_opponent.push({ time: '', player_end: [], player_start: [], slot: null });
		},
		removeSubOpponent: function (item) {
			var index = this.form.partita.sub_opponent.indexOf(item);
			this.form.partita.sub_opponent.splice(index, 1);
		},

		addEvent: function () {
			this.form.partita.events.push({ time: '', player: [], event: null });
		},
		removeEvent: function (item) {
			var index = this.form.partita.events.indexOf(item);
			this.form.partita.events.splice(index, 1);
		},
		addEventOpponent: function () {
			this.form.partita.events_opponent.push({ time: '', player: [], event: null });
		},
		removeEventOpponent: function (item) {
			var index = this.form.partita.events_opponent.indexOf(item);
			this.form.partita.events_opponent.splice(index, 1);
		},

		addStaffEvent: function () {
			this.form.partita.staff_events.push({ time: '', staff: [], event: null });
		},
		removeStaffEvent: function (item) {
			var index = this.form.partita.staff_events.indexOf(item);
			this.form.partita.staff_events.splice(index, 1);
		},
		addStaffEventOpponent: function () {
			this.form.partita.staff_events_opponent.push({ time: '', surname: null, name: null, event: null });
		},
		removeStaffEventOpponent: function (item) {
			var index = this.form.partita.staff_events_opponent.indexOf(item);
			this.form.partita.staff_events_opponent.splice(index, 1);
		},
		
		addDiffidato: function () {
			this.form.partita.diffidati.push({player: []});
		},
		removeDiffidato: function (item) {
			var index = this.form.partita.diffidati.indexOf(item);
			this.form.partita.diffidati.splice(index, 1);
		},
		addDiffidatoOpponent: function () {
			this.form.partita.diffidati_opponent.push({player: []});
		},
		removeDiffidatoOpponent: function (item) {
			var index = this.form.partita.diffidati_opponent.indexOf(item);
			this.form.partita.diffidati_opponent.splice(index, 1);
		},

		addSqualificato: function () {
			this.form.partita.squalificati.push({player: []});
		},
		removeSqualificato: function (item) {
			var index = this.form.partita.squalificati.indexOf(item);
			this.form.partita.squalificati.splice(index, 1);
		},
		addSqualificatoOpponent: function () {
			this.form.partita.squalificati_opponent.push({player: []});
		},
		removeSqualificatoOpponent: function (item) {
			var index = this.form.partita.squalificati_opponent.indexOf(item);
			this.form.partita.squalificati_opponent.splice(index, 1);
		},

		addStaffTitolare: function () {
			this.form.partita.staff_titolari.push({ staff: [] });
		},
		removeStaffTitolare: function (item) {
			var index = this.form.partita.staff_titolari.indexOf(item);
			this.form.partita.staff_titolari.splice(index, 1);
		},
		addStaffPanchina: function () {
			this.form.partita.staff_panchina.push({ staff: [] });
		},
		removeStaffPanchina: function (item) {
			var index = this.form.partita.staff_panchina.indexOf(item);
			this.form.partita.staff_panchina.splice(index, 1);
		},

		manage_type: function(type){
			var trovato = null;
			this.opts_partite.forEach(function(el, index) {
				if( type === el.name ) trovato = {id:el.id, name:el.name} ;
			});
			this.form.partita.type = trovato;
		},
		manage_trainings_post: function(trn){
			var trovato = null;
			this.trainings.forEach(function(el, index) {
				if( trn === el.id ) trovato = {id:el.id, name:el.name} ;
			});
			this.form.convocazione.post_match_training_id = trovato;
		},
		manage_trainings_not_conv: function(trn){
			var trovato = null;
			this.trainings.forEach(function(el, index) {
				if( trn === el.id ) trovato = {id:el.id, name:el.name} ;
			});
			this.form.convocazione.not_conv_training_id = trovato;
		},
		manage_players: function(players) {
			var trovato = null;
			this.opts_num_player_partite.forEach(function(el, index) {
				if( players.toString() === el.name ) trovato = {id:el.id, name:el.name} ;
			});
			this.form.partita.players = trovato;
		},
		get: function() {
			this.$http.get(this.$store.state.apiEndPoint + `/football/partita/`+this.$route.params.id).then((response) => {
				this.loading = false;
				if (response.ok) {
					this.rawPartita = response.data;
					this.season_id = response.data.stagione_id;

					this.label = response.data.tipo_categoria.tipo.nome + ' ' +  (response.data.giornata ? response.data.giornata + '° Giornata ' : ' ') + moment(response.data.data).format("DD/MM/YYYY HH:mm");
					if(response.data.tipo_categoria.tipo.nome != 'Nazionale') {
						this.opts_partite[4].$isDisabled = true;
					}

					this.manage_type(response.data.tipo_categoria.tipo.nome);

					if(response.data.tipo_categoria.tipo.numero_giocatori) {
						this.manage_players(response.data.tipo_categoria.tipo.numero_giocatori);
					}

					this.num_players = response.data.tipo_categoria.tipo.numero_giocatori;
					this.form.partita.enabled = response.data.enabled;
					this.form.partita.day = response.data.giornata;

					if(response.data.info && response.data.info.start_stop_status) {
						this.form.partita.start_stop_status = response.data.info.start_stop_status;
						if(this.form.partita.start_stop_status == 'stop') {
							this.form.partita.stopped_at_minute = response.data.info.start_stop_minutes;
						} else if(this.form.partita.start_stop_status == 'restart') {
							this.form.partita.restarted_from_minute = response.data.info.start_stop_minutes;
						}
					}

					this.form.partita.duration_first_half = response.data.tempi[0].durata_totale - response.data.tempi[0].recupero;
					this.form.partita.duration_second_half = response.data.tempi[1].durata_totale - response.data.tempi[1].recupero;
					this.form.partita.duration = this.form.partita.duration_first_half + this.form.partita.duration_second_half;

				//	this.form.partita.duration = (response.data.tempi[0].durata_totale - response.data.tempi[0].recupero) + (response.data.tempi[1].durata_totale - response.data.tempi[1].recupero);

					if(response.data.tipo_categoria.tipo.numero_tempi_supplementari > 0) {
						this.form.partita.has_supplementari = 1;
						this.form.partita.duration_suppl = (response.data.tempi[2].durata_totale - response.data.tempi[2].recupero) + (response.data.tempi[3].durata_totale - response.data.tempi[3].recupero);
						this.form.partita.recovery_first_half_suppl = response.data.tempi[2].recupero;
						this.form.partita.recovery_second_half_suppl = response.data.tempi[3].recupero;
					}

					var opponent = response.data.home_id == this.rosaTeamId ? response.data.away : response.data.home; 
					this.form.partita.squadra = opponent.officialName;
					this.form.partita.opponent_team_id = opponent.id;
					this.form.partita.opponent_team = opponent;

					this.form.partita.categoria = {
						name: response.data.categoria.nome,
						id: response.data.categoria_id,
					};

					this.form.partita.date.time = moment(response.data.data).format("DD-MM-YYYY HH:mm:ss");
					this.form.partita.home = response.data.home_id == this.rosaTeamId ? 0 : 1;


					this.form.partita.luogo = response.data.info && response.data.info.technical_meeting_place ? response.data.info.technical_meeting_place : '';
					this.locateAddress();

					this.form.partita.competition_id = response.data.competizione_id;
					this.form.partita.stadium = response.data.info && response.data.info.stadio ? response.data.info.stadio.nome : null;
					this.form.partita.recovery_first_half = response.data.tempi[0].recupero;
					this.form.partita.recovery_second_half = response.data.tempi[1].recupero;
					this.form.partita.condizioni_campo_id = response.data.info ? response.data.info.condizioni_campo_id : null;
					this.form.partita.tipologia_campo_id = response.data.info ? response.data.info.tipologia_campo_id : null;
					this.form.partita.meteo_id = response.data.info ? response.data.info.meteo_id : null;
					this.form.partita.diretta_tv = response.data.info ? response.data.info.diretta_tv : null;
					this.form.partita.data_comunicato = response.data.info ? response.data.info.data_comunicato : null;
					this.form.partita.citta = response.data.info ? response.data.info.citta : null;
					this.form.partita.fuso_orario = response.data.info ? response.data.info.fuso_orario : null;

					var coach_rel = response.data.persona_rel.find((item) => item.team_id == this.rosaTeamId && item.partita_persona_tipo_glo_id == 4);
					if(coach_rel) {
						this.form.partita.coach_id = coach_rel.persona_id;
					} else {

					}

					var opp_coach_rel = response.data.persona_rel.find((item) => item.team_id != this.rosaTeamId && item.partita_persona_tipo_glo_id == 4);
					if(opp_coach_rel) {	
						this.form.partita.opp_coach.surname = opp_coach_rel.persona.cognome;
						this.form.partita.opp_coach.name = opp_coach_rel.persona.nome;
					}

					this.form.partita.team_home = response.data.home_team;
					this.form.partita.team_away = response.data.away_team;
					this.form.partita.score_home = response.data.home_score;
					this.form.partita.score_away = response.data.away_score;
					this.form.partita.fase_id = response.data.fase_id;

					if(response.data.info && response.data.info.arrive) {
						this.form.convocazione.arrive.time = moment(response.data.info.arrive).format("DD-MM-YYYY HH:mm:ss");
					}
					if(response.data.info && response.data.info.meal) {
						this.form.convocazione.meal.time = moment(response.data.info.meal).format("DD-MM-YYYY HH:mm:ss");
					}
					if(response.data.info && response.data.info.technical_meeting) {
						this.form.convocazione.technical_meeting.time = moment(response.data.info.technical_meeting).format("DD-MM-YYYY HH:mm:ss");
					}
					if(response.data.info && response.data.info.stadium_departure) {
						this.form.convocazione.stadium_departure.time = moment(response.data.info.stadium_departure).format("DD-MM-YYYY HH:mm:ss");
					}
					if(response.data.info && response.data.info.return_base) {
						this.form.convocazione.return_base.time = moment(response.data.info.return_base).format("DD-MM-YYYY HH:mm:ss");
					}
					if(response.data.info && response.data.info.post_match_training) {
						this.form.convocazione.post_match_training.time = moment(response.data.info.post_match_training).format("DD-MM-YYYY HH:mm:ss");
					}
					if(response.data.info && response.data.info.post_match_training_id) {
						this.manage_trainings_post(response.data.info.post_match_training_id);
					}
					if(response.data.info && response.data.info.not_conv_training_id) {
						this.manage_trainings_not_conv(response.data.info.not_conv_training_id);
					}
					if(response.data.info && response.data.info.dress_code) {
						this.form.convocazione.dress_code = response.data.info.dress_code;
					}
					if(response.data.info && response.data.info.note) {
						this.form.convocazione.note = response.data.info.note;
					}

					response.data.persona_rel.filter((item) => item.convocazione).forEach((el, index) => {
						this.form.convocazione.convocated[el.persona_id] = el.convocazione;
					});

				//	this.getPlayers();

				/* modulo */
					if(response.data.home_modulo) {
						var newStr = this.findAndReplace(response.data.home_modulo.name, '-', '');
						newStr = this.findAndReplace(newStr, ' ', '');
						if(response.data.home_id == this.rosaTeamId) {	
							this.form.partita.formation = response.data.home_modulo_id;
							this.form.partita.modulo = newStr;
						} else {
							this.form.partita.formation_opponent = response.data.home_modulo_id;
							this.form.partita.modulo_opponent = newStr;
						}
					}
					if(response.data.away_modulo) {
						var newStr = this.findAndReplace(response.data.away_modulo.name, '-', '');
						newStr = this.findAndReplace(newStr, ' ', '');
						if(response.data.home_id != this.rosaTeamId) {	
							this.form.partita.formation = response.data.away_modulo_id;
							this.form.partita.modulo = newStr;
						} else {
							this.form.partita.formation_opponent = response.data.away_modulo_id;
							this.form.partita.modulo_opponent = newStr;
						}
					}
				/* player */
					var k = 0;					
					response.data.persona_rel.filter((item) => item.partita_persona_tipo_glo_id == 1 || item.partita_persona_tipo_glo_id == 2).forEach((el, index) => {
						if( el.team_id == this.rosaTeamId ) {
							if(this.rawPartita.isHideConvocazione == false && el.convocazione != 'convocato') {	
							} else {
								this.form.partita.match_players[k] = {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona.id }
								this.form.partita.match_players_shirt[k] = el.maglia;	
								k++;	
							}
						}
					});
					if(k > 24 || response.data.tipo_categoria.tipo.nome == 'Nazionale') {
						this.n_players = k;
					}

				/* player avversario */
					k = 0;
					response.data.persona_rel.filter((item) => item.partita_persona_tipo_glo_id == 1 || item.partita_persona_tipo_glo_id == 2).forEach((el, index) => {
						if( el.team_id != this.rosaTeamId) {
							this.form.partita.match_players_opponent[k] = {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona.id, opponent_id: el.persona.id }
							this.form.partita.match_players_opponent_shirt[k] = el.maglia;
							k++;
						}
					});

					this.getPlayers();

					if(k > 24 || response.data.tipo_categoria.tipo.nome == 'Nazionale') {
						this.n_opponents = k;
					}

				/* espulsioni */
					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 6 && item.team_id == this.rosaTeamId).forEach((el, index) => {
						this.form.partita.es[k] = { time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id }, days: el.squalifica ? el.squalifica.durata : 1 };
						k++;
					});

					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 6 && item.team_id != this.rosaTeamId).forEach((el, index) => {
						this.form.partita.es_opponent[k] = {time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id }};
						k++;
					});

				/* ammonizioni */
					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 5 && item.team_id == this.rosaTeamId).forEach((el, index) => {
						this.form.partita.amm[k] = { time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id }};
						k++;
					});

					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 5 && item.team_id != this.rosaTeamId).forEach((el, index) => {
						this.form.partita.amm_opponent[k] = {time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id }};
						k++;
					});

				/* goals */

					k = 0;						
					response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 1 || item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 4 ) && item.team_id == this.rosaTeamId && item.minuto != 1000).forEach((el, index) => {							
						this.form.partita.goals[k] = {time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id}, autogoal: el.partita_evento_tipo_glo_id == 4 ? 1 : 0, penalty: el.partita_evento_tipo_glo_id == 3 ? 1 : 0 };
						k++;
					});

					k = 0;
					response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 1 || item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 4 ) && item.team_id != this.rosaTeamId && item.minuto != 1000).forEach((el, index) => {	
						this.form.partita.goals_opponent[k] = {time: el.minuto, player: el.persona ? {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id} : -1, autogoal: el.partita_evento_tipo_glo_id == 4 ? 1 : 0, penalty: el.partita_evento_tipo_glo_id == 3 ? 1 : 0 };
						k++;
					});

					/* rigori */
					if(response.data.tipo_categoria.tipo.rigori > 0) {
						this.form.partita.has_rigori = 1;
						k = 0;						
						response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 9 ) && item.team_id == this.rosaTeamId && item.minuto == 1000).forEach((el, index) => {							
							this.form.partita.rigori[k] = {time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id}, goal: el.partita_evento_tipo_glo_id == 3 ? 1 : 0 };
							k++;
						});

						k = 0;
						response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 9 ) && item.team_id != this.rosaTeamId && item.minuto == 1000).forEach((el, index) => {	
							this.form.partita.rigori_opponent[k] = {time: el.minuto, player: el.persona ? {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id} : -1, goal: el.partita_evento_tipo_glo_id == 3 ? 1 : 0 };
							k++;
						});
					}

				/* assists */
					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 2 && item.team_id == this.rosaTeamId).forEach((el, index) => {
						this.form.partita.assists[k] = { time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id }};
						k++;
					});

					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 2 && item.team_id != this.rosaTeamId).forEach((el, index) => {
						this.form.partita.assists_opponent[k] = { time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id }};
						k++;
					});

				/* sostituzioni */
					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 8 && item.team_id == this.rosaTeamId).forEach((el, index) => {
						var event_in = null;
						var id = el.info ? el.info.valore_numerico : null;
						if(id) {
							event_in = response.data.eventi.find((obj) => obj.id == id);
						}
						this.form.partita.sub[k] = {
							time: el.intervallo ? el.minuto + 0.5 : el.minuto,
							player_end: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id },
							player_start: { name: event_in ? event_in.persona.nome + ' ' + event_in.persona.cognome : null, id: event_in ? event_in.persona_id : null },
							slot: el.slot && el.slot.valore_numerico ? parseInt(el.slot.valore_numerico) : null,
						};
						k++;
					});

					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 8 && item.team_id != this.rosaTeamId).forEach((el, index) => {
						var event_in = null;
						var id = el.info ? el.info.valore_numerico : null;
						if(id) {
							event_in = response.data.eventi.find((obj) => obj.id == id);
						}
						this.form.partita.sub_opponent[k] = {
							time: el.intervallo ? el.minuto + 0.5 : el.minuto,
							player_end: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id,  opponent_id: el.persona_id},
							player_start: {name: event_in ? event_in.persona.nome + ' ' + event_in.persona.cognome : null, id:  event_in ? event_in.persona_id : null,  opponent_id: event_in ? event_in.persona_id : null },
							slot: el.slot && el.slot.valore_numerico ? parseInt(el.slot.valore_numerico) : null,
						};
						k++;
					});	

				/* eventi */
					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 9 && item.team_id == this.rosaTeamId && item.minuto != 1000).forEach((el, index) => {
						this.form.partita.events[k] = {time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id}, event: 'wrong_penalty' };
						k++;
					});

					k = 0;
					response.data.eventi.filter((item) => item.partita_evento_tipo_glo_id == 9 && item.team_id != this.rosaTeamId && item.minuto != 1000).forEach((el, index) => {		
						this.form.partita.events_opponent[k] = {time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id}, event: 'wrong_penalty' };
						k++;
					});

					if(response.data.tipo_categoria.tipo.nome != 'Nazionale') {
					/* eventi staff */
						k = 0;
						response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 10 || item.partita_evento_tipo_glo_id == 11) && item.team_id == this.rosaTeamId).forEach((el, index) => {
							this.form.partita.staff_events[k] = {time: el.minuto, staff: {id: el.persona_id, name: el.persona.nome + ' ' + el.persona.cognome }, event: el.partita_evento_tipo_glo_id == 10 ? 'yellow' : 'red' };
							k++;
						});

						k = 0;
						response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 10 || item.partita_evento_tipo_glo_id == 11) && item.team_id != this.rosaTeamId).forEach((el, index) => {
							this.form.partita.staff_events_opponent[k] = {time: el.minuto,  name: el.persona.nome, surname: el.persona.cognome , event: el.partita_evento_tipo_glo_id == 10 ? 'yellow' : 'red' };
							k++;
						});
						this.getOpponents(this.$route.params.id, response.data.home_id == this.rosaTeamId ? response.data.away_id : response.data.home_id);
					}

				/* arbitri */
					response.data.persona_rel.filter((item) => item.tipo && item.tipo.gruppo == 'arbitri').forEach((el, index) => {
						if(this.form.partita[el.tipo.nome.replace(' ', '_')][this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1].cognome != null) {
							this.form.partita[el.tipo.nome.replace(' ', '_')].push({
								cognome: null,
								nome: null,
								sezione: null,
								stato: null,
							});
						}
						this.form.partita[el.tipo.nome.replace(' ', '_')][this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1].cognome = el.persona.cognome;
						this.form.partita[el.tipo.nome.replace(' ', '_')][this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1].nome = el.persona.nome;
						this.form.partita[el.tipo.nome.replace(' ', '_')][this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1].sezione = el.persona.arbitro.sezione;
						this.form.partita[el.tipo.nome.replace(' ', '_')][this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1].stato = el.persona.arbitro.stato;

						if(this.$refs[el.tipo.nome.replace(' ', '_') + '' + (this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1)] !== undefined) {
							this.$refs[el.tipo.nome.replace(' ', '_') + '' + (this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1)][0].setNome(el.persona.id, el.persona.cognome, this.form.partita[el.tipo.nome.replace(' ', '_')].length - 1, el.tipo.nome.replace(' ', '_'));
						}
					});	

				/* capitani */

					k = 0;						
					response.data.persona_rel.filter((item) => item.capitano && item.team_id == this.rosaTeamId).forEach((el, index) => {						
						this.form.partita.capitani[k] = {player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id} };
						k++;
					});

					k = 0;
					response.data.persona_rel.filter((item) => item.capitano && item.team_id != this.rosaTeamId).forEach((el, index) => {
						this.form.partita.capitani_opponent[k] = {player: el.persona ? {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id} : -1 };
						k++;
					});

				/* diffidati */
					var k = 0;					
					response.data.persona_rel.filter((item) => item.diffidato && item.team_id == this.rosaTeamId).forEach((el, index) => {					
						this.form.partita.diffidati[k] = {player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id } };	
						k++;	
					});

				/* diffidati avversario */
					k = 0;
					response.data.persona_rel.filter((item) => item.diffidato && item.team_id != this.rosaTeamId).forEach((el, index) => {
						if(el.persona) {
							this.form.partita.diffidati_opponent[k] = {player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id } };
							k++;
						}
					});

				/* squalificati */
					var k = 0;					
					response.data.persona_rel.filter((item) => item.partita_persona_tipo_glo_id == 3 && item.team_id == this.rosaTeamId).forEach((el, index) => {							
						this.form.partita.squalificati[k] = {player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id } };	
						k++;	
					});

				/* squalificati avversario */
					k = 0;
					response.data.persona_rel.filter((item) => item.partita_persona_tipo_glo_id == 3 && item.team_id != this.rosaTeamId).forEach((el, index) => {
						if( el.persona ) {
							this.form.partita.squalificati_opponent[k] = {player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, opponent_id: el.persona_id } };
							k++;
						}
					});

				/* titolari staff */
					k = 0;
					_.sortBy(response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 12) && item.team_id == this.rosaTeamId), ['id']).forEach((el, index) => {
						this.form.partita.staff_titolari[k] = {staff: {id: el.persona_id, name: el.persona.nome + ' ' + el.persona.cognome }};
						k++;
					});

				/* panchina staff */
					k = 0;
					_.sortBy(response.data.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 13) && item.team_id == this.rosaTeamId), ['id']).forEach((el, index) => {
						this.form.partita.staff_panchina[k] = {staff: {id: el.persona_id, name: el.persona.nome + ' ' + el.persona.cognome }};
						k++;
					});	

					if(response.data.sessione_rel) {
						response.data.sessione_rel.forEach((item) => {
							if(item.sessione && item.sessione.sessione_tipo_glo_id == 15) {
								this.training_id = item.entita_id;
							}
						});
					}

					if(this.form.partita.type && this.form.partita.type.name === 'Campionato' && this.form.partita.competition_id) {
						this.fetchRankingParams()
					}
				}
			}, (response) => {
			});
},

getOpponents: function(match_id, opponent_id) {
	axios.get(`/football/partita/opponents/` + match_id + `/` + opponent_id)
	.then((res) => {	
		this.opponents = res.data.map(item => ({
			name: item.nome + ' ' + item.cognome,
			id: item.id,
			shirt: item.last_carriera_sportiva.maglia,
		}));

		this.options_opponents = this.opponents.slice();

		this.opponents_opts = this.options_opponents.slice().map(item => ({
			name: item.name,
			id: item.id,
			opponent_id: item.id
		}));

		var first = {
			id: null,
			name: 'Seleziona',
		}

		this.options_opponents.unshift(first);

		/* options avversario */
		for(var k in this.form.partita.match_players_opponent) {
			var item = this.form.partita.match_players_opponent[k];
			for(var i in this.options_opponents) {
				var option = this.options_opponents[i];
				if(option.id == item.id) {
					this.options_opponents.splice(i, 1);
					break;
				}
			}
			this.selected_opponents[k] = item.id;
		}
	}).catch((err) => {
	});
},

getPlayers: function() {
	this.$http.get(this.$store.state.apiEndPoint + '/players', {params: { team_id: this.rosaTeamId, season_id: this.season_id, no_stats: true, convocated: Object.keys(this.form.convocazione.convocated) }}).then((response) => {
		if (response.ok)
		{
			if(response.data.players) {
				this.players = response.data.players.map(item => ({
					name: item.nome + ' ' + item.cognome,
					id: item.id,
					shirt: item.last_carriera_sportiva.maglia,
					prova: item.last_carriera_sportiva.tipologia == 'prova' ? 1 : 0,
				}));

				this.options_players = this.players.slice();
				
				this.players_opts = this.options_players.slice().map(item => ({
					name: item.name,
					id: item.id,
				}));
				
				var first = {
					id: null,
					name: 'Seleziona',
				}
				this.options_players.unshift(first);

				/* options players */
				for(var k in this.form.partita.match_players) {
					var item = this.form.partita.match_players[k];
					for(var i in this.options_players) {
						var option = this.options_players[i];
						if(option.id == item.id) {
							this.options_players.splice(i, 1);
							break;
						}
					}
					this.selected_players[k] = item.id;
				}

				if(this.form.partita.type && this.form.partita.type.id == 5) {
					this.options_opponents = this.options_players.slice();
				}

			}
		}
	}, (response) => {
	});
},

selectPlayer(item, key) {
	var id = key.substr(6);
	var old = this.selected_players[id];
	if(old) {
		for(var i in this.players) {
			if(this.players[i].id == old) {
				this.options_players.push(this.players[i]);
			}
		}
	}
	if(item.id) {
		for(var i in this.options_players) {
			var option = this.options_players[i];
			if(option.id == item.id) {
				this.options_players.splice(i, 1);
				this.selected_players[id] = item.id;
				break;
			}
		}
		this.form.partita.match_players_shirt[id] = item.shirt;
	} else {
		this.selected_players[id] = null;
	}
}, 

selectOpponent(item, key) {
	var id = key.substr(7);
	var old = this.selected_opponents[id];
	if(old) {
		for(var i in this.opponents) {
			if(this.opponents[i].id == old) {
				this.options_opponents.push(this.opponents[i]);
			}
		}
	}
	if(item.id && item.id > 0) {
		for(var i in this.options_opponents) {
			var option = this.options_opponents[i];
			if(option.id == item.id) {
				this.options_opponents.splice(i, 1);
				this.selected_opponents[id] = item.id;
				break;
			}
		}
		this.form.partita.match_players_opponent_shirt[id] = item.shirt;
	} else {
		this.selected_opponents[id] = null;
	}
},

addNewPlayerOpponent() {
	if(this.form.partita.opponent_team_id && this.form.partita.categoria) {
		let routeData = this.$router.resolve({ path: "/football/opponents/" + this.form.partita.opponent_team_id + "/" + this.form.partita.categoria.id });
		window.open(routeData.href, '_blank');
		this.saveMatch();
	}
},

downloadPdf(){
	window.open(this.$store.state.apiEndPoint + '/partita/pdf/' + this.$route.params.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
},
distintaPdf(){
	window.open(this.$store.state.apiEndPoint + '/distinta/pdf/' + this.$route.params.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
},
distintaSingolaPdf(){
	window.open(this.$store.state.apiEndPoint + '/distinta-singola/pdf/' + this.$route.params.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
},
convocatiPdf(){
	window.open(this.$store.state.apiEndPoint + '/convocati/pdf/' + this.$route.params.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
},
arbitriPdf(){
	window.open(this.$store.state.apiEndPoint + '/arbitri/pdf/' + this.$route.params.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
},
anteprimaDistinta() {
	window.open('https://app.asromasystem.com/plain/monitor/#/report-pre-partita/' + this.$route.params.id);		
},
locateAddress: function() {
	this.geocoder = new window.google.maps.Geocoder();
	this.geocoder.geocode({ address: this.form.partita.luogo}, (results, status) => {
		if (status === google.maps.GeocoderStatus.OK) {
			this.$map.setCenter(results[0].geometry.location);
			this.$map.setZoom(17);
			this.$marker.setPosition(results[0].geometry.location);
			this.$marker.setVisible(true);
		}
	});
},
addPLayer() {
	this.n_players++;
	this.selected_players[this.n_players - 1] = null;
}, 

addOpponent() {
	this.n_opponents++;
	this.selected_opponents[this.n_opponents - 1] = null;
},

getCompetizioni: function() {
	var competizioni_by_categoria = this.getCache('competizioni_by_cat_' + this.rosaTeamId);
	if(competizioni_by_categoria) {
		this.competizioni = competizioni_by_categoria;
	} else {
		this.$http.get(this.$store.state.apiEndPoint + '/football/competizioni/by/category', {params: { team_id: this.rosaTeamId }}).then((response) => {	
			this.competizioni = response.data;
			this.setCache('competizioni_by_cat_' + this.rosaTeamId, this.competizioni);
		});
	}
},

getCompetizioneFasi: function() {
	var competizione_fasi = this.getCache('competizione_fasi');
	if(competizione_fasi) {
		this.competizione_fasi = competizione_fasi;
	} else {
		this.$http.get(this.$store.state.apiEndPoint + '/football/competizione/fasi').then((response) => {	
			this.competizione_fasi = response.data;
			this.setCache('competizione_fasi', this.competizione_fasi);
		});
	}
},

getMeteo: function() {
	var meteo = this.getCache('meteo');
	if(meteo) {
		this.meteo = meteo;
	} else {
		this.$http.get(this.$store.state.apiEndPoint + '/meteo').then((response) => {
			this.meteo = response.data;
			this.setCache('meteo', this.meteo);
		});
	}
},

getCondizioniCampo: function() {
	var condizioni_campo = this.getCache('condizioni_campo');
	if(condizioni_campo) {
		this.condizioni_campo = condizioni_campo;
	} else {
		this.$http.get(this.$store.state.apiEndPoint + '/condizioni/campo').then((response) => {
			this.condizioni_campo = response.data;
			this.setCache('condizioni_campo', this.condizioni_campo);
		});
	}
},

getTipiCampo: function() {
	var tipi_campo = this.getCache('tipi_campo');
	if(tipi_campo) {
		this.tipi_campo = tipi_campo;
	} else {
		this.$http.get(this.$store.state.apiEndPoint + '/tipi/campo').then((response) => {
			this.tipi_campo = response.data;
			this.setCache('tipi_campo', this.tipi_campo);
		});
	}
},

getTipiStartStop: function() {
	this.tipi_start_stop = [
		{ id: 'stop', nome: 'Partita interrotta' },
		{ id: 'restart', nome: 'Partita ripresa' },
	];
},

getArbitri: function() {
	var arbitri = this.getCache('arbitri');
	if(arbitri) {
		this.arbitri = arbitri;
	} else {
		this.$http.get(this.$store.state.apiEndPoint + '/arbitri').then((response) => {
			this.arbitri = response.data;
			this.setCache('arbitri', this.arbitri);
		});
	}
},

getStaff: function() {	
	this.staff_members = [];
	var staff_members = this.getCache('staff_members');
	if(staff_members) {
		this.staff_members = staff_members;
	} else {
	this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: this.rosaTeamId }}).then((response) => {
		this.staff_members = response.data.map(item => ({
			name: item.nome + ' ' + item.cognome,
			id: item.id,
		}));
		this.setCache('staff_members', this.staff_members);
	});
	}
},

loadPlayersMatches() {
	this.$http.get(this.$store.state.apiEndPoint + '/report/players/matches', {params: { team_id: this.rosaTeamId, season_id: this.season_id, competition_id: this.form.partita.competition_id }}).then((response) => {
		if (response.ok)
		{
			this.players_matches = response.data;
			var values = Object.values(this.players_matches);
			var all_matches = values[0].matches;
			var index = all_matches.findIndex((item) => item.id == this.$route.params.id);
			var matches = all_matches.slice(0, index + 1).reverse();
			var players = values[0].players;
			var players_ids = Object.keys(players);
			for(var i in players_ids) {
				var rosa_id = players_ids[i];
				var player_obj = players[rosa_id];
				for(var j in matches) {
					var match = matches[j];
					var key = 'match_id_' + match.id;
					var match_player = player_obj[key];
					if(j == 0) {
						/*** squalificato ***/
						if(match_player.state == 5) {
							var foundPlayer = this.players_opts.find((item) => item.id == rosa_id);
							var foundIndex = this.form.partita.squalificati.findIndex((item) => item.player.id == foundPlayer.id);
							if(foundIndex == -1) {
								this.form.partita.squalificati.push({player: foundPlayer });	
							}
							break;
						}
					} else {
						/*** diffidato ***/
						if(match_player.state == 2) {
							var foundPlayer = this.players_opts.find((item) => item.id == rosa_id);
							var foundIndex = this.form.partita.diffidati.findIndex((item) => item.player.id == foundPlayer.id);
							if(foundIndex == -1) {
								this.form.partita.diffidati.push({player: foundPlayer });
							}	
							break;
						} else if(match_player.state > 0) {
							break;
						}
					}
				}
			}
		}
	}, (response) => {
	});
},

updateSqualificati() {
	this.$http.get(this.$store.state.apiEndPoint + '/report/players/matches', {params: { team_id: this.rosaTeamId, season_id: this.season_id }});
},

addArbitro(categoria) {
	this.form.partita[categoria].push({
		cognome: null,
		nome: null,
		sezione: null,
		stato: null,
	});
}, 

onArbitroSelect(id, index, category) {
	if(id > 0 && index > -1) {
		var persona = this.arbitri.find(item => item.id == id);
		if(persona) {
			this.form.partita[category][index].cognome = persona.cognome;
			this.form.partita[category][index].nome = persona.nome;
			this.form.partita[category][index].sezione = persona.arbitro.sezione;
			this.form.partita[category][index].stato = persona.arbitro.stato;
		}
	}
},
onArbitroChange(value, index, category) {
	this.form.partita[category][index].cognome = value;
	this.form.partita[category][index].nome = null;
	this.form.partita[category][index].sezione = null;
	this.form.partita[category][index].stato = null;
},
},
mounted() {
	this.autocomplete = new window.google.maps.places.Autocomplete(
		(this.$refs.autocomplete),
		{types: ['geocode']}
		);
	this.$map = new window.google.maps.Map(this.$refs.map, {
		zoom: 4,
		center: { lat: 41.871940, lng: 12.567380 },
		streetViewControl: false,
		mapTypeControl: false,
	});

	this.$marker = new window.google.maps.Marker({
		position: { lat: Number(41.871940), lng: Number(12.567380) },
		map: this.$map,
	});
	this.$marker.setVisible(false);

	this.autocomplete.addListener('place_changed', () => {
		let place = this.autocomplete.getPlace();

		const bounds = new window.google.maps.LatLngBounds();

		if (!place.geometry) {
			return;
		}
		if (place.geometry.viewport) {
			bounds.union(place.geometry.viewport);
		} else {
			bounds.extend(place.geometry.location);
		}
		this.$marker.setPosition(place.geometry.location);
		this.$marker.setVisible(true);

		this.$map.fitBounds(bounds);
		this.form.partita.luogo = place.formatted_address;
	}); 
	this.locateAddress();
}
}
</script>
