<template>
	<div>
		<b-nav vertical>
			<b-nav-item :to="{ name: 'PlayerPersonaDashboard' }" :style="isSelected('PlayerPersonaDashboard') ? navActiveStyle : ''">
				Dashboard
			</b-nav-item>
			
			<b-nav-item :to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'anagrafica-generale' } }"  :style="isSelected('PlayerPersonaAnagraficaGenerale', 'anagrafica-generale') ? navActiveStyle : ''">
				Anagrafica Generale
			</b-nav-item>
			
			<b-nav-item :to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'body-metrics' } }" :style="isSelected('PlayerPersonaAnagraficaGenerale', 'body-metrics') ? navActiveStyle : ''">
				Body Metrics
			</b-nav-item>
			
			<b-nav-item :to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'carriera' } }" :style="isSelected('PlayerPersonaAnagraficaGenerale', 'carriera') ? navActiveStyle : ''">
				Carriera
			</b-nav-item>
			
			<b-nav-item :to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'dati-economici' } }" :style="isSelected('PlayerPersonaAnagraficaGenerale', 'dati-economici') ? navActiveStyle : ''">
				Dati Economici
			</b-nav-item>
			
			<b-nav-item :to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'providers' } }" :style="isSelected('PlayerPersonaAnagraficaGenerale', 'providers') ? navActiveStyle : ''">
				Providers
			</b-nav-item>
		</b-nav>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			navActiveStyle: 'background-color: white; border-top-left-radius: 10px; border-bottom-left-radius: 10px;'
		}
	},
	methods: {
		isSelected: function(name, tab = null) {
			return name === this.$route.name && (tab === null || this.$route.query.tab === tab);
		}
	}
}

</script>