<template>
	<div>
		<b-alert :show="!sessione.id">Devi prima salvare la sessione per poterla collegare ad una partita!</b-alert>

		<b-overlay :show="loadingMatch" style="min-height: 150px">
			<div style="max-width: 50%; margin: auto" v-if="match">
				<b-card @click="goToPartita(match.id)" style="cursor:pointer">
					<b-row class="align-items-center">
						<b-col cols="5">
							<b-row class="align-items-center">
								<b-col cols="9" class="text-center">
									<img :src="match.home.logo_url" style="max-height: 60px" />
								</b-col>
								<b-col cols="3" class="text-center" style="font-size: 230%">
									{{ match.risultato.result.home }}
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="2" class="text-center" style="font-size: 200%">
							-
						</b-col>
						<b-col cols="5">
							<b-row class="align-items-center">
								<b-col cols="3" class="text-center" style="font-size: 230%">
									{{ match.risultato.result.away }}
								</b-col>
								<b-col cols="9" class="text-center">
									<img :src="match.away.logo_url" style="max-height: 60px" />
								</b-col>
							</b-row>
						</b-col>
					</b-row>

					<b-row class="align-items-center mt-2">
						<b-col cols="5">
							<b-row class="align-items-center">
								<b-col cols="9" class="text-center" style="line-height: 1; font-weight: bold">
									{{ match.home.officialName }}
								</b-col>
								<b-col cols="3" class="text-center" style="font-size: 230%">
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="2" class="text-center text-muted small">
							<div style="line-height: 1" v-if="match.competizione" class="mb-1"><em>{{ match.competizione.nome }}</em></div>
							<div style="line-height: 1">{{ match.data | matchDate }}</div>
						</b-col>
						<b-col cols="5">
							<b-row class="align-items-center">
								<b-col cols="3" class="text-center" style="font-size: 230%">
								</b-col>
								<b-col cols="9" class="text-center" style="line-height: 1; font-weight: bold">
									{{ match.away.officialName }}
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-card>
				<div class="text-center mt-2"><b-btn size="sm" @click.prevent="showPicker = true">Cambia partita</b-btn></div>
			</div>
		</b-overlay>

		<div v-if="sessione && sessione.id && showPicker">
			<b-form-group label="Partita Collegata">
				<b-input-group>
					<b-form-select v-model="partita_id">
						<b-form-select-option :value="null">-- Seleziona --</b-form-select-option>
						<b-form-select-option :value="partita.id" v-for="partita in partite">{{ partita.nome }}</b-form-select-option>
					</b-form-select>
					<b-input-group-append><b-btn size="sm" :disabled="!partita_id" @click.prevent="savePartitaRel">Salva</b-btn></b-input-group-append>
				</b-input-group>
			</b-form-group>	
		</div>
		
	</div>
</template>

<script>

	import sessionMixin from '@/mixins/sessione.js';
	import _ from 'lodash'
	import moment from 'moment'
	import * as types from '@/store/types'
	import { mapState } from 'vuex';

	export default {
		props: [ 'sessione' ],
		computed: {
			...mapState(['rosaTeamId', 'seasonId']),	
		},
		filters: {
			matchDate: function(d) {
				return moment(d).format('DD/MM/YY HH:mm')
			}
		},
		data: function() {
			return {
				partite: null,
				partita_id: null,
				match: null,
				showPicker: false,
				loadingMatch: false,
			}
		},
		watch: {
			'sessione.partita_rel': {
				immediate: true,
				handler: function() {
					if(this.sessione.partita_rel)
						this.getPartita(this.sessione.partita_rel.partita_id);
				}
			},
			showPicker: function() {
				if(this.showPicker && !this.partite) {
					this.fetchPartite();
				}
			}
		},
		created() {
			if(this.sessione && this.sessione.partita_rel) {
				this.partita_id = this.sessione.partita_rel.partita_id;
			} else {
				this.showPicker = true;
			}
		},
		methods: {
			fetchPartite() {
				const formData = new FormData();
				formData.append("team_id", this.rosaTeamId);
				formData.append("season_id", this.seasonId);
				this.$http.post(this.$store.state.apiEndPoint + '/football/partite', formData).then(function(response) {
					this.partite = response.data.map(item => ({
						nome: item.tipo_categoria.tipo.nome == 'Nazionale' ? '['+ item.tipo_categoria.tipo.nome + '] ' + item.home_team + ' VS ' + item.away_team : '['+ item.tipo_categoria.tipo.nome + '] ' + item.home.name + ' VS ' + item.away.name + ' - ' + moment(item.data).format('DD/MM/YYYY HH:mm'),
						id: item.id,
					}));
				});
			},
			savePartitaRel() {
				this.showPicker = false;
				this.$http.post(this.$store.state.apiEndPoint + '/sessione/save/partita/rel', { partita_id: this.partita_id, sessione_id: this.sessione.id }).then(function(response) {
					this.$emit('fetchSessione', this.sessione.id);
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Partita collegata con successo!', status: 1 });
				});
			},
			getPartita: function(partitaId) {
				this.loadingMatch = true;
				this.$http.get(this.$store.state.apiEndPoint + '/football/partita-lite/' + partitaId).then(function(data) {
					this.match = data.data;
					this.loadingMatch = false;
				})
			},
			goToPartita(id) {
				this.$router.push({ path: `/football/partita/${id}` })
			},
		}
	}

</script>