<template>
	<div>
		<ValutazioneGiocatoreFromGlo 
			:evaluation="evaluation.evaluation"
			:glossario="evaluation.glossario"
			:isOnlyRead="read"
			:hideTitle="hideTitle"
			@remove="$emit('remove')"
			@update:evaluation="updateEvalutaion"
		/>
	</div>
</template>

<script>

export default {
	props: [ 'evaluation', 'read', 'hideTitle' ],
	components: {
		ValutazioneGiocatoreFromGlo: require('@/components/layout/Valutazioni/ValutazioneGiocatoreFromGlo.vue').default
	},
	methods: {
		updateEvalutaion: function( evaluationMap ){
			this.$set(this.evaluation, 'evaluation', evaluationMap);
		}
	}
}

</script>