<template>
	<b-card>
		<template slot="header">Valutazioni giocatore</template>
		<div class="text-center">
			<div style="position: relative;">
				<E-Chart 
					@onClick="clickEvaluation"
					:option="chartOptions" 
					style="width: 100%; height: 180px">
				</E-Chart>
				<div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
					<div 
						style="font-size:2rem; font-weight: 700;"
						:style="{color: avgTotalColor}"
					> {{ valutazioniTotalAvg }} </div>
				</div>
			</div>
			<div style="z-index: 999999999; position: relative;" class="text-center">
				<span class="lead">{{ valutazioniTotal }} Valutazioni</span>
			</div>
		</div>
	</b-card>
</template>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue';

export default {
	components: {
		'E-Chart': eChart
	},
	emits: ['openEvaluation'],
	computed: {
		chartOptions: function() {

			return {
				// legend: {
				// 	bottom: '0%',
				// 	left: 'center'
				// },
				color: this.colors,
				tooltip: {
					trigger: 'item'
				},
				series: [{
					name: 'Valutazioni',
					type: 'pie',
					radius: ['62%', '90%'],
					avoidLabelOverlap: true,
					itemStyle: {
						borderRadius: 2,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						show: false,
					},
					// emphasis: {
					// 	label: {
					// 		show: true,
					// 		fontSize: 14,
					// 	}
					// },
					labelLine: {
						show: true
					},
					data: this.dataset
				}]
			}

		},
		avgTotalColor: function() {
			const colors = this.getBasicColors();
			let color = 'black';
			
			if( this.valutazioniTotalAvg >= 0 && this.valutazioniTotalAvg <= 1 )
				color = colors[0];
			else if( this.valutazioniTotalAvg > 1 && this.valutazioniTotalAvg <= 2 )
				color = colors[1];
			else if( this.valutazioniTotalAvg > 2 && this.valutazioniTotalAvg <= 3 )
				color = colors[2];
			else if( this.valutazioniTotalAvg > 3 && this.valutazioniTotalAvg <= 4 )
				color = colors[3];
			else if( this.valutazioniTotalAvg > 4 && this.valutazioniTotalAvg <= 5 )
				color = colors[4];

			return color
		}
	},
	mounted: function(){
		this.fetchData();
	},
	methods: {
		fetchData: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/aggregato/get/' + this.playerId ).then(
				function({body: data}) {
					this.dataset = [
						{ value: data.qty_val_0_1 ?? 0, name: 'Valutazione 0-1', key: 'qty_val_0_1' },
						{ value: data.qty_val_1_2 ?? 0, name: 'Valutazione 1-2', key: 'qty_val_1_2' },
						{ value: data.qty_val_2_3 ?? 0, name: 'Valutazione 2-3', key: 'qty_val_2_3' },
						{ value: data.qty_val_3_4 ?? 0, name: 'Valutazione 3-4', key: 'qty_val_3_4' },
						{ value: data.qty_val_4_5 ?? 0, name: 'Valutazione 4-5', key: 'qty_val_4_5' }
					];

					this.valutazioniTotal = (data.qty_val_0_1 ?? 0) + (data.qty_val_1_2 ?? 0) + (data.qty_val_2_3 ?? 0) + (data.qty_val_3_4 ?? 0) + (data.qty_val_4_5 ?? 0);
					this.valutazioniTotalAvg = data.val_total_avg ? data.val_total_avg.toFixed(2) : 'N/A';
					
					if( this.valutazioniTotal === 0 ){
						this.colors = ['#dddddd']
						this.dataset = [{ value: 0, name: 'Nessuna valutazione' }]
					}else{
						this.colors = this.getBasicColors()
					}
				}
			);
		},
		getBasicColors: function() {
			return [
				'#674ea7',
				'#ea4336',
				'#ff6d01',
				'#34a853',
				'#46bdc6'
			]
		},
		clickEvaluation: function(params) {
			this.$emit('openEvaluation', params.data.key);
		}
	},
	props: [ 'playerId' ],
	data: function() {
		return {
			dataset: [],
			valutazioniTotal: 0,
			valutazioniTotalAvg: 0,
			colors: this.getBasicColors(),
		}
	}
}

</script>