<template>
	<b-row class="d-flex align-items-center mb-2">
        <b-col>
            <p class="lead ml-1 mb-0">
                <span :style="heading.label_style">
                    {{heading.label}} 
                </span>
            </p>
        </b-col>
        <b-col class="text-right" v-if="!isOnlyRead">
            <a 
                href="javascript:;" 
                @click.prevent="$emit('remove')"
            >
                &times; Rimuovi
            </a>
        </b-col>
    </b-row>
</template>

<script>

export default {
	props: [ 'heading', 'isOnlyRead' ]
}

</script>