<template>
    <b-tr>
        <b-td>
            <span :style="kpi.label_style">
                {{kpi.label}}                
            </span>
        </b-td>
        <b-td>
            <b-input 
                size="sm" 
                :readonly="!kpi.input_value.editable || isOnlyRead"
                v-model="localEvaluation[group.slug][kpi.slug].note"
            />
        </b-td>
        <b-td>
            <Smart-Tag 
                :readonly="!kpi.input_value.editable || isOnlyRead"
                v-model="localEvaluation[group.slug][kpi.slug].value"
                @input="$emit('update-subgroup-kpi')"
            >
            </Smart-Tag>
        </b-td>
    </b-tr>
</template>

<script>

export default {
	props: [ 'kpi', 'group', 'evaluation', 'isOnlyRead' ],
    data() {
        return {
            localEvaluation: this.evaluation
        };
    },
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	}
}

</script>