<template>
	<div>
		<b-card no-body :class="'p-2 mb-1' + (hasVal(player.persona_id) ? ' border-dark' : '')" v-for="(player, index) in theFormazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'">
			<b-row align-v="center">
				<b-col :cols="3">
					<b-row align-v="center">
						<b-col cols="2">
							<strong>{{ player.shirtNumber }}</strong>
						</b-col>
						<b-col cols="3">
							<b-img :src="player.imageDataURL" :height="60"/>
						</b-col>
						<b-col>
							<router-link :to="{ name: 'PlayerPersonaDashboard', params: { persona_id: player.persona_id }}" target="_blank" v-if="player.persona_id">
								<div>{{ player.shortName }}</div>
							</router-link>
							<div v-else> {{ player.shortName }}</div>
							<em>{{ player.role.code3 }}</em>
						</b-col>
					</b-row>
				</b-col>
				<b-col :cols="1" class="text-center">
					<div style="font-weight: bold;font-size:1.8rem" v-if="hasVal(player.persona_id)"><b-badge :style="'width:90%;background-color:' + getBgColor(getVotoValutazione(hasVal(player.persona_id)))" class="p-1">{{ getVotoValutazione(hasVal(player.persona_id)) }}</b-badge></div>
					<div v-else> - </div>
				</b-col>
				<b-col style="font-size:0.85rem;text-align: justify;">
					<div v-if="hasVal(player.persona_id)">
						{{ getGiudizioValutazione(hasVal(player.persona_id)) }}
					</div>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

export default {
	props: [ 'formazione', 'valutazioni' ],
	methods: {
		hasVal: function(persona_id) {
			return this.valutazioni.find(item => item.persona_valutata.entita_id == persona_id);
		},
		getBgColor(value) {
			var color = '#FFFFFF';
			var valore = _.ceil(value);
			if(valore == 5) {
				color = '#46bdc6';
			} else if(valore == 4) {
				color = '#34a853';
			} else if(valore == 3) {
				color = '#ff6d01';
			} else if(valore == 2) {
				color = '#ea4336';
			} else if(valore == 1) {
				color = '#674ea7';
			}
			return color;
		},
		getVotoValutazione(val) {
			return val.evaluation.conclusion.total.value;
		},
		getGiudizioValutazione(val) {
			return val.evaluation.conclusion.total.note;
		},
	},
	computed: {
		theFormazione: function() {
			return this.formazione.sort(function(a, b) { 
				var roles_ws = ['GKP', 'DEF', 'MID', 'FWD', 'N/D'];
				var role_a = a.role ? a.role.code3 : 'N/D';
				var role_b = b.role ? b.role.code3 : 'N/D';
				return roles_ws.indexOf(role_a) - roles_ws.indexOf(role_b);
			});
		}
	}

}

</script>