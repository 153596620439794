<template>
    <b-card class="mt-2">
        <template slot="header">
            <span :style="footer.label_style">
                {{footer.label}}
            </span>
        </template>
        <div>
            <b-row>
                <b-col v-if="footer.has_description">
                    <b-textarea 
                        size="sm" 
                        v-model="localEvaluation[footer.slug].total.note"
                        :readonly="isOnlyRead"
                    >
                    </b-textarea>
                </b-col>
                <b-col cols="2"> 
                    <Smart-Tag 
                        :show="footer.input_value.visible"
                        :readonly="!footer.input_value.editable || isOnlyRead" 
                        :value="localEvaluation[footer.slug].total.value"
                    >
                    </Smart-Tag>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>

export default {
	props: [ 'footer', 'evaluation', 'isOnlyRead' ],
    data() {
        return {
            localEvaluation: this.evaluation
        };
    },
	components: {
        SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
    }
}

</script>