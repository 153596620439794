<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<!-- <pre style="background-color: white">{{whiteboard}}</pre> -->

			<b-card class="border-0">
				<b-row>
					<b-col cols="3">
						<b-form-group label="Board date" horizontal>
							<b-form-input v-model="board_date" type="date"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				<div v-if="whiteboard && whiteboard.data != board_date" class="mb-3 color-rosso">
					Whiteboard not found for the selected date! The data of <strong>{{ whiteboard.data |formatDate }}</strong> has been loaded. <br/>
					Press <strong>Save</strong> if you want to add the whiteboard for the selected day.
				</div>
				<b-tabs>
					<b-tab v-if="auth.isAdmin() || auth.isUserEnable('whiteboard_config_players')" title="Players" active>
						<div :key="random_player" v-if="!loading" ref="page_1">
							<b-table striped show-empty small
							:items="players_items"
							:fields="visible_players_fields"
							ref="dashtable"
							head-variant="dark"
							>
							<template #cell(index)="row">
								{{ row.index + 1 }}
							</template>
							<template #cell(cognome)="row">
								<b-row>
									<b-col>
										{{ row.value }}
									</b-col><b-col cols="2" v-if="row.item.ruolo == 'PO'"><span class="ml-2" title="Goalkeeper"><i class="fa fa-hand-paper-o" aria-hidden="true" style="color:#000000"></i></span></b-col>
								</b-row>
							</template>
							<template #cell(nickname)="row">
								<b-row>
									<b-col>
										<b-form-input v-model="row.item.nickname" maxlength="15"></b-form-input>
									</b-col>
									<b-col>
										<b-form-input v-model="row.item.nickname_2" maxlength="15"></b-form-input>
									</b-col>
								</b-row>
							</template>
							<template #cell(group)="row">
								<b-form-select v-model="row.item.group">
									<option value=""></option>
									<option v-for="item in players_gruppi" :value="item">{{ item }}</option>
								</b-form-select>
							</template>
							<template #cell(none)="row">
								<b-form-radio value="" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('', row.item.id)"></b-form-radio>
							</template>
							<template #cell(present)="row">
								<b-form-radio value="present" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('present', row.item.id)"></b-form-radio>
							</template>
							<template #cell(recovery)="row">
								<b-form-radio value="recovery" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('recovery', row.item.id)"></b-form-radio>
							</template>
							<template #cell(individual)="row">
								<b-form-radio value="individual" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('individual', row.item.id)"></b-form-radio>
							</template>
							<template #cell(injured)="row">
								<b-form-radio value="injured" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('injured', row.item.id)"></b-form-radio>
							</template>
							<template #cell(absent)="row">
								<b-form-radio value="absent" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('absent', row.item.id)"></b-form-radio>
							</template>
							<template #cell(dayoff)="row">
								<b-form-radio value="dayoff" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('dayoff', row.item.id)"></b-form-radio>
							</template>
							<template #cell(national)="row">
								<b-form-radio value="national" :name="'p' + row.item.id" v-model="row.item.state" @change="updateStatePlayer('national', row.item.id)"></b-form-radio>
							</template>
							<template #cell(label)="row">
								<b-row>
									<b-col cols="10">
										<b-form-input v-model="row.item.label" maxlength="15" :style="'background:' + (row.item.label_lighted ? '#FFFD54' : '')"></b-form-input>
									</b-col>
									<b-col cols="2">
										<b-form-checkbox v-model="row.item.label_lighted">
										</b-form-checkbox>
									</b-col>
								</b-row>
							</template>
							<template #cell(note)="row">
								<b-row>
									<b-col cols="10">
										<b-form-input v-model="row.item.note" :style="'background:' + (row.item.note_lighted ? '#FFFD54' : '')"></b-form-input>
									</b-col>
									<b-col cols="2">
										<b-form-checkbox v-model="row.item.note_lighted">
										</b-form-checkbox>
									</b-col>
								</b-row>
							</template>
							<template #head(flash)>
								<i class="fa fa-bolt" aria-hidden="true"></i>
							</template>
							<template #cell(flash)="row">
								<b-form-checkbox v-model="row.item.flash"></b-form-checkbox>
							</template>
						</b-table>
					</div>
				</b-tab>
				<b-tab title="Staff" v-if="auth.isAdmin() || auth.isUserEnable('whiteboard_config_staff')">
					<div :key="random_staff" v-if="!loading" ref="page_2">
						<b-table striped show-empty small
						:items="staff_items"
						:fields="staff_fields"
						ref="dashtable"
						head-variant="dark"
						>
						<template #cell(index)="row">	
							{{ row.index + 1 }}
						</template>
						<template #cell(nickname)="row">
							<b-row>
								<b-col>
									<b-form-input v-model="row.item.nickname" maxlength="15"></b-form-input>
								</b-col>
								<b-col>
									<b-form-input v-model="row.item.nickname_2" maxlength="15"></b-form-input>
								</b-col>
							</b-row>
						</template>
						<template #cell(group)="row">
							<b-form-select v-model="row.item.group">
								<option value=""></option>
								<option v-for="item in staff_gruppi" :value="item">{{ item }}</option>
							</b-form-select>
						</template>
						<template #cell(none)="row">
							<b-form-radio value="" :name="'s' + row.item.id" v-model="row.item.state" @change="updateStateStaff('', row.item.id)"></b-form-radio>
						</template>
						<template #cell(present)="row">
							<b-form-radio value="present" :name="'s' + row.item.id" v-model="row.item.state" @change="updateStateStaff('present', row.item.id)"></b-form-radio>
						</template>
						<template #cell(absent)="row">
							<b-form-radio value="absent" :name="'s' + row.item.id" v-model="row.item.state" @change="updateStateStaff('absent', row.item.id)"></b-form-radio>
						</template>
						<template #cell(dayoff)="row">
								<b-form-radio value="dayoff" :name="'s' + row.item.id" v-model="row.item.state" @change="updateStateStaff('dayoff', row.item.id)"></b-form-radio>
							</template>
						<template #cell(national)="row">
							<b-form-radio value="national" :name="'s' + row.item.id" v-model="row.item.state" @change="updateStateStaff('national', row.item.id)"></b-form-radio>
						</template>
						<template #cell(course-master)="row">
							<b-form-radio value="course-master" :name="'s' + row.item.id" v-model="row.item.state" @change="updateStateStaff('course-master', row.item.id)"></b-form-radio>
						</template>
						<template #cell(label)="row">
							<b-row>
								<b-col cols="10">
									<b-form-input v-model="row.item.label" maxlength="15" :style="'background:' + (row.item.label_lighted ? '#FFFD54' : '')"></b-form-input>
								</b-col>
								<b-col cols="2">
									<b-form-checkbox v-model="row.item.label_lighted">
									</b-form-checkbox>
								</b-col>
							</b-row>
						</template>
						<template #cell(note)="row">
							<b-row>
								<b-col cols="10">
									<b-form-input v-model="row.item.note" :style="'background:' + (row.item.note_lighted ? '#FFFD54' : '')"></b-form-input>
								</b-col>
								<b-col cols="2">
									<b-form-checkbox v-model="row.item.note_lighted">
									</b-form-checkbox>
								</b-col>
							</b-row>
						</template>
						<template #head(flash)>
							<i class="fa fa-bolt" aria-hidden="true"></i>
						</template>
						<template #cell(flash)="row">
							<b-form-checkbox v-model="row.item.flash"></b-form-checkbox>
						</template>
					</b-table>
				</div>
			</b-tab>
			<b-tab title="Info" v-if="auth.isAdmin() || auth.isUserEnable('whiteboard_config_info')">
				<div v-if="!loading">
					<b-row>
						<b-col cols="6" class="p-3">
							<b-row>
								<b-col class="mr-2">
									<b-form-group label="Convocation from">
										<b-form-input v-model="hour_1" type="time"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col class="ml-2">
									<b-form-group label="Convocation to">
										<b-form-input v-model="hour_2" type="time"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-form-group label="Location">
								<b-form-checkbox-group v-model="location">
									<b-form-checkbox :value="1">Locker Room</b-form-checkbox>
									<b-form-checkbox :value="2">Sala Video</b-form-checkbox>
									<b-form-checkbox :value="3">Gym</b-form-checkbox>
								</b-form-checkbox-group>
							</b-form-group>
							<b-form-group label="Pitch" v-if="$route.meta.module == 'whiteboard'">
								<b-form-checkbox-group v-model="pitch">
									<b-form-checkbox :value="1">B</b-form-checkbox>
									<b-form-checkbox :value="2">C</b-form-checkbox>
									<b-form-checkbox :value="3">Testaccio</b-form-checkbox>
									<b-form-checkbox :value="4">Nuovo Testaccio</b-form-checkbox>
								</b-form-checkbox-group>
							</b-form-group>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Programma settimanale">
										<b-form-input v-if="programma_settimanale" v-model="programma_settimanale" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_programma_settimanale"
										:state="Boolean(file_programma_settimanale)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Programma trimestrale">
										<b-form-input v-if="programma_trimestrale" v-model="programma_trimestrale" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_programma_trimestrale"
										:state="Boolean(file_programma_trimestrale)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Coppa Italia">
										<b-form-input v-if="coppa_italia" v-model="coppa_italia" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_coppa_italia"
										:state="Boolean(file_coppa_italia)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="UEL">
										<b-form-input v-if="uel" v-model="uel" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_uel"
										:state="Boolean(file_uel)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Icona 1">
										<b-form-input v-if="icon_1" v-model="icon_1" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_icon_1"
										:state="Boolean(file_icon_1)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Icona 2">
										<b-form-input v-if="icon_2" v-model="icon_2" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_icon_2"
										:state="Boolean(file_icon_2)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Icona 3">
										<b-form-input v-if="icon_3" v-model="icon_3" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_icon_3"
										:state="Boolean(file_icon_3)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Icona 4">
										<b-form-input v-if="icon_4" v-model="icon_4" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_icon_4"
										:state="Boolean(file_icon_4)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Icona 5">
										<b-form-input v-if="icon_5" v-model="icon_5" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_icon_5"
										:state="Boolean(file_icon_5)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="$route.meta.module == 'whiteboard'">
								<b-col>
									<b-form-group label="Icona 6">
										<b-form-input v-if="icon_6" v-model="icon_6" type="text" plaintext></b-form-input>
										<b-form-input v-else value="Nessun file associato!" type="text" plaintext></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Nuovo file">
										<b-form-file
										v-model="file_icon_6"
										:state="Boolean(file_icon_6)"
										placeholder="Scegli o trascina un file da caricare..."
										drop-placeholder="Trascina un file qui..."
										></b-form-file>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="6" class="p-3" v-if="$route.meta.module == 'whiteboard'">
							<div class="mb-2">News</div>
							<b-row v-for="(item, index) in news" class="mb-2" :key="index" align-v="center">
								<b-col>
									<b-form-input type="text" v-model="item.text"></b-form-input>
								</b-col>
								<b-col cols="1">
									<b-button size="sm" variant="primary" @click="removeNews(item)"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
								</b-col>	
							</b-row>
							<b-row>
								<b-col class="text-left">
									<b-button size="sm" variant="primary" @click="addNews">Add news <i class="fa fa-plus" aria-hidden="true"></i></b-button>
								</b-col>
							</b-row>	
							<b-row class="mt-2">
								<b-col>
									<b-form-group label="Countdown partita">
										<b-form-select v-model="countdown_match_id">
											<option :value="null">Prossima partita</option>
											<option :value="false">Nessuna partita</option>
											<option v-for="item in prossime_partite" :value="item.id">{{ getLabelPartita(item) }}</option>
										</b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
							<b-form-group label="Seleziona la sorgente del video:">
								<b-form-radio-group v-model="video_source" :options="type_options">
								</b-form-radio-group>
							</b-form-group>
							<b-form-group v-if="video_source == 'youtube'" label="Youtube Video ID">
								<b-form-input v-model="highlights" type="text"></b-form-input>
							</b-form-group>
							<b-row v-if="video_source == 'asroma'">
								<b-col>
									<VideoManager entity="Whiteboard" :entityId="whiteboard.id" :readonly="whiteboard.data != board_date" @countVideo="updateCountVideo" :hideNewVideo="countVideo > 0 ? true : false"></VideoManager>
								</b-col>
							</b-row>
							<b-row v-if="whiteboard && whiteboard.tools_preset">
								<b-col class="text-left">
									<b-button size="sm" variant="primary" @click="resetTools">Reset Tools <i class="fa fa-trash" aria-hidden="true"></i></b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</div>
			</b-tab>
		</b-tabs>
		<div class="mt-2">
			<b-button variant="primary" @click.prevent="saveWhiteboard" class="mr-1">Save</b-button>
			<b-button variant="success" class="mr-1" @click="downloadWhiteboard">Download pdf</b-button>
			<b-button v-if="$route.meta.module == 'whiteboard'" :to="{ name: 'witheboard_dashboard'}">Back to Whiteboard</b-button>
		</div>
	</b-card>
</div>

<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { mapState } from 'vuex';
export default {

	data: function() {
		return {
			loading: false,
			whiteboard: null,
			prossime_partite: [],
			players: [],
			players_config: [],
			staffs: [],
			config_staff: [],
			players_fields: [
				{ key: 'index', label: 'Number', class: 'text-center' },
				{ key: 'cognome', label: 'Last Name', class: 'text-left', thClass: 'text-center' },
				{ key: 'nome', label: 'First Name', class: 'text-left', thClass: 'text-center' },
				{ key: 'nickname', label: 'Nickname', class: 'text-center' },
				{ key: 'ruolo', label: 'Role', class: 'text-center' },
				{ key: 'group', label: 'Group', class: 'text-center' },
				{ key: 'none', label: 'None', class: 'text-center', thClass:'table-none'},
				{ key: 'present', label: 'Present', class: 'text-center', thClass:'table-present'},
				{ key: 'recovery', label: 'Recovery', class: 'text-center', thClass:'table-recovery' },
				{ key: 'individual', label: 'Apart', class: 'text-center', thClass:'table-individual' },
				{ key: 'injured', label: 'Injured', class: 'text-center', thClass:'table-injured' },
				{ key: 'dayoff', label: 'Day Off', class: 'text-center', thClass:'table-dayoff' },
				{ key: 'absent', label: 'Absent', class: 'text-center', thClass:'table-absent' },
				{ key: 'national', label: 'National T.', class: 'text-center', thClass:'table-national' },
				{ key: 'label', label: 'State', class: 'text-center' },
				{ key: 'note', label: 'Note', class: 'text-center' },
				{ key: 'flash', label: '', class: 'text-center', thClass:'table-recovery' },
				],
			staff_fields: [
				{ key: 'index', label: 'Number', class: 'text-center' },
				{ key: 'cognome', label: 'Last Name', class: 'text-left', thClass: 'text-center' },
				{ key: 'nome', label: 'First Name', class: 'text-left', thClass: 'text-center' },
				{ key: 'nickname', label: 'Nickname', class: 'text-center' },
				{ key: 'ruolo', label: 'Role', class: 'text-center' },
				{ key: 'group', label: 'Group', class: 'text-center' },
				{ key: 'none', label: 'None', class: 'text-center', thClass:'table-none'},
				{ key: 'present', label: 'Present', class: 'text-center', thClass:'table-present'},
				{ key: 'dayoff', label: 'Day Off', class: 'text-center', thClass:'table-dayoff' },
				{ key: 'absent', label: 'Absent', class: 'text-center', thClass:'table-absent' },
				{ key: 'national', label: 'National T.', class: 'text-center', thClass:'table-national' },
				{ key: 'course-master', label: 'Course/Master', class: 'text-center', thClass:'table-course-master' },
				{ key: 'label', label: 'State', class: 'text-center' },
				{ key: 'note', label: 'Note', class: 'text-center' },
				{ key: 'flash', label: '', class: 'text-center', thClass:'table-recovery' },
				],
			players_items: [],
			staff_items: [],
			players_stati: ['present', 'recovery', 'individual', 'injured', 'dayoff', 'absent', 'national', ''],
			staff_stati: ['present', 'dayoff', 'absent', 'national', 'course-master', ''],
			players_gruppi: ['Giocatori'],
			staff_gruppi: ['Allenatori', 'Medici', 'Manager', 'Preparatori atletici', 'Fisioterapisti', 'Interpreti', 'Recupero infortunati', 'Nutrizionisti', 'Magazzinieri', 'Football perf. analysis', 'Podologi', 'Sport Science', 'Consulenti'],
			random_player: Math.random(),
			random_staff: Math.random(),
			board_date: null,
			hour_1: null,
			hour_2: null,
			location: [],
			pitch: [],
			news: [],
			highlights: null,
			programma_trimestrale: null,
			programma_settimanale: null,
			coppa_italia: null,
			uel: null,
			icon_1: null,
			icon_2: null,
			icon_3: null,
			icon_4: null,
			icon_5: null,
			icon_6: null,
			file_programma_trimestrale: null,
			file_programma_settimanale: null,
			file_coppa_italia: null,
			file_uel: null,
			file_icon_1: null,
			file_icon_2: null,
			file_icon_3: null,
			file_icon_4: null,
			file_icon_5: null,
			file_icon_6: null,
			countdown_match_id: null,
			countVideo: 0,
			type_options: [
				{ text: 'Youtube', value: 'youtube' }, 
				{ text: 'AS Roma', value: 'asroma' },
			],
			video_source: 'youtube',
		}
	},

	components: {
		headapp: require('../../layout/headapp.vue').default,
		menusub: require('../../layout/menusub.vue').default,
		footerapp: require('../../layout/footerapp.vue').default,
		card: require('../../layout/card.vue').default,
		VideoManager: require('@/components/layout/video/manager.vue').default,
	},

	created: function () {
		this.getPlayers();
		this.getProssimePartite();
	},

	computed: {
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'categoriaId']),

		visible_players_fields: function() {
			if(this.$route.meta.module == 'whiteboard') {
				return this.players_fields;
			}
			var keys = ['group', 'label', 'flash'];
			return this.players_fields.filter(item => !keys.includes(item.key));
		}
	},

	methods: {

		getWhiteboard: function() {
			this.whiteboard = null;
			this.loading = true;				
			this.$http.get(this.$store.state.apiEndPoint + '/whiteboard/get/by/date/' + this.board_date, {params: {categoria_id: this.categoriaId, team_id: this.rosaTeamId}}).then((response) => {
				if (response.ok)
				{
					this.whiteboard = response.data;
					this.hour_1 = response.data.hour_1;
					this.hour_2 = response.data.hour_2;
					this.location = response.data.location ? JSON.parse(response.data.location) : [];
					this.pitch = response.data.pitch ? JSON.parse(response.data.pitch) : [];
					this.news = response.data.news != '' ? JSON.parse(response.data.news) : [];
					this.highlights = response.data.highlights;
					this.programma_trimestrale = response.data.programma_trimestrale;
					this.programma_settimanale = response.data.programma_settimanale;
					this.coppa_italia = response.data.coppa_italia;
					this.uel = response.data.uel;
					this.icon_1 = response.data.icon_1;
					this.icon_2 = response.data.icon_2;
					this.icon_3 = response.data.icon_3;
					this.icon_4 = response.data.icon_4;
					this.icon_5 = response.data.icon_5;
					this.icon_6 = response.data.icon_6;
					this.countdown_match_id = response.data.countdown_match_id;
					this.video_source = response.data.video_source ? response.data.video_source : 'youtube';
					this.config_players = JSON.parse(response.data.players);
					this.players_items = [];
					for(var i in this.players) {
						var player = this.players[i];
						var foundConfig = this.config_players.find(item => item.id == player.id);
						var item = {
							id: player.id,
							cognome: player.cognome,
							nome: player.nome, 
							nickname: foundConfig !== undefined ? (foundConfig.nickname ? foundConfig.nickname : '') : '', 
							nickname_2: foundConfig !== undefined ? (foundConfig.nickname_2 ? foundConfig.nickname_2 : '') : '',
							ruolo: player.last_carriera_sportiva.ruolo_ideale.length > 0 ? player.last_carriera_sportiva.ruolo_ideale[0].sigla : '',
							group: foundConfig !== undefined ? (foundConfig.group ? foundConfig.group : '') : '',
							state: foundConfig !== undefined ? foundConfig.state : '',
							label: foundConfig !== undefined ? (foundConfig.label ? foundConfig.label : '') : '',
							note: foundConfig !== undefined ? foundConfig.note : '',
							note_lighted: foundConfig !== undefined ? (foundConfig.note_lighted ? foundConfig.note_lighted : '') : '',
							label_lighted: foundConfig !== undefined ? (foundConfig.label_lighted ? foundConfig.label_lighted : '') : '',
							flash: foundConfig !== undefined ? (foundConfig.flash ? foundConfig.flash : '') : '',
							_cellVariants: { cognome: foundConfig !== undefined ? foundConfig.state : '', nome: foundConfig !== undefined ? foundConfig.state : '' },
						};
						this.players_items.push(item);
					}
					this.players_items.sort((a, b) => a.ruolo == 'PO' ? 1 : (b.ruolo == 'PO' ? -1 : 0));
					this.players_items.sort((a, b) => this.players_stati.indexOf(a.state) - this.players_stati.indexOf(b.state));

					this.config_staff = JSON.parse(response.data.staff);
					this.staff_items = [];
					for(var i in this.staff) {
						var member = this.staff[i];
						var foundConfig = this.config_staff.find(item => item.id == member.id);
						var item = {
							id: member.id,
							cognome: member.cognome,
							nome: member.nome,
							nickname: foundConfig !== undefined ? (foundConfig.nickname ? foundConfig.nickname : '') : '',
							nickname_2: foundConfig !== undefined ? (foundConfig.nickname_2 ? foundConfig.nickname_2 : '') : '',
							ruolo: member.last_carriera_sportiva.ruoli.length > 0 ? member.last_carriera_sportiva.ruoli[0].nome : '',
							group: foundConfig !== undefined ? (foundConfig.group ? foundConfig.group : '') : '',
							state: foundConfig !== undefined ? foundConfig.state : '',
							label: foundConfig !== undefined ? (foundConfig.label ? foundConfig.label : '') : '',
							note: foundConfig !== undefined ? foundConfig.note : '',
							note_lighted: foundConfig !== undefined ? (foundConfig.note_lighted ? foundConfig.note_lighted : '') : '',
							label_lighted: foundConfig !== undefined ? (foundConfig.label_lighted ? foundConfig.label_lighted : '') : '',
							flash: foundConfig !== undefined ? (foundConfig.flash ? foundConfig.flash : '') : '',
							_cellVariants: { cognome: foundConfig !== undefined ? foundConfig.state : '', nome: foundConfig !== undefined ? foundConfig.state : '' },
						};
						this.staff_items.push(item);
					}
					this.staff_items.sort((a, b) => this.staff_stati.indexOf(a.state) - this.staff_stati.indexOf(b.state));
					this.loading = false;

				}
			}, (response) => {
			});
		},

		getPlayers: function() {
			this.loading = true;				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId }}).then((response) => {
				if (response.ok)
				{
					this.players = response.data;
					this.getStaff();
				}
			}, (response) => {
			});
		},

		getStaff: function() {
			this.loading = true;				
			this.staff = [];
			this.board_date = null;
			this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: this.rosaTeamId }}).then((response) => {
				if (response.ok)
				{
					this.staff = response.data;
					this.loading = false;
					this.board_date = moment().format("YYYY-MM-DD");
				//	this.getWhiteboard();
				}
			}, (response) => {
			});
		},

		updateStatePlayer(state, id) {
			var item = this.players_items.find((item) => item.id == id);
			item.state = state;
			item._cellVariants.cognome = state;
			item._cellVariants.nome = state;
			this.players_items.sort((a, b) => a.cognome.localeCompare(b.cognome));
			this.players_items.sort((a, b) => a.ruolo == 'PO' ? 1 : (b.ruolo == 'PO' ? -1 : 0));
			this.players_items.sort((a, b) => this.players_stati.indexOf(a.state) - this.players_stati.indexOf(b.state));
			this.random_player = Math.random();
		},

		updateStateStaff(state, id) {
			var item = this.staff_items.find((item) => item.id == id);
			item.state = state;
			item._cellVariants.cognome = state;
			item._cellVariants.nome = state;
			this.staff_items.sort((a, b) => a.cognome.localeCompare(b.cognome));
			this.staff_items.sort((a, b) => this.staff_stati.indexOf(a.state) - this.staff_stati.indexOf(b.state));
			this.random_staff = Math.random();
		},

		saveWhiteboard: function() {
			this.loading = true;
			var formData = new FormData();
			formData.append('players', JSON.stringify(this.players_items));
			formData.append('staff', JSON.stringify(this.staff_items));	
			formData.append('board_date', this.board_date);	
			formData.append('hour_1', this.hour_1);		
			formData.append('hour_2', this.hour_2);	
			formData.append('location', JSON.stringify(this.location));	
			formData.append('pitch', JSON.stringify(this.pitch));
			formData.append('positions', this.whiteboard.positions);
			formData.append('positions2', this.whiteboard.positions2);
			formData.append('news', JSON.stringify(this.news));	
			formData.append('highlights', this.highlights);	
			formData.append('programma_trimestrale', this.programma_trimestrale);	
			formData.append('programma_settimanale', this.programma_settimanale);
			formData.append('coppa_italia', this.coppa_italia);	
			formData.append('uel', this.uel);
			formData.append('icon_1', this.icon_1);
			formData.append('icon_2', this.icon_2);
			formData.append('icon_3', this.icon_3);
			formData.append('icon_4', this.icon_4);
			formData.append('icon_5', this.icon_5);
			formData.append('icon_6', this.icon_6);		
			formData.append("file_programma_trimestrale", this.file_programma_trimestrale);
			formData.append("file_programma_settimanale", this.file_programma_settimanale);
			formData.append("file_coppa_italia", this.file_coppa_italia);
			formData.append("file_uel", this.file_uel);
			formData.append('file_icon_1', this.file_icon_1);
			formData.append('file_icon_2', this.file_icon_2);
			formData.append('file_icon_3', this.file_icon_3);
			formData.append('file_icon_4', this.file_icon_4);
			formData.append('file_icon_5', this.file_icon_5);
			formData.append('file_icon_6', this.file_icon_6);	
			formData.append("countdown_match_id", this.countdown_match_id);
			formData.append("video_source", this.video_source);
			formData.append("whiteboard_id", this.whiteboard.id);
			formData.append("categoria_id", this.categoriaId);				
			formData.append("tools_preset", this.whiteboard.tools_preset);

			this.$http.post(this.$store.state.apiEndPoint + '/whiteboard/save', formData).then((response) => {
				if (response.ok)
				{
					this.getWhiteboard();
				}
			}, (response) => {
			});
		},

		downloadWhiteboard() {
			window.open(this.$store.state.apiEndPoint + '/whiteboard/download/' + (this.whiteboard ? this.whiteboard.id : 0) + "/" + Auth.user.id + "/" + Auth.user.api_token, '_blank');
		},

		addNews: function () {
			this.news.push({text: ''});
		},

		removeNews: function (item) {
			var index = this.news.indexOf(item);	
			this.news.splice(index, 1);
		},
		getProssimePartite: function() {
			this.loading = true;				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/public-atlas/prossime-partite').then((response) => {
				if (response.ok)
				{
					this.prossime_partite = response.data;
				}
			}, (response) => {
			});
		},
		getLabelPartita(partita) {
			var label = '';
			if(partita.competizione) {
				label += partita.competizione.name + ' - ';
			}
			label += partita.home ? partita.home.name : 'N/D';
			label += ' vs ';
			label += partita.away ? partita.away.name : 'N/D';
			label += ' ' + moment(partita.date).format('DD/MM/YYYY HH:mm');
			return label;
		},
		updateCountVideo: function(count) {
			this.countVideo = count;
		},
		resetTools: function() {
			if(confirm("Confermi di voler svuotare i tools ?")) {
				this.whiteboard.tools_preset = null;

				if(this.whiteboard.data == this.board_date) {
					this.$http.get(this.$store.state.apiEndPoint + '/whiteboard/reset-tools/' + (this.whiteboard ? this.whiteboard.id : 0)).then((response) => {
						this.getWhiteboard();
					});
				}
			}
		},
	},

	watch: {
		board_date: function () {
			if(this.board_date) {
				this.getWhiteboard();
			}
		},
		rosaTeamId() {
			this.getPlayers();
		},

	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
}
</script>
