<template>
	<div v-if="xPhysicalIndexes !== null">
		<b-row>
			<b-col cols="3" v-if="xPhysical" @click="openXPhy" style="cursor:pointer;">
				<PhysicalIndex :dataset="xPhysical"></PhysicalIndex>
			</b-col>
			<b-col cols="3" v-else>
				<EmptyPhysicalIndex />
			</b-col>
			<b-col cols="3" v-if="player">
				<DoughnutValutazioni
					:key="doughnutValutazioniRefresh"
					@openEvaluation="openEvaluationDetail"
					:playerId="player.persona.id"
				/>
			</b-col>
		</b-row>

		<!-- Show Physical Index Details -->
		<b-card v-if="showIndexDetail" class="mt-3">
			<template slot="header">{{ _.startCase(_.replace(xPhysical.gruppo, /\_/g, ' ')) }}</template>
			<b-row>
				<b-col cols="4">
					<b-card style="height:50vh;overflow-y:scroll;">
						<b-table-simple small>
							<b-tbody>
								<b-tr v-for="rank, i in xPhysical.ranking" :variant=" rank.persona.id == player.persona.id ? 'warning' : ''">
									<b-td style="vertical-align: middle;">{{ i + 1 }}</b-td>
									<b-td style="vertical-align: middle;"><b-img :src="rank.persona.avatar_url" :height="40"></b-img> {{ rank.persona.cognome }} {{ rank.persona.nome }}</b-td>
									<b-td style="vertical-align: middle;">{{ rank.xPhy }}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-card>
				</b-col>	
				<b-col>
					<b-card style="height:50vh;overflow-y:scroll;">
						<b-table-simple small>
							<b-thead>
								<b-tr>
									<b-th>Category</b-th>
									<b-th>Statistic</b-th>
									<b-th class="text-center">Bracket</b-th>
									<b-th class="text-center">Value</b-th>
									<b-th class="text-center">Points</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="stat, kpi, idx in stats" v-if="typeof stat === 'object'">
									<b-td>{{ getLabelCategory(_.values(_.cloneDeep(stats)), idx) }}</b-td>
									<b-td v-b-popover.hover.top="{ html: true, content: legendHtml(stat) }" style="cursor:pointer;">{{ stat.label }}</b-td>
									<b-td class="text-center">{{ getBracket(stat.range, stat.rangeIndex) }}</b-td>
									<b-td class="text-center">{{ stat.value }}</b-td>
									<b-td class="text-center">{{ stat.points }}/{{ stat.maxPoints }}</b-td>
								</b-tr>
							</b-tbody>
							<b-tfoot>
								<b-tr>
									<b-th colspan="4" class="text-right">Total of points</b-th>
									<b-th class="text-center">{{ stats.totalPoint }}</b-th>
								</b-tr>
								<b-tr>
									<b-th colspan="4" class="text-right">Total maximum</b-th>
									<b-th class="text-center">{{ stats.totalMaximum }}</b-th>
								</b-tr>
								<b-tr>
									<b-th colspan="4" class="text-right">Calculation of the index</b-th>
									<b-th class="text-center">({{ stats.totalPoint }} / {{ stats.totalMaximum }}) x 100</b-th>
								</b-tr>
								<b-tr>
									<b-th colspan="4" class="text-right">xPhy</b-th>
									<b-th class="text-center">{{ stats.xPhy }}</b-th>
								</b-tr>
							</b-tfoot>
						</b-table-simple>	
					</b-card>	
				</b-col>
			</b-row>
		</b-card>
		
		<!-- Show Evaluation Details -->
		<b-card v-if="showEvalutaionDetail" class="mt-3">
			<template slot="header">
				<div class="d-flex justify-content-between align-items-center">
					<div>
						Valutazioni da {{ evaluationFilters.total_from }} - {{ evaluationFilters.total_to }}
					</div> 
					<div class="text-right my-1">
						<b-button size="sm" @click.stop="showEvalutaionDetail = false">
							Chiudi
						</b-button>
					</div>
				</div>
			</template>
			<div>
				<PageValutazioni 
					@evalutationDeleted="doughnutValutazioniRefresh++"
					:player="player" 
					:evaluationFilters="evaluationFilters" 
				/>
			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	components: {
		PhysicalIndex: require('@/components/v2/Player/comp-xPhysicalIndex.vue').default,
		EmptyPhysicalIndex: require('@/components/v2/Player/Chart/EmptyXPhysicalIndex.vue').default,
		DoughnutValutazioni: require('@/components/v2/Player/Chart/DoughnutQuantitaValutazioni.vue').default,
		PageValutazioni: require('@/components/v2/Player/page-valutazioni.vue').default
	},
	props: [ 'player' ],
	data: function() {
		return {
			xPhysicalSelected: null,
			xPhysicalIndexes: null,
			showIndexDetail: false,
			showEvalutaionDetail: false,
			evaluationFilters: null,
			doughnutValutazioniRefresh: 0,
		}
	},
	computed: {
		_: function() {
			return _;
		},
		xPhysical: function() {
			return this.xPhysicalIndexes[this.xPhysicalSelected];
		},
		stats: function() {
			return this.xPhysical.index;
		}
	},
	watch: {
		player: {
			immediate: true,
			handler: function() {
				this.fetchIndex();
			}
		}
	},
	methods: {
		fetchIndex: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skillcorner/xPhysical/' + this.player.persona.id + '/test').then(function(data) {
				this.xPhysicalIndexes = data.data;
				this.xPhysicalSelected = _.size(data.data) - 1;
			});
		},
		getBracket: function(range, index) {
			var bracket = '-';
			if(index != -1) {
				var interval = range[index];
				if(interval.min && interval.max) {
					bracket = 'beetween ' + interval.min + ' and ' + interval.max;
				} else if(interval.min && !interval.max) {
					bracket = '> ' + interval.min;
				} else if(!interval.min && interval.max) {
					bracket = '< ' + interval.max;
				}
			}
			return bracket;
		},
		getLabelCategory: function(array_values, idx) {
			var cat = array_values[idx].category;
			if(idx > 0) {
				var prevCat = array_values[idx - 1].category;
				if(cat == prevCat) {
					cat = '';
				}
			}
			return cat;
		},
		legendHtml: function(stat) {
			var html = [];
			html.push('<div><strong>' + stat.label + '</strong></div>');
			_.forEach(stat.range, function(item) {
				var label = '';
				if(item.min && item.max) {
					label = 'beetween ' + item.min + ' and ' + item.max;
				} else if(item.min && !item.max) {
					label = '> ' + item.min;
				} else if(!item.min && item.max) {
					label = '< ' + item.max;
				}
				label += ' : +' + item.points;
				html.push('<div>' + label + '</div>');
			});

			return html.join("\n");
		},
		openEvaluationDetail: function( evalutionValuesGroup ) {
			
			let from=0, to=0

			switch ( evalutionValuesGroup ) {
				case 'qty_val_0_1':
					from = 0
					to = 1
					break;
				case 'qty_val_1_2':
					from = 1
					to = 2
					break;
				case 'qty_val_2_3':
					from = 2
					to = 3
					break;
				case 'qty_val_3_4':
					from = 3
					to = 4
					break;
				case 'qty_val_4_5':
					from = 4
					to = 5
					break;
			}

			this.evaluationFilters = {
				'total_from': from,
				'total_to': to
			};

			this.showIndexDetail = false;
			this.showEvalutaionDetail = true;
		},
		openXPhy: function() {
			this.showIndexDetail = !this.showIndexDetail
			this.showEvalutaionDetail = false;
		}
	}
}

</script>