<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<div class="card">
				<div class="card-body">
					<div class="content">
						<b-row class="text-center">
							<b-col class="text-center border" cols="2">
								<b-row>
									<b-col class="border pt-1" cols="4">
										<b-button-group vertical style="width:100%">
											<b-button size="sm" @click="contentToShow = 'players'" :variant="contentToShow == 'players' ? 'primary' : 'outline-primary'">Players</b-button>
											<b-button size="sm" @click="contentToShow = 'modules'" :variant="contentToShow == 'modules' ? 'primary' : 'outline-primary'" :disabled="loadingRosa || loadingOpponents || loadingTeam || loadingOpponent">Modules</b-button>
											<b-button size="sm" @click="contentToShow = 'drawing'" :variant="contentToShow == 'drawing' ? 'primary' : 'outline-primary'">Drawing</b-button>
											<b-button size="sm" @click="contentToShow = 'aspect'" :variant="contentToShow == 'aspect' ? 'primary' : 'outline-primary'">Aspect</b-button>
											<b-button size="sm" @click="contentToShow = 'file'" :variant="contentToShow == 'file' ? 'primary' : 'outline-primary'">File</b-button>
											<b-button size="sm" @click="contentToShow = 'pages'" :variant="contentToShow == 'pages' ? 'primary' : 'outline-primary'" :disabled="loadingRosa || loadingOpponents || loadingTeam || loadingOpponent">Pages <b-badge class="ml-2">{{ canvas_pages.length }}</b-badge></b-button>
										</b-button-group>

										<b-button-group vertical style="width:100%" class="my-3">
											<b-button size="sm" @click="replaceRosaSel" :variant="replaceRosa == 'sel' ? 'primary' : 'outline-secondary'" v-if="hasSelRosa" title="Replace player selected">Replace Sel <i class="fa fa-user" aria-hidden="true"></i></b-button>
											<b-button size="sm" @click="replaceRosaAll" :variant="replaceRosa == 'all' ? 'primary' : 'outline-secondary'" v-if="hasSelRosa" title="Replace all occurrences of the selected player">Replace All <i class="fa fa-users" aria-hidden="true"></i></b-button>
											<b-button size="sm" @click="replaceOpponentSel" :variant="replaceOpponent == 'sel' ? 'primary' : 'outline-secondary'" v-if="hasSelOpponent" title="Replace player selected">Replace Sel <i class="fa fa-user" aria-hidden="true"></i></b-button>
											<b-button size="sm" @click="replaceOpponentAll" :variant="replaceOpponent == 'all' ? 'primary' : 'outline-secondary'" v-if="hasSelOpponent" title="Replace all occurrences of the selected player">Replace All <i class="fa fa-users" aria-hidden="true"></i></b-button>	
											<b-button size="sm" @click="objUp" variant="outline-secondary" v-if="hasSelRect && canvas.rects.length > 1">Rect Up <i class="fa fa-level-up" aria-hidden="true"></i></b-button>
											<b-button size="sm" @click="objDown" variant="outline-secondary" v-if="hasSelRect && canvas.rects.length > 1">Rect Down <i class="fa fa-level-down" aria-hidden="true"></i></b-button>
											<b-button size="sm" @click="clearSel" variant="outline-secondary" v-if="nodesSelected.length > 0">Clear sel</b-button>
											<b-button size="sm" @click="clearSelDraw" variant="outline-secondary" v-if="hasSelDraw">Clear sel draw</b-button>
											<b-button size="sm" @click="clearAll" variant="outline-secondary" v-if="hasClearAll">Clear all</b-button>
											<b-button size="sm" @click="clearAllDraw" variant="outline-secondary" v-if="hasClearAllDraw">Clear all draw</b-button>
											<b-button size="sm" @click="revertEdits" variant="outline-secondary" v-if="hasRevert">Revert</b-button>
											<b-button-group style="width:100%">
												<b-button style="width:50%" size="sm" @click="undoStep" variant="outline-secondary">
													<i class="fa fa-arrow-left"></i>
												</b-button>
												<b-button style="width:50%" size="sm" @click="redoStep" variant="outline-secondary">
													<i class="fa fa-arrow-right"></i>
												</b-button>
											</b-button-group>
										</b-button-group>
										<p v-if="replaceRosa == 'sel'" class="small">Click on a player in AS Roma to replace the selected player</p>
										<p v-if="replaceRosa == 'all'" class="small">Click on a player in AS Roma to replace all occurrences of the selected player</p>	
										<p v-if="replaceOpponent == 'sel'" class="small">Click on a player in {{ opponent ? opponent.name : 'Opponent' }} to replace the selected player</p>
										<p v-if="replaceOpponent == 'all'" class="small">Click on a player in {{ opponent ? opponent.name : 'Opponent' }} to replace all occurrences of the selected player</p>		
										<div>
											<b-form-group label="Color" label-size="sm" class="mb-1" v-if="contentToShow == 'drawing' || hasEditDraw">
												<b-form-input size="sm" type="color" v-model="colorSelected" style="width: 100%;"></b-form-input>
											</b-form-group>
											<b-form-group label="Stroke Width" label-size="sm" class="mb-1" v-if="contentToShow == 'drawing' || hasEditDraw">
												<b-form-input size="sm" :title="'Stroke Width: ' + strokeWidthSelected" type="range" v-model="strokeWidthSelected" style="width: 100%;" min="1" max="10"></b-form-input>
											</b-form-group>
											<b-form-group label="Opacity" label-size="sm" v-if="contentToShow == 'drawing' || hasEditOpacity">
												<b-form-select size="sm" v-model="opacitySelected">
													<b-form-select-option v-for="val, key in hexTransparencies" :key="'hex_' + key" :value="val">{{ key }}%</b-form-select-option>
												</b-form-select>
											</b-form-group>
										</div>

										<div class="mb-3">
											<b-button size="sm" @click="downloadAssets" :variant="contentToShow == 'players' ? 'primary' : 'outline-primary'">
												<i class="fa fa-download" aria-hidden="true"></i>	Assets
											</b-button>
										</div>

										<b-button size="sm" :variant="locked ? 'secondary' : 'outline-secondary'" @click="switchLocked">
											<i class="fa fa-lock" aria-hidden="true" v-if="locked"></i>
											<i class="fa fa-unlock" aria-hidden="true" v-if="!locked"></i>	
										</b-button>
									</b-col>	
									<b-col class="pt-1" style="width:100%;height: 89vh; overflow-y: auto" cols="8">
										<b-tabs justified v-if="contentToShow == 'players'">
											<b-tab>
												<template #title>
													<div style="fontSize: 0.85rem">AS Roma</div>
												</template>
												<b-overlay :show="loadingRosa || loadingTeam" rounded="sm">
													<b-button-group vertical style="width:100%">
														<b-button size="sm" @click="setActiveObject('player_rosa')" @dblclick="addPlayer('rosa')" @dbltap="addPlayer('rosa')" variant="primary" :style="'background-color:' + (activeObject == 'player_rosa' ? '#a80532' : '#a8053280')">Generic Player</b-button>
														<b-button v-for="pl in rosa_opts" :key="'rosa_' + pl.id" class="text-truncate" size="sm" @click="selectPlayer(pl.id, true)" @dblclick="addPlayerRosa(pl.id, false)" @dbltap="addPlayerRosa(pl.id, false)" :variant="player_sel == pl.id ? 'primary' : 'outline-primary'">
															{{ pl.name }}
														</b-button>
													</b-button-group>
												</b-overlay>
											</b-tab>
											<b-tab>
												<template #title>
													<div style="fontSize: 0.85rem">{{ opponent ? opponent.name : 'Opponent' }} <i class="fa fa-search" style="cursor:pointer" aria-hidden="true" @click="selectTeam"></i></div>
												</template>
												<b-overlay :show="loadingOpponents || loadingOpponent" rounded="sm">
													<b-button-group vertical style="width:100%">
														<b-button size="sm" @click="setActiveObject('player_opponent')" @dblclick="addPlayer('opponent')" @dbltap="addPlayer('opponent')" variant="secondary" :style="'background-color:' + (activeObject == 'player_opponent' ? '#6c757d' : '#6c757d80')">Generic Player</b-button>
														<b-button v-for="pl in opponents_opts" :key="'opp_' + pl.id" class="text-truncate" size="sm" @click="selectPlayer(pl.id, false)"  @dblclick="addPlayerOpponent(pl.id, false)" @dbltap="addPlayerOpponent(pl.id, false)" :variant="player_sel == pl.id ? 'secondary' : 'outline-secondary'">
															{{ pl.name }}
														</b-button>
													</b-button-group>
												</b-overlay>
											</b-tab>
										</b-tabs>
										<b-tabs justified v-if="contentToShow == 'modules'">
											<b-tab>
												<template #title>
													<div style="fontSize: 0.85rem">AS Roma</div>
												</template>
												<b-form-radio-group v-model="field" size="sm">
													<b-form-radio value="half">Half field</b-form-radio>
													<b-form-radio value="all">All field</b-form-radio>
												</b-form-radio-group>
												<b-form-radio-group v-model="direction" size="sm">
													<b-form-radio value="default">Left to right</b-form-radio>
													<b-form-radio value="reverse">Right to left</b-form-radio>
												</b-form-radio-group>
												<b-overlay :show="loadingModuliTeam" rounded="sm">
													<div class="accordion" role="tablist">
														<b-card no-body class="mb-0" v-for="mod in teamModuliSorted" :key="'tm_mod_' + mod">
															<b-card-header header-tag="header" class="p-0" role="tab">
																<b-button size="sm" block v-b-toggle="'team-' + mod" variant="outline-primary">{{ mod }} <b-badge class="ml-2">{{ teamModuliGrouped[mod].length }}</b-badge></b-button>
															</b-card-header>
															<b-collapse :id="'team-' + mod" accordion="my-team" role="tabpanel">
																<b-button-group vertical style="width:100%">
																	<b-button v-for="form in teamModuliGrouped[mod]" :key="'rosa_form_' + form.id" class="text-truncate" size="sm" variant="outline-primary" @click="selectTeamFormation(form.id)">
																		{{ form.label }} <br/> {{ form.partita.data | dateTimeFormat }}
																	</b-button>
																</b-button-group>
															</b-collapse>
														</b-card>
													</div>
												</b-overlay>
											</b-tab>
											<b-tab>
												<template #title>
													<div style="fontSize: 0.85rem">{{ opponent ? opponent.name : 'Opponent' }} <i class="fa fa-search" style="cursor:pointer" aria-hidden="true" @click="selectTeam"></i></div>
												</template>
												<b-form-radio-group v-model="field" size="sm">
													<b-form-radio value="half">Half field</b-form-radio>
													<b-form-radio value="all">All field</b-form-radio>
												</b-form-radio-group>
												<b-form-radio-group v-model="direction" size="sm">
													<b-form-radio value="default">Right to left</b-form-radio>
													<b-form-radio value="reverse">Left to right</b-form-radio>
												</b-form-radio-group>
												<b-overlay :show="loadingModuliOpponent" rounded="sm">
													<div class="accordion" role="tablist">
														<b-card no-body class="mb-0" v-for="mod in opponentModuliSorted" :key="'opp_mod_' + mod">
															<b-card-header header-tag="header" class="p-0" role="tab">
																<b-button size="sm" block v-b-toggle="'opp-' + mod" variant="outline-secondary">{{ mod }} <b-badge class="ml-2">{{ opponentModuliGrouped[mod].length }}</b-badge></b-button>
															</b-card-header>
															<b-collapse :id="'opp-' + mod" accordion="my-opponent" role="tabpanel">
																<b-button-group vertical style="width:100%">
																	<b-button v-for="form in opponentModuliGrouped[mod]" :key="'opp_form_' + form.id" class="text-truncate" size="sm" variant="outline-secondary" @click="selectOpponentFormation(form.id)">
																		{{ form.label }} <br/> {{ form.partita.data | dateTimeFormat }}
																	</b-button>
																</b-button-group>
															</b-collapse>
														</b-card>
													</div>
												</b-overlay>
											</b-tab>
										</b-tabs>
										<b-button-group vertical style="width:100%" v-if="contentToShow == 'drawing'">
											<b-button size="sm" @click="setActiveObject('line')" @dblclick="setLockedObject('line')" @dbltap="setLockedObject('line')" :variant="activeObject == 'line' ? 'primary' : 'outline-primary'">Line</b-button>
											<b-button size="sm" @click="setActiveObject('arrow')" @dblclick="setLockedObject('arrow')" @dbltap="setLockedObject('arrow')" :variant="activeObject == 'arrow' ? 'primary' : 'outline-primary'">Arrow</b-button>
											<b-button size="sm" @click="setActiveObject('rect')" @dblclick="setLockedObject('rect')" @dbltap="setLockedObject('rect')" :variant="activeObject == 'rect' ? 'primary' : 'outline-primary'">Rect</b-button>
											<b-button size="sm" @click="setActiveObject('obj_ball')" @dblclick="setLockedObject('obj_ball')" @dbltap="setLockedObject('obj_ball')" :variant="activeObject == 'obj_ball' ? 'primary' : 'outline-primary'">
												Ball
												<img :src="ball.src" width="15" />
											</b-button>
										</b-button-group>
										<div style="width:100%" class="card text-center border-primary bg-transparent" v-if="contentToShow == 'aspect'">
											<b-row><b-col><b-form-checkbox size="sm" v-model="config.showAltezza" :style="config.enfasiAltezza ? 'font-weight:bold' : ''">Height ({{ Math.round(config.zoomAltezza * 100) }}%)</b-form-checkbox><b-row align-v="center"><b-col><b-form-checkbox size="sm" v-model="config.enfasiAltezza">Bold</b-form-checkbox></b-col><b-col><b-button-group><b-button size="xs" @click="zoomAltezza('-')" :disabled="config.zoomAltezza <= 1">-</b-button><b-button size="xs" @click="zoomAltezza('+')">+</b-button></b-button-group></b-col></b-row></b-col><b-col><b-form-checkbox size="sm" v-model="config.showPiede" :style="config.enfasiPiede ? 'font-weight:bold' : ''">Foot ({{ Math.round(config.zoomPiede * 100) }}%)</b-form-checkbox><b-row align-v="center"><b-col><b-form-checkbox size="sm" v-model="config.enfasiPiede">Bold</b-form-checkbox></b-col><b-col><b-button-group><b-button size="xs" @click="zoomPiede('-')" :disabled="config.zoomPiede <= 1">-</b-button><b-button size="xs" @click="zoomPiede('+')">+</b-button></b-button-group></b-col></b-row></b-col></b-row>
												<b-row><b-col><img :src="config.showDivisa ? player.src : avatarPlaceholder" :height="player.height + (player.height * 25/100)" v-if="config.showDivisa != 2"/><div v-else :style="'margin: 0 auto;border-radius: 50%;background:#CCCCCC;height:' + (player.height + (player.height * 25/100)) + 'px;width:' + (player.height + (player.height * 25/100)) + 'px;'"></div><div><b-form-checkbox size="sm" v-model="config.showImage">Image</b-form-checkbox></div><div :style="(config.enfasiLabel ? 'font-weight:bold;fontSize:0.9rem;' : 'fontSize:0.9rem;') + ('color:' + config.colorLabel + ';background:' + config.bgLabel)">Player ({{ Math.round(config.zoomLabel * 100) }}%)</div></b-col></b-row>
												<b-row align-v="center"><b-col class="text-right"><b-form-checkbox size="sm" v-model="config.enfasiLabel">Bold</b-form-checkbox></b-col><b-col class="text-left"><b-button-group><b-button size="xs" @click="zoomLabel('-')" :disabled="config.zoomLabel <= 1">-</b-button><b-button size="xs" @click="zoomLabel('+')">+</b-button></b-button-group></b-col>
													</b-row>
													<b-row align-v="center" class="p-1">
													<b-col><b-form-group label="Color" label-size="sm">
														<b-form-input size="sm" type="color" v-model="config.colorLabel" style="width: 100%;"></b-form-input>
													</b-form-group></b-col><b-col><b-form-group label="Bg" label-size="sm">
														<b-form-input size="sm" type="color" v-model="config.bgLabel" style="width: 100%;"></b-form-input>
													</b-form-group></b-col><b-col><b-form-group label="Opacity" label-size="sm">
														<b-form-select size="sm" v-model="config.opacityLabel">
															<b-form-select-option v-for="val, key in hexTransparencies" :key="'hex2_' + key" :value="val">{{ key }}%</b-form-select-option>
														</b-form-select>
													</b-form-group></b-col></b-row>
													<b-row class="my-1" align-v="center"><b-col><b-form-checkbox size="sm" v-model="config.showNumero">Number</b-form-checkbox></b-col><b-col class="pr-2"><b-form-select v-model="config.showDivisa" size="sm"><b-form-select-option :value="0">Avatar</b-form-select-option><b-form-select-option :value="1">Shirt</b-form-select-option><b-form-select-option :value="2">Circle</b-form-select-option></b-form-select></b-col></b-row>
													<b-row v-if="config.showDivisa == 2"><b-col><b-form-group label="AS Roma" label-size="sm">
														<b-form-input size="sm" type="color" v-model="config.colorTeam" style="width: 100%;"></b-form-input>
													</b-form-group></b-col><b-col><b-form-group label="Opponent" label-size="sm">
														<b-form-input size="sm" type="color" v-model="config.colorOpponent" style="width: 100%;"></b-form-input>
													</b-form-group></b-col></b-row>
													<b-row class="my-1"><b-col class="p-2"><b-form-checkbox size="sm" v-model="config.alwaysShowNumero">Always the number on the Label</b-form-checkbox></b-col></b-row>
													<b-row class="my-1"><b-col class="p-2"><b-form-checkbox size="sm" v-model="config.playerFromTeams">Sets the team shirt and color to the generic player</b-form-checkbox></b-col></b-row>
													<b-row class="mt-2">
														<b-col class="p-2">
															<b-form-group label="Select field" label-size="sm">
																<b-form-select v-model="config.background" size="sm">
																	<b-form-select-option value="orizzontale">Horizontal field</b-form-select-option>
																	<b-form-select-option value="prospettiva">Field in perspective</b-form-select-option>
																	<b-form-select-option value="none">No field</b-form-select-option>
																</b-form-select>
															</b-form-group>
														</b-col>
													</b-row>
												</div>

												<div class="accordion" role="tablist" v-if="contentToShow == 'file'">
													<b-card no-body class="mb-0">
														<b-card-header header-tag="header" class="p-0">
															<b-button size="sm" variant="outline-primary" style="width:100%" @click="newBoard">New</b-button>
														</b-card-header>
													</b-card>
													<b-card no-body class="mb-0">
														<b-card-header header-tag="header" class="p-0" role="tab">
															<b-button size="sm" block v-b-toggle.save variant="outline-primary">Store</b-button>
														</b-card-header>
														<b-collapse id="save" accordion="my-file" role="tabpanel" class="p-1">
															<b-form-group label="Name" label-size="sm">
																<b-form-input size="sm" type="text" v-model="currentTacticalBoard.nome"></b-form-input>
															</b-form-group>
															<b-button-group style="width:100%">
																<b-button size="sm" variant="outline-secondary" v-if="!currentTacticalBoard.id" @click="save">Save</b-button>
																<b-button size="sm" variant="outline-secondary" v-if="currentTacticalBoard.id" @click="save">Update</b-button>
																<b-button size="sm" variant="outline-secondary" v-if="currentTacticalBoard.id" @click="saveAsNew">Save as new</b-button>
															</b-button-group>
														</b-collapse>
													</b-card>
													<b-card no-body class="mb-0">
														<b-card-header header-tag="header" class="p-0" role="tab">
															<b-button size="sm" block v-b-toggle.load variant="outline-primary" :disabled="loadingRosa || loadingTeam">Load <b-badge class="ml-2">{{ tacticalBoardFiles.length }}</b-badge></b-button>
														</b-card-header>
														<b-collapse id="load" accordion="my-file" role="tabpanel">

															<b-list-group v-if="tacticalBoardFiles.length">
																<b-list-group-item href="#" v-for="tb in tacticalBoardFiles" :key="'tbf_' + tb.id" class="p-1">
																	<b-row @click="selectTB(tb.id)"><b-col cols="8" style="cursor:pointer" class="text-left">{{ tb.nome }} </b-col><b-col cols="4">{{ tb.updated_at | dateFormat}}</b-col></b-row>
																	<b-row v-if="tbSelected == tb.id"><b-col><b-button-group style="width:100%" class="mt-2">
																		<b-button size="sm" variant="outline-secondary" @click="load(tb.id)">Load</b-button>
																		<b-button size="sm" variant="outline-secondary" @click="deleteTb(tb.id)">Delete</b-button>
																	</b-button-group></b-col></b-row>
																</b-list-group-item>
															</b-list-group>
															<div v-else class="small p-1">No tactical boards saved for the selected category!</div>
														</b-collapse>
													</b-card>
													<b-card no-body class="mb-0">
														<b-card-header header-tag="header" class="p-0" role="tab">
															<b-button size="sm" block v-b-toggle.download variant="outline-primary">Download</b-button>
														</b-card-header>
														<b-collapse id="download" accordion="my-file" role="tabpanel" class="p-1">
															<b-button-group style="width:100%" class="mt-1">
																<b-button size="sm" variant="outline-secondary" @click="downloadAllPage" v-if="canvas_pages.length > 1">All Page</b-button>
																<b-button size="sm" variant="outline-secondary" @click="download">Current Page</b-button>
															</b-button-group>
														</b-collapse>
													</b-card>
												</div>
												<div v-if="contentToShow == 'pages'">
													<b-button-group style="width:100%" vertical>
														<b-button size="sm" variant="outline-secondary" @click="addEmptyPage">Add empty page</b-button>
														<b-button size="sm" variant="outline-secondary" @click="copyCurrentPage">Clone current page</b-button>
														<b-button size="sm" variant="outline-secondary" @click="removeCurrentPage" v-if="canvas_pages.length > 1">Remove current page</b-button>
														<b-button size="sm" variant="outline-secondary" @click="switchPageSelection" v-if="canvas_pages.length > 1">{{ hasPageSelection ? 'Disabled page selection' : 'Enable page selection' }}</b-button>
														<b-button size="sm" variant="outline-secondary" @click="removeSelectedPages" v-if="pages_selected.length > 0">Remove selected pages</b-button>
													</b-button-group>

													<b-row class="mt-2">
														<b-col cols="12" class="text-center mb-1" v-for="thumb, thumb_index in thumbnails" :key="'thumb_' + thumb_index">
															<b-card no-body class="p-2" :style="'cursor:pointer;' + (currentPage == thumb_index + 1 ? 'background-color: #fffacd' : '')" @click="currentPage = thumb_index + 1">
																<b-img fluid :src="thumb"></b-img>
																<div><b-form-checkbox :value="thumb_index + 1" v-model="pages_selected" v-if="hasPageSelection"><b-badge :variant="currentPage == thumb_index + 1 ? 'primary' : ''">{{ thumb_index + 1 }}</b-badge></b-form-checkbox><b-badge v-else :variant="currentPage == thumb_index + 1 ? 'primary' : ''">{{ thumb_index + 1 }}</b-badge></div>		
															</b-card>
														</b-col>
													</b-row>
												</div>
											</b-col>
										</b-row>
									</b-col>
									<b-col cols="10" class="text-center border" :key="randomKey">

										<b-overlay :show="loadingRosa || loadingOpponents" rounded="sm">

											<div ref="container" style="height: 89vh; width: 100%;">
												<v-stage ref="stage" :key="'stg_' + canvas_index" :config="configStage" v-for="canvas, canvas_index in canvas_pages" v-show="canvas_index == currentPage - 1" v-if="(canvas_index == currentPage - 1) || loadingThumbs"
												@mousedown="handleMouseDown"
												@mousemove="handleMouseMove" 
												@mouseup="handleMouseUp"
												@touchstart="handleMouseDown" 
												@touchmove="handleMouseMove" 
												@touchend="handleMouseUp"
												@click="handleClick"
												@tap="handleClick">
												<v-layer ref="layer" :config="{ listening: locked ? false : true }">
													<v-image :config="{image: background, x: bgXOffset, name: 'img_bg', listening: false}" v-if="canvas.config && canvas.config.background == 'orizzontale'"></v-image>
													<v-image :config="{image: bgProspettiva, x: bgXOffsetProspettiva, name: 'img_bg', listening: false}" v-if="canvas.config && canvas.config.background == 'prospettiva'"></v-image>
													<v-rect v-for="(rect, index) in canvas.rects"
													:key="rect.name"
													:config="{
														x: rect.x ? rect.x : Math.min(rect.startPointX, rect.startPointX + rect.width),
														y: rect.y ? rect.y : Math.min(rect.startPointY, rect.startPointY + rect.height),
														width: Math.abs(rect.width),
														height: Math.abs(rect.height),
														fill: rect.color + '' + rect.opacity,
														stroke: rect.color,
														strokeWidth: rect.strokeWidth,
														draggable: isDraggable,
														name: rect.name,
														rotation: rect.rotation,
														scaleX: rect.scaleX,
														scaleY: rect.scaleY,
														strokeScaleEnabled: false,
													}"
													@transformend="handleTransformEnd"
													@dragend="handleDragEnd"
													/>
													<v-line
													v-for="line in canvas.lines"
													:key="line.name"
													:config="{
														x: line.x,
														y: line.y,
														stroke: line.color,
														strokeWidth: line.strokeWidth,
														points: line.points,
														draggable: isDraggable,
														name: line.name,
														rotation: line.rotation,
														scaleX: line.scaleX,
														scaleY: line.scaleY,
														strokeScaleEnabled: false,
													}"
													@transformend="handleTransformEnd"
													@dragend="handleDragEnd"
													/>
													<v-arrow
													v-for="arrow in canvas.arrows"
													:key="arrow.name"
													:config="{
														x: arrow.x,
														y: arrow.y,
														stroke: arrow.color,
														fill: arrow.color,
														strokeWidth: arrow.strokeWidth,
														points: arrow.points,
														draggable: isDraggable,
														name: arrow.name,
														rotation: arrow.rotation,
														scaleX: arrow.scaleX,
														scaleY: arrow.scaleY,
														strokeScaleEnabled: false,
													}"
													@transformend="handleTransformEnd"
													@dragend="handleDragEnd"
													/>
													<v-group v-for="pl, index in canvas.players" :key="pl.name" :config="{ draggable: isDraggable, x: pl.x, y: pl.y, name: pl.name, rotation: pl.rotation, scaleX: pl.scaleX, scaleY: pl.scaleY}"  @transformend="handleTransformEnd" @dragend="handleDragEnd" @dblclick="editText" @dbltap="editText">
														<v-image :config="{image: pl.avatar, x: pl.avatar.width * 50/100}" v-if="!config.showDivisa && pl.showImage"></v-image>
														<v-image :config="{image: config.playerFromTeams ? pl.shirt : pl.image, x: pl.image.width * 100/100}" v-if="config.showDivisa == 1 && pl.showImage"></v-image>
														<v-circle :config="{radius: circle, x: pl.image.height/0.88, y: pl.image.height/2, fill: config.playerFromTeams ? (pl.from == 'rosa' ? config.colorTeam : config.colorOpponent) : '#CCCCCC' }" v-if="config.showDivisa == 2 && pl.showImage"></v-circle>
														<v-label :config="{y: config.showDivisa == 2 ? (3.5 * circle) + 2 : pl.image.height + 2}">
															<v-tag :config="{fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: !config.showDivisa ? pl.avatar.width + pl.avatar.width * 100/100 : pl.image.width + pl.image.width * 200/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3}"/>
														</v-label>
													</v-group>
													<v-group v-for="pl, index in canvas.rosa" :key="pl.name" :config="{ draggable: isDraggable, x: pl.x, y: pl.y, id: pl.id + '', name: pl.name, rotation: pl.rotation, scaleX: pl.scaleX, scaleY: pl.scaleY }" @transformend="handleTransformEnd" @dragend="handleDragEnd" @dblclick="editText" @dbltap="editText">
														<v-image :config="{image: pl.avatar, x: pl.avatar.width * 50/100  }" v-if="!config.showDivisa && pl.showImage"></v-image>
														<v-image :config="{image: pl.shirt, x: pl.shirt.width * 100/100 }" v-if="config.showDivisa == 1 && pl.showImage"></v-image>
														<v-circle :config="{radius: circle, x: pl.shirt.height/0.88, y: pl.shirt.height/2, fill: config.colorTeam }" v-if="config.showDivisa == 2 && pl.showImage"></v-circle>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'white', stroke: 'white', strokeWidth: 5, text: pl.number, y: pl.shirt.height/2.5, fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'black', text: pl.number, y: (pl.shirt.height/2.5), fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-label v-if="!config.showDivisa" :config="{y: pl.avatar.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (config.showNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.avatar.width + pl.avatar.width * 100/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3 }"/>
														</v-label>
														<v-label v-if="config.showDivisa" :config="{y: config.showDivisa == 2 ? (3.5 * circle) + 2 : pl.shirt.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (pl.alwaysShowNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3}"/>
														</v-label>
														<v-text v-if="pl.showAltezza" :config="{ text: pl.altezza, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) - (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 12.5/100, fontSize: pl.fontSize * (pl.zoomAltezza ? pl.zoomAltezza : 1), fontStyle: pl.enfasiAltezza ? 'bold' : 'normal' }"/>
														<v-text v-if="pl.showPiede" :config="{ text: pl.foot, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) + (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 90/100, fontSize: pl.fontSize * (pl.zoomPiede ? pl.zoomPiede : 1), fontStyle: pl.enfasiPiede ? 'bold' : 'normal' }"/>
													</v-group>
													<v-group v-for="pl, index in canvas.opponents" :key="pl.name" :config="{ draggable: isDraggable, x: pl.x, y: pl.y, id: pl.id + '', name: pl.name, rotation: pl.rotation, scaleX: pl.scaleX, scaleY: pl.scaleY }" @transformend="handleTransformEnd" @dragend="handleDragEnd" @dblclick="editText" @dbltap="editText">
														<v-image :config="{image: pl.avatar, x: pl.avatar.width * 50/100  }" v-if="!config.showDivisa && pl.showImage"></v-image>
														<v-image :config="{image: pl.shirt, x: pl.shirt.width * 100/100 }" v-if="config.showDivisa == 1 && pl.showImage"></v-image>
														<v-circle :config="{radius: circle, x: pl.shirt.height/0.88, y: pl.shirt.height/2, fill: config.colorOpponent }" v-if="config.showDivisa == 2 && pl.showImage"></v-circle>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'white', stroke: 'white', strokeWidth: 5, text: pl.number, y: pl.shirt.height/2.5, fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'black', text: pl.number, y: (pl.shirt.height/2.5), fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-label v-if="!config.showDivisa" :config="{y: pl.avatar.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (config.showNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.avatar.width + pl.avatar.width * 100/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3 }"/>
														</v-label>
														<v-label v-if="config.showDivisa" :config="{y: config.showDivisa == 2 ? (3.5 * circle) + 2 : pl.shirt.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (pl.alwaysShowNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3}"/>
														</v-label>
														<v-text v-if="pl.showAltezza" :config="{ text: pl.altezza, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) - (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 12.5/100, fontSize: pl.fontSize * (pl.zoomAltezza ? pl.zoomAltezza : 1), fontStyle: pl.enfasiAltezza ? 'bold' : 'normal' }"/>
														<v-text v-if="pl.showPiede" :config="{ text: pl.foot, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) + (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 90/100, fontSize: pl.fontSize * (pl.zoomPiede ? pl.zoomPiede : 1), fontStyle: pl.enfasiPiede ? 'bold' : 'normal' }"/>
													</v-group>	
													<v-image v-for="obj, index in canvas.objects" :key="obj.name" :config="{image: obj.image, draggable: isDraggable, x: obj.x, y: obj.y, name: obj.name, rotation: obj.rotation, scaleX: obj.scaleX, scaleY: obj.scaleY }"  @transformend="handleTransformEnd" @dragend="handleDragEnd"></v-image>
													<v-rect :key="'selRect_' + canvas_index" ref="selRect" :config="{fill: 'rgba(0,0,255,0.5)', visible: false, listening: false}"/>
													<v-transformer :key="'transf_' + canvas_index" ref="transformer" :config="{ enabledAnchors:[ 'top-left', 'top-right', 'bottom-left', 'bottom-right'], keepRatio: hasSelRect ? false : true }"/>
												</v-layer>
											</v-stage>
										</div>
									</b-overlay>

									<div style="display: none;"> 

										<!-- Assets Rosa -->
										<template v-for="pl, index in canvas.rosa">
											<v-stage :ref="`extRosaStage${index}`"
												:config="configSinglePlayerStage"
											>
												<v-layer :config="{ listening: locked ? false : true }">
													<v-group :ref="`extRosaPlayer${index}`" :config="{ draggable: isDraggable, x: 150, y: 150, id: pl.id + '-single', name: pl.name, rotation: 0, scaleX: 2, scaleY: 2 }">
														<v-image :config="{image: pl.avatar, x: pl.avatar.width * 50/100  }" v-if="!config.showDivisa && pl.showImage"></v-image>
														<v-image :config="{image: pl.shirt, x: pl.shirt.width * 100/100 }" v-if="config.showDivisa == 1 && pl.showImage"></v-image>
														<v-circle :config="{radius: circle, x: pl.shirt.height/0.88, y: pl.shirt.height/2, fill: config.colorTeam }" v-if="config.showDivisa == 2 && pl.showImage"></v-circle>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'white', stroke: 'white', strokeWidth: 5, text: pl.number, y: pl.shirt.height/2.5, fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'black', text: pl.number, y: (pl.shirt.height/2.5), fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-label v-if="!config.showDivisa" :config="{y: pl.avatar.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (config.showNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.avatar.width + pl.avatar.width * 100/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3 }"/>
														</v-label>
														<v-label v-if="config.showDivisa" :config="{y: config.showDivisa == 2 ? (3.5 * circle) + 2 : pl.shirt.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (pl.alwaysShowNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3}"/>
														</v-label>
														<v-text v-if="pl.showAltezza" :config="{ text: pl.altezza, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) - (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 12.5/100, fontSize: pl.fontSize * (pl.zoomAltezza ? pl.zoomAltezza : 1), fontStyle: pl.enfasiAltezza ? 'bold' : 'normal' }"/>
														<v-text v-if="pl.showPiede" :config="{ text: pl.foot, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) + (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 90/100, fontSize: pl.fontSize * (pl.zoomPiede ? pl.zoomPiede : 1), fontStyle: pl.enfasiPiede ? 'bold' : 'normal' }"/>
													</v-group>
												</v-layer>
											</v-stage>
										</template>

										<!-- Assets Opponents -->
										<template v-for="pl, index in canvas.opponents">
											<v-stage :ref="`extOppoStage${index}`"
												:config="configSinglePlayerStage"
											>
												<v-layer :config="{ listening: locked ? false : true }">
													<v-group :ref="`extOppoPlayer${index}`" :config="{ draggable: isDraggable, x: 150, y: 150, id: pl.id + '-single', name: pl.name, rotation: 0, scaleX: 2, scaleY: 2 }">
														<v-image :config="{image: pl.avatar, x: pl.avatar.width * 50/100  }" v-if="!config.showDivisa && pl.showImage"></v-image>
														<v-image :config="{image: pl.shirt, x: pl.shirt.width * 100/100 }" v-if="config.showDivisa == 1 && pl.showImage"></v-image>
														<v-circle :config="{radius: circle, x: pl.shirt.height/0.88, y: pl.shirt.height/2, fill: config.colorOpponent }" v-if="config.showDivisa == 2 && pl.showImage"></v-circle>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'white', stroke: 'white', strokeWidth: 5, text: pl.number, y: pl.shirt.height/2.5, fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-text v-if="config.showDivisa && config.showNumero && pl.showImage" :config="{ fill: 'black', text: pl.number, y: (pl.shirt.height/2.5), fontSize: pl.fontSize * 1.6, width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center' }"/>
														<v-label v-if="!config.showDivisa" :config="{y: pl.avatar.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (config.showNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.avatar.width + pl.avatar.width * 100/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3 }"/>
														</v-label>
														<v-label v-if="config.showDivisa" :config="{y: config.showDivisa == 2 ? (3.5 * circle) + 2 : pl.shirt.height + 2}">
															<v-tag :config="{ fill: pl.bgLabel + '' + pl.opacityLabel }"></v-tag>	
															<v-text :config="{ text: (pl.alwaysShowNumero ? pl.number + ' - ' : '') + pl.label, fontSize: pl.fontSize * (pl.zoomLabel ? pl.zoomLabel : 1), width: pl.shirt.width + pl.shirt.width * 200/100, align: 'center', fontStyle: pl.enfasiLabel ? 'bold' : 'normal', fill: pl.colorLabel , padding: 3}"/>
														</v-label>
														<v-text v-if="pl.showAltezza" :config="{ text: pl.altezza, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) - (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 12.5/100, fontSize: pl.fontSize * (pl.zoomAltezza ? pl.zoomAltezza : 1), fontStyle: pl.enfasiAltezza ? 'bold' : 'normal' }"/>
														<v-text v-if="pl.showPiede" :config="{ text: pl.foot, y: config.showDivisa == 2 ? 10 : -10, x: (config.showDivisa ? pl.shirt.width : pl.avatar.width/2) + (config.showDivisa ? pl.shirt.width : pl.avatar.width) * 90/100, fontSize: pl.fontSize * (pl.zoomPiede ? pl.zoomPiede : 1), fontStyle: pl.enfasiPiede ? 'bold' : 'normal' }"/>
													</v-group>
												</v-layer>
											</v-stage>
										</template>

									</div>
								</b-col>	
							</b-row>
						</div>
						<b-modal id="selectTeamModal" ref="selectTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
							<div v-if="teams.data.length == 0"><h5>Nessuna squadra trovata!</h5></div>
							<div>
								<div style="height: 500px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
									<b-row class="mb-1">
										<b-col sm="6">
											<b-form-select v-model="filters.categoria">
												<option value="0">-- Categoria --</option>
												<option v-for="item in categories_opt" :value="item.id" :key="'cat_' + item.id">{{item.name}}</option>
											</b-form-select>
										</b-col>
										<b-col sm="6">
											<b-input-group>
												<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>  	
										</b-col>
									</b-row>	
									<b-row>
										<b-col cols="4" v-for="team in teams.data" v-bind:key="'team_' + team.id" style="padding: 0.2rem">
											<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="setTeam(team)">
												<img :src="team.logo_url" width="50" /><br/>
												<strong>{{team.officialName}}</strong><br/>
												<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
											</b-card>
										</b-col>
									</b-row>	
									<b-row>
										<b-col cols="6" style="padding: 0.2rem">
											<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
										</b-col>	

										<b-col cols="6" style="padding: 0.2rem">
											<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
										</b-col>	
									</b-row>				
								</div>
							</div>
						</b-modal>
					</div>
				</div>
			</div>

			<footerapp></footerapp>
		</section>
	</template>

	<script>
	import moment from 'moment';
	import { mapState } from 'vuex';
	import * as types from '@/store/types';
	var Auth = require('@/auth.js');
	import { jsPDF } from 'jspdf';
	import JSZip from 'jszip';
	import { saveAs } from 'file-saver'; 

	export default {

		components: {
			headapp: require('@/components/layout/headapp.vue').default,
			footerapp: require('@/components/layout/footerapp.vue').default,
			flag: require('@/components/layout/flag.vue').default,
		},

		data: function() {
			return {

				randomKey: 'kk_' + this.getRandom(),
				currentPageSteps: {},
				canvasShouldWatch: true,
				avatarPlaceholder: '',
				isOnline: navigator.onLine,

				currentPageRevert: null,
				contentToShow: null,
				locked: false,

				categories_opt: [],
				teams: {
					data: []
				},
				filters: {
					paese: 0,
					osservati: 0,
					videotags: 0,
					search_name: '',
					search_coach: '',
					reports: 0,
					missioni: 0,
					categoria: 0,
				},
				page: 1,

				season_id: null,
				team: null,
				opponent: null,
				team_moduli: [],
				opponent_moduli: [],
				rosa_opts: [],
				opponents_opts: [],  
				loadingRosa: false,
				loadingOpponents: false,
				loadingTeam: false,
				loadingModuliTeam: false,
				loadingOpponent: false,
				loadingModuliOpponent: false,

				background: null,
				bgXOffset: null,
				fontSize: 12,
				
				bgProspettiva: null,
				bgXOffsetProspettiva: null,

				player: null,
				ball: null,
				avatar: null,
				circle: 20,

				positions_perc: {
					gk: {x: 10, y: 50},
					rb: {x: 34, y: 14},
					rcb: {x: 30, y: 38},
					lcb: {x: 30, y: 62},
					lb: {x: 34, y: 86},
					rw: {x: 66, y: 14},
					rcmf: {x: 56, y: 38},
					lcmf: {x: 56, y: 62},
					lw: {x: 66, y: 86},
					ss: {x: 75, y: 50},
					cf: {x: 93, y: 50},
					amf: {x: 66, y: 50},
					rcmf3: {x: 58, y: 38},
					dmf: {x: 50, y: 50},
					lcmf3: {x: 58, y: 62},
					rdmf: {x: 50, y: 38},
					ldmf: {x: 50, y: 62},
					ramf: {x: 66, y: 20},
					lamf: {x: 66, y: 80},
					rwf: {x: 82, y: 26},
					lwf: {x: 82, y: 74},
					rcb3: {x: 34, y: 30},
					cb: {x: 30, y: 50},
					lcb3: {x: 34, y: 70},
					rwb: {x: 54, y: 14},
					lwb: {x: 54, y: 86},
					rb5: {x: 38, y: 20},
					l5b: {x: 38, y: 80},
				/*	PO: {x: 10, y: 50},
					TD: {x: 34, y: 14},
					DCD: {x: 30, y: 38},
					DCS: {x: 30, y: 62},
					TS: {x: 34, y: 86},
					ED: {x: 66, y: 14},
					CID: {x: 56, y: 38},
					CIS: {x: 56, y: 62},
					ES: {x: 66, y: 86},
					SP: {x: 75, y: 50},
					AC: {x: 75, y: 50},
					PC: {x: 93, y: 50},
					TQC: {x: 66, y: 50},
					CC: {x: 50, y: 50},
					AD: {x: 82, y: 26},
					AS: {x: 82, y: 74},
					DC: {x: 30, y: 50},  */
				},

				positions_default: {
					1: {x: 10, y: 50},
					2: {x: 34, y: 86},
					3: {x: 30, y: 62},
					4: {x: 30, y: 38},
					5: {x: 34, y: 14},
					6: {x: 56, y: 62},
					7: {x: 50, y: 50},
					8: {x: 56, y: 38},
					9: {x: 82, y: 74},
					10: {x: 93, y: 50},
					11: {x: 82, y: 26}	
				},

				field: 'half',
				direction: 'default',

				configStage: {
					width: 0,
					height: 0,
				},

				configSinglePlayerStage: {
					width: 1000,
					height: 1000,
				},

				config: {
					bg_width: 0,
					bg_height: 0,
					bg_offsetX: 0,
					showDivisa: 0,
					showPiede: true,
					showAltezza: true,
					showNumero: true,
					alwaysShowNumero: false,
					enfasiPiede: true,
					enfasiAltezza: true,
					enfasiLabel: true,
					zoomPiede: 1.3,
					zoomAltezza: 1.3,
					zoomLabel: 1.5,
					colorTeam: '#FF0000',
					colorOpponent: '#0000FF',
					colorLabel: '#FFFFFF',
					bgLabel: '#000000',
					opacityLabel: 'FF',
					playerFromTeams: false,
					showImage: true,
					background: 'orizzontale'
				},

				canvas_pages: [{
					config: {
						background: 'orizzontale'
					},
					rosa: [],
					opponents: [],  
					players: [],
					lines: [],
					arrows: [],
					rects: [],
					objects: [],
				}],

				currentPage: 1,
				hasPageSelection: false,
				pages_selected: [],

				isDrawing: false,

				activeObject: null,
				isRosa: true,
				player_sel: null,
				colorSelected: '#000000',
				strokeWidthSelected: 1,
				opacitySelected: '33',
				selecting: false,
				points: {x1: null, y1: null, x2: null, y2: null},
				piede: ['DX', 'SX', 'AD'],

				nodesSelected: [],

				lockedObjects: [],

				tacticalBoardFiles: [],
				tbSelected: null,
				currentTacticalBoard: {
					id: 0,
					nome: null,
				},
				textEditAreas: [],
				replaceRosa: null,
				replaceOpponent: null,
				storedThumbs: [],
				loadingThumbs: false,
				actionThumb: null,
				hexTransparencies: {
					100: 'FF',
					99: 'FC',
					98: 'FA',
					97: 'F7',
					96: 'F5',
					95: 'F2',
					94: 'F0',
					93: 'ED',
					92: 'EB',
					91: 'E8',
					90: 'E6',
					89: 'E3',
					88: 'E0',
					87: 'DE',
					86: 'DB',
					85: 'D9',
					84: 'D6',
					83: 'D4',
					82: 'D1',
					81: 'CF',
					80: 'CC',
					79: 'C9',
					78: 'C7',
					77: 'C4',
					76: 'C2',
					75: 'BF',
					74: 'BD',
					73: 'BA',
					72: 'B8',
					71: 'B5',
					70: 'B3',
					69: 'B0',
					68: 'AD',
					67: 'AB',
					66: 'A8',
					65: 'A6',
					64: 'A3',
					63: 'A1',
					62: '9E',
					61: '9C',
					60: '99',
					59: '96',
					58: '94',
					57: '91',
					56: '8F',
					55: '8C',
					54: '8A',
					53: '87',
					52: '85',
					51: '82',
					50: '80',
					49: '7D',
					48: '7A',
					47: '78',
					46: '75',
					45: '73',
					44: '70',
					43: '6E',
					42: '6B',
					41: '69',
					40: '66',
					39: '63',
					38: '61',
					37: '5E',
					36: '5C',
					35: '59',
					34: '57',
					33: '54',
					32: '52',
					31: '4F',
					30: '4D',
					29: '4A',
					28: '47',
					27: '45',
					26: '42',
					25: '40',
					24: '3D',
					23: '3B',
					22: '38',
					21: '36',
					20: '33',
					19: '30',
					18: '2E',
					17: '2B',
					16: '29',
					15: '26',
					14: '24',
					13: '21',
					12: '1F',
					11: '1C',
					10: '1A',
					9: '17',
					8: '14',
					7: '12',
					6: '0F',
					5: '0D',
					4: '0A',
					3: '08',
					2: '05',
					1: '03',
					0: '00'
				}
			}
		},
		created() {
			this.season_id = this.user.seasons[0].id;
			this.getTeam();
	//	this.getPlayers();
			this.getCategories();
			this.getTeams();
			this.getTacticalBoards();

		/* Check online status */
			window.addEventListener('online', this.updateOnlineStatus);
			window.addEventListener('offline', this.updateOnlineStatus);
		},
		mounted: async function() {

		/* Load static image */
			this.getAvatarPlaceholder();

			this.configStage.width = this.$refs.container.offsetWidth;
			this.configStage.height = this.$refs.container.offsetHeight;

			const image = new window.Image();

			image.src = await this.urlToData('/assets/tacticalboard/orizzontale_rifilato.svg');
		//	image.src = await this.urlToData('/assets/tacticalboard/prospettiva.svg');

			image.onload = async () => {

				let newWidth;
				let newHeight;

				var scaleX = this.configStage.width / image.width;
				var scaleY = this.configStage.height / image.height;

				const imageRatio = image.height / image.width;
				if(scaleX > scaleY) {
					newWidth = this.configStage.height / imageRatio;
					newHeight = this.configStage.height;
				} else {
					newWidth = this.configStage.width;
					newHeight = this.configStage.width * imageRatio;
				}

				image.width = newWidth;
				image.height = newHeight;

				const xOffset = (this.configStage.width - image.width) / 2;
				this.bgXOffset = xOffset;

				this.config.bg_width = newWidth;
				this.config.bg_height = newHeight;
				this.config.bg_offsetX = this.bgXOffset;

				this.background = image;

				this.fontSize = this.config.bg_height / 90;
				this.circle = this.config.bg_height / 60;

				const pl = new window.Image();
				//	pl.src = '/assets/tacticalboard/shirt.svg';
				pl.src = await this.urlToData('/assets/tacticalboard/shirt.svg');

				pl.onload = () => {

					var playerRatio = pl.height / pl.width;
			//	var player_width = this.background.width / 25;
			//	var player_height = player_width * playerRatio;
					var player_height = this.background.width / 20;
					var player_width = player_height / playerRatio;
					pl.width = player_width;
					pl.height = player_height;

					this.player = pl;
				};

				const avatar = new window.Image();
				avatar.src = await this.urlToData('/assets/tacticalboard/avatar.jpg');

				avatar.onload = () => {
					var playerRatio = avatar.height / avatar.width;
					var player_height = this.background.width / 20;
					var player_width = player_height / playerRatio;
					avatar.width = player_width;
					avatar.height = player_height;

					this.avatar = avatar;
				};

				const ball = new window.Image();
			//	ball.src = '/assets/tacticalboard/ball.svg';
				ball.src = await this.urlToData('/assets/tacticalboard/ball.svg');

				ball.onload = () => {
					this.ball = ball
				};

				const campo_prospettiva = new window.Image();
				campo_prospettiva.src = await this.urlToData('/assets/tacticalboard/prospettiva.svg');

				campo_prospettiva.onload = () => {
					const imageRatio = campo_prospettiva.height / campo_prospettiva.width;
					if(scaleX > scaleY) {
						newWidth = this.configStage.height / imageRatio;
						newHeight = this.configStage.height;
					} else {
						newWidth = this.configStage.width;
						newHeight = this.configStage.width * imageRatio;
					}

					campo_prospettiva.width = newWidth;
					campo_prospettiva.height = newHeight;

					const xOffset = (this.configStage.width - campo_prospettiva.width) / 2;
					this.bgXOffsetProspettiva = xOffset;
					this.bgProspettiva = campo_prospettiva;
				};

				this.loadingThumbs = true;
			};
		},
		beforeDestroy() {
			window.removeEventListener('online', this.updateOnlineStatus);
			window.removeEventListener('offline', this.updateOnlineStatus);
		},
		methods: {

			updateRandomKey() {
		//	this.randomKey = 'kk_' + this.getRandom();
			},

			selectTB(id) {
				if(this.tbSelected == id) {
					this.tbSelected = null;
				} else {
					this.tbSelected = id;
				}
			},
			switchLocked() {
				if(this.locked) {
					if(confirm("Are you sure you want to unlock the tactical board?")) {
						this.locked = false;
					}
				} else {
					this.locked = true;
				}
			},

			urlToData( url ){
				return fetch(url)
				.then(response => response.blob())
				.then(blob => new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.onloadend = () => resolve(reader.result)
					reader.onerror = reject
					reader.readAsDataURL(blob)
				}))
			},

			undoStep(){
			/** Check if there is an Undo */
				if( this.currentPageSteps[this.currentPage] !== null && this.currentPageSteps[this.currentPage] !== undefined ){
					const undo_step = this.currentPageSteps[this.currentPage]['current_step'] - 1
					if( this.currentPageSteps[this.currentPage][`step_${undo_step}`] !== null && this.currentPageSteps[this.currentPage][`step_${undo_step}`] !== undefined ){
						const undo_step_canvas = _.cloneDeep(this.currentPageSteps[this.currentPage][`step_${undo_step}`])

						this.canvasShouldWatch = false

						this.canvas.config    = undo_step_canvas.config
						this.canvas.lines     = undo_step_canvas.lines
						this.canvas.arrows    = undo_step_canvas.arrows
						this.canvas.rects     = undo_step_canvas.rects
						this.canvas.players   = undo_step_canvas.players
						this.canvas.rosa      = undo_step_canvas.rosa
						this.canvas.opponents = undo_step_canvas.opponents
						this.canvas.objects   = undo_step_canvas.objects

						this.currentPageSteps[this.currentPage]['current_step'] = undo_step
					}
				}
			},
			redoStep(){
				/** Check if there is a Redo */
				if( this.currentPageSteps[this.currentPage] !== null && this.currentPageSteps[this.currentPage] !== undefined ){
					const redo_step = this.currentPageSteps[this.currentPage]['current_step'] + 1
					if( this.currentPageSteps[this.currentPage][`step_${redo_step}`] !== null && this.currentPageSteps[this.currentPage][`step_${redo_step}`] !== undefined ){
						const redo_step_canvas = _.cloneDeep(this.currentPageSteps[this.currentPage][`step_${redo_step}`])

						this.canvasShouldWatch = false

						this.canvas.config    = redo_step_canvas.config
						this.canvas.lines     = redo_step_canvas.lines
						this.canvas.arrows    = redo_step_canvas.arrows
						this.canvas.rects     = redo_step_canvas.rects
						this.canvas.players   = redo_step_canvas.players
						this.canvas.rosa      = redo_step_canvas.rosa
						this.canvas.opponents = redo_step_canvas.opponents
						this.canvas.objects   = redo_step_canvas.objects

						this.currentPageSteps[this.currentPage]['current_step'] = redo_step
					}
				}
			},

			getAvatarPlaceholder: async function() {
				this.avatarPlaceholder = await this.urlToData( '/assets/tacticalboard/avatar.jpg' );
			},

			updateOnlineStatus() {
				this.isOnline = navigator.onLine;
			},

			getRandom() {
				return Math.floor(Math.random() * 1000) + 1;
			},

			getTacticalBoards: function () {
				this.$http.get(this.$store.state.apiEndPoint + '/tacticalboard/by/category/' + this.categoriaId).then((response) => {
					this.tacticalBoardFiles = response.data;						
				});
			},

			selectTeamFormation(id) {
				this.canvas.rosa = [];
				var modulo = this.team_moduli.find(item => item.id == id);
				var formation = JSON.parse(modulo.formazione);
				formation.forEach((item, index) => {
					var foundPlayer = modulo.football ? this.rosa_opts.find(p => p.id == item.persona_id) : this.rosa_opts.find(p => p.wyscout_id == item.wyId);
					if(foundPlayer) {
						var pos = modulo.football ? foundPlayer.ruolo : item.position;
						var foundPosition = this.positions_perc[pos];
						if(!foundPosition) {
							foundPosition = this.positions_default[index + 1];
						} 
						if(foundPosition) {
							var position = {
								x: this.direction == 'default' ? (this.bgXOffset + ((this.field == 'half' ? this.background.width/2 : this.background.width) * foundPosition.x/100) - this.player.width) : ((this.bgXOffset + this.background.width) - ((this.field == 'half' ? this.background.width/2 : this.background.width) * foundPosition.x/100) - this.player.width),
								y: (this.background.height * foundPosition.y/100) - this.player.height/2,
							}
							this.addPlayerRosa(foundPlayer.id, position);
						}
					}
				});
			},

			selectOpponentFormation(id) {
				this.canvas.opponents = [];
				var modulo = this.opponent_moduli.find(item => item.id == id);
				var formation = JSON.parse(modulo.formazione);
				formation.forEach((item, index) => {
					var foundPlayer = modulo.football ? this.opponents_opts.find(p => p.id == item.persona_id) : this.opponents_opts.find(p => p.wyscout_id == item.wyId);
					if(foundPlayer) {
						var pos = modulo.football ? foundPlayer.ruolo : item.position;
						var foundPosition = this.positions_perc[pos];
						if(!foundPosition) {
							foundPosition = this.positions_default[index + 1];
						} 
						if(foundPosition) {
							var position = {
								x: this.direction == 'default' ? ((this.bgXOffset + this.background.width) - ((this.field == 'half' ? this.background.width/2 : this.background.width) * foundPosition.x/100) - this.player.width) : (this.bgXOffset + ((this.field == 'half' ? this.background.width/2 : this.background.width) * foundPosition.x/100) - this.player.width),
								y: (this.background.height * foundPosition.y/100) - this.player.height/2,
							}
							this.addPlayerOpponent(foundPlayer.id, position);
						}
					}
				});
			},

			selectPlayer(id, isRosa) {
				if(this.player_sel == id) {
					this.player_sel = null;
				} else {
					this.isRosa = isRosa;
					if(this.isRosa && this.hasSelRosa && this.replaceRosa != null) {
						var player = this.rosa_opts.find(pl => pl.id == id);
						var node = this.nodesSelected[0];
						if(this.replaceRosa == 'sel') {
							var playerSel = this.canvas.rosa.find(pl => pl.name == node.attrs.name);
							if(playerSel) {
								const shirt = new window.Image();
								shirt.src = player.ruolo == 'PO' ? this.team.divisa_portiere_base64 : this.team.divisa_base64;
								shirt.width = this.player.width;
								shirt.height = this.player.height;
						//	shirt.crossOrigin = 'Anonymous';

								const avatar = new window.Image();
								avatar.src = player.avatar;
						//	avatar.crossOrigin = 'Anonymous';

								avatar.onload = () => {
									var avatarRatio = avatar.height / avatar.width;
							//	var avatar_width = this.background.width / 25;
							//	var avatar_height = avatar_width * avatarRatio;
									var avatar_height = this.background.width / 20;
									var avatar_width = avatar_height / avatarRatio;
									avatar.width = avatar_width;
									avatar.height = avatar_height;

									playerSel.id = player.id;
									playerSel.avatar = avatar;
									playerSel.altezza = player.altezza;
									playerSel.piede = player.foot;
									playerSel.number = player.number;
									playerSel.label = player.name;
									playerSel.name = playerSel.name.replace(playerSel.id, player.id);

									this.updateRandomKey();
								};
							}
						} else if(this.replaceRosa == 'all') {
							this.canvas_pages.forEach(canvas => {
								var playerSel = canvas.rosa.find(pl => pl.id == node.attrs.id);
								if(playerSel) {
									const shirt = new window.Image();
									shirt.src = player.ruolo == 'PO' ? this.team.divisa_portiere_base64 : this.team.divisa_base64;
									shirt.width = this.player.width;
									shirt.height = this.player.height;
							//	shirt.crossOrigin = 'Anonymous';

									const avatar = new window.Image();
									avatar.src = player.avatar;
							//	avatar.crossOrigin = 'Anonymous';

									avatar.onload = () => {
										var avatarRatio = avatar.height / avatar.width;
								//	var avatar_width = this.background.width / 25;
								//	var avatar_height = avatar_width * avatarRatio;
										var avatar_height = this.background.width / 20;
										var avatar_width = avatar_height / avatarRatio;
										avatar.width = avatar_width;
										avatar.height = avatar_height;

										playerSel.id = player.id;
										playerSel.avatar = avatar;
										playerSel.altezza = player.altezza;
										playerSel.piede = player.foot;
										playerSel.number = player.number;
										playerSel.label = player.name;
										playerSel.name = playerSel.name.replace(playerSel.id, player.id);

										this.updateRandomKey();
									};
								}
							});
						}
						this.replaceRosa = null;
					} else if(!this.isRosa && this.hasSelOpponent && this.replaceOpponent != null) {
						var player = this.opponents_opts.find(pl => pl.id == id);
						var node = this.nodesSelected[0];
						if(this.replaceOpponent == 'sel') {
							var playerSel = this.canvas.opponents.find(pl => pl.name == node.attrs.name);
							if(playerSel) {
								const shirt = new window.Image();
								shirt.src = player.ruolo == 'PO' ? this.opponent.divisa_portiere_base64 : this.opponent.divisa_base64;
								shirt.width = this.player.width;
								shirt.height = this.player.height;
						//	shirt.crossOrigin = 'Anonymous';

								const avatar = new window.Image();
								avatar.src = player.avatar;
						//	avatar.crossOrigin = 'Anonymous';

								avatar.onload = () => {
									var avatarRatio = avatar.height / avatar.width;
							//	var avatar_width = this.background.width / 25;
							//	var avatar_height = avatar_width * avatarRatio;
									var avatar_height = this.background.width / 20;
									var avatar_width = avatar_height / avatarRatio;
									avatar.width = avatar_width;
									avatar.height = avatar_height;

									playerSel.id = player.id;
									playerSel.avatar = avatar;
									playerSel.altezza = player.altezza;
									playerSel.piede = player.foot;
									playerSel.number = player.number;
									playerSel.label = player.name;
									playerSel.name = playerSel.name.replace(playerSel.id, player.id);

									this.updateRandomKey();
								};
							}
						} else if(this.replaceOpponent == 'all') {
							this.canvas_pages.forEach(canvas => {
								var playerSel = canvas.opponents.find(pl => pl.id == node.attrs.id);
								if(playerSel) {
									const shirt = new window.Image();
									shirt.src = player.ruolo == 'PO' ? this.opponent.divisa_portiere_base64 : this.opponent.divisa_base64;
									shirt.width = this.player.width;
									shirt.height = this.player.height;
							//	shirt.crossOrigin = 'Anonymous';

									const avatar = new window.Image();
									avatar.src = player.avatar;
							//	avatar.crossOrigin = 'Anonymous';

									avatar.onload = () => {
										var avatarRatio = avatar.height / avatar.width;
								//	var avatar_width = this.background.width / 25;
								//	var avatar_height = avatar_width * avatarRatio;
										var avatar_height = this.background.width / 20;
										var avatar_width = avatar_height / avatarRatio;
										avatar.width = avatar_width;
										avatar.height = avatar_height;

										playerSel.id = player.id;
										playerSel.avatar = avatar;
										playerSel.altezza = player.altezza;
										playerSel.piede = player.foot;
										playerSel.number = player.number;
										playerSel.label = player.name;
										playerSel.name = playerSel.name.replace(playerSel.id, player.id);

										this.updateRandomKey();
									};
								}
							});
						}
						this.replaceOpponent = null;

					} else {
						this.player_sel = id;
					}
				}
			},

			selectTeam: function(squadra) {			
				this.$refs.selectTeamModal.show();
			},

			getTeam() {
				if(this.rosaTeamId) {
					this.loadingTeam = true;
					this.$http.get(this.$store.state.apiEndPoint + `/squadre/get/` + this.rosaTeamId).then( async (res) => {
						this.team = {
							...res.data,
							divisa_base64: await this.urlToData(res.data.divisa_url),
							divisa_portiere_base64: await this.urlToData(res.data.divisa_portiere_url)
						};
						this.loadingTeam = false;
					});
					this.loadingModuliTeam = true;
					this.$http.get(this.$store.state.apiEndPoint + `/tacticalboard/moduli/squadra/` + this.rosaTeamId, {params: {season_id: this.season_id}}).then((res) => {
						this.team_moduli = res.data;
						this.loadingModuliTeam = false;
					});
				}
			},

			paginate(page) {
				this.page = page;
				this.getTeams();
			},

			getTeams: function () {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then((response) => {
					this.teams = {
						...response.data,
						data: []
					}

					response.data.data.forEach( async ( team ) => {
						this.teams.data.push({
							...team,
							divisa_base64: await this.urlToData(team.divisa_url),
							divisa_portiere_base64: await this.urlToData(team.divisa_portiere_url)
						})
					})
				});
			},

			getCategories() {
				this.$http.get(this.$store.state.apiEndPoint + `/exercise/categories`)
				.then((res) => {
					this.categories_opt = res.data.map(item => ({
						name: item.name,
						id: item.id,
					}));
				});
			},

			filtraArchivio() {
				this.teams = {
					data: []
				};
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then((response) => {
					this.teams = response.data;
				});
			},

			getPlayers: function() {
				this.rosa_opts = [];
				this.loadingRosa = true;
				this.$http.get(this.$store.state.apiEndPoint + '/players', {params: { team_id: this.rosaTeamId, no_stats: true, add_info: true, season_id: this.season_id }}).then((response) => {
					if(response.data.players) {
						response.data.players.forEach( async (item) => {
							const avatar = item.info_extra && item.info_extra.board_avatar ? item.board_avatar_url : item.avatar_url;
							const avatarBase64 = await this.urlToData(avatar);
							this.rosa_opts.push({
								id: item.id,
						//	name: item.cognome + ' ' + item.nome.substr(0, 1) + '.', 
								name: item.cognome, 
								number: item.last_carriera_sportiva.maglia ? item.last_carriera_sportiva.maglia : '',
							// avatar: item.info_extra && item.info_extra.board_avatar ? item.board_avatar_url : item.avatar_url,
								avatar: avatarBase64,
								altezza: item.info_fisica ? item.info_fisica.altezza : null,
								foot: item.giocatore ? this.piede[item.giocatore.piede] : null,
								ruolo: item.last_carriera_sportiva.ruolo_ideale && item.last_carriera_sportiva.ruolo_ideale.length > 0 ? item.last_carriera_sportiva.ruolo_ideale[0].sigla : null,
								wyscout_id: item.wyscout ? item.wyscout.chiave : null,
							});
							if(this.rosa_opts.length == response.data.players.length) {
								this.loadingRosa = false;
								if(this.currentTacticalBoard.id) {
									this.loadRosa(this.currentTacticalBoard.id);
								}
							}
						});
					} else {
						this.loadingRosa = false;
					}
				});
			},

			setTeam: async function(team) {
				this.opponent = team;
				if(!this.opponent.divisa_base64) {
					this.loadingOpponent = true;
					this.opponent.divisa_base64 = await this.urlToData(team.divisa_url);
					this.opponent.divisa_portiere_base64 = await this.urlToData(team.divisa_portiere_url);
					this.loadingOpponent = false;
				}
				this.loadingModuliOpponent = true;
				this.$http.get(this.$store.state.apiEndPoint + `/tacticalboard/moduli/squadra/` + this.opponent.id, {params: {season_id: this.season_id}}).then((res) => {
					this.opponent_moduli = res.data;
					this.loadingModuliOpponent = false;
				});
				this.$refs.selectTeamModal.hide();
				this.getOpponents();
			},

			getOpponents: function() {				
				this.opponents_opts = [];
				if(this.opponent) {
					this.loadingOpponents = true;
					this.$http.get(this.$store.state.apiEndPoint + '/opponents/' + this.opponent.id + '/' + this.opponent.id_categoria + '/' + this.season_id).then((response) => {
						if(response.data.players) {
							response.data.players.forEach( async (item) => {
								const avatar = item.info_extra && item.info_extra.board_avatar ? item.board_avatar_url : item.avatar_url;
								const avatarBase64 = await this.urlToData(avatar);
								this.opponents_opts.push({	
									id: item.id,							
							//	name: item.cognome + ' ' + item.nome.substr(0, 1) + '.', 
									name: item.cognome, 
									number: item.last_carriera_sportiva.maglia ? item.last_carriera_sportiva.maglia : '',
								//avatar: item.info_extra && item.info_extra.board_avatar ? item.board_avatar_url : item.avatar_url,
									avatar: avatarBase64,
									altezza: item.info_fisica ? item.info_fisica.altezza : null,
									foot: item.giocatore ? this.piede[item.giocatore.piede] : null,
									ruolo: item.last_carriera_sportiva.ruolo_ideale && item.last_carriera_sportiva.ruolo_ideale.length > 0 ? item.last_carriera_sportiva.ruolo_ideale[0].sigla : null,
									wyscout_id: item.wyscout ? item.wyscout.chiave : null,
								});
								if(this.opponents_opts.length == response.data.players.length) {
									this.loadingOpponents = false;
									if(this.currentTacticalBoard.id) {
										this.loadOpponents(this.currentTacticalBoard.id);
									}
								}
							});
						} else {
							this.loadingOpponents = false;
						}
					});
				} 
			},

			setActiveObject(obj) {
				this.lockedObjects = [];
				if(obj == this.activeObject) {
					this.activeObject = null;
				} else {
					this.activeObject = obj;
				}
			},

			setLockedObject(obj) {
				if(!this.lockedObjects.includes(obj)) {
					this.lockedObjects.push(obj);
					this.activeObject = obj;
				}
			},

			addPlayer(position, from = null) {
				if(position == 'rosa') {
					from = position;
					position = {
						x: this.bgXOffset + (this.background.width/4) - Math.floor(Math.random() * this.background.width/4),
						y: (this.background.height/3) + Math.floor(Math.random() * this.background.height/4),
					}
				} else if(position == 'opponent') {
					from = position;
					position = {
						x: this.bgXOffset + (this.background.width/4) * 3 + Math.floor(Math.random() * this.background.width/4),
						y: (this.background.height/3) + Math.floor(Math.random() * this.background.height/4),
					}
				}
				const shirt = new window.Image();
				shirt.src = from == 'rosa' ? this.team.divisa_base64 : (this.opponent ? this.opponent.divisa_base64 : this.player.src);
				shirt.width = this.player.width;
				shirt.height = this.player.height;

				const avatar = new window.Image();
				avatar.src = this.avatar.src;
				avatar.width = this.avatar.width;
				avatar.height = this.avatar.height;

				const pl = new window.Image();
				pl.src = this.player.src;
				pl.width = this.player.width;
				pl.height = this.player.height;

				pl.onload = () => {
					this.canvas.players.push({image: pl, avatar: avatar, shirt: shirt, x: position.x, y: position.y, label: 'player ' + (this.canvas.players.length + 1), name: 'pl_' + this.getRandom() + '_' + this.canvas.players.length, rotation: 0, scaleX: 1, scaleY: 1, fontSize: this.fontSize, enfasiLabel: this.config.enfasiLabel, zoomLabel: this.config.zoomLabel, colorLabel: this.config.colorLabel, bgLabel: this.config.bgLabel, opacityLabel: this.config.opacityLabel, showImage: this.config.showImage, from: from, isNew: true });
					this.updateRandomKey();
				};
			},

			addObject(name, scale, position) {
				const object = new window.Image();
				if(name == 'ball') {
					object.src = this.ball.src;
				}
				object.onload = () => {
					var objectRatio = object.height / object.width;
					var object_width = this.background.width / scale;
					var object_height = object_width * objectRatio;
					object.width = object_width;
					object.height = object_height;
					this.canvas.objects.push({image: object, x: position.x, y: position.y, name: 'obj_' + name + '_' + this.getRandom() + '_' + this.canvas.objects.length, rotation: 0, scaleX: 1, scaleY: 1, isNew: true });
					this.updateRandomKey();
				};
			},

			addPlayerRosa(player_id, position) {
				if(!position) {
					position = {
						x: this.bgXOffset + (this.background.width/4) - Math.floor(Math.random() * this.background.width/4),
						y: (this.background.height/3) + Math.floor(Math.random() * this.background.height/4),
					}
				}
				var player = this.rosa_opts.find(item => item.id == player_id);
				const shirt = new window.Image();
				shirt.src = player.ruolo == 'PO' ? this.team.divisa_portiere_base64 : this.team.divisa_base64;
				shirt.width = this.player.width;
				shirt.height = this.player.height;
		//	shirt.crossOrigin = 'Anonymous';

				const avatar = new window.Image();
				avatar.src = player.avatar;
		//	avatar.crossOrigin = 'Anonymous';

				avatar.onload = () => {
					var avatarRatio = avatar.height / avatar.width;
			//	var avatar_width = this.background.width / 25;
			//	var avatar_height = avatar_width * avatarRatio;
					var avatar_height = this.background.width / 20;
					var avatar_width = avatar_height / avatarRatio;
					avatar.width = avatar_width;
					avatar.height = avatar_height;
					this.canvas.rosa.push({id: player_id, avatar: avatar, shirt: shirt, altezza: player.altezza, foot: player.foot, number: player.number, x: position.x, y: position.y, label: player.name, name: 'rosa_' + player_id + '_' + this.getRandom() + '_' + this.canvas.rosa.length, rotation: 0, scaleX: 1, scaleY: 1, fontSize: this.fontSize, enfasiLabel: this.config.enfasiLabel, enfasiAltezza: this.config.enfasiAltezza, enfasiPiede: this.config.enfasiPiede, zoomLabel: this.config.zoomLabel, zoomAltezza: this.config.zoomAltezza, zoomPiede: this.config.zoomPiede, alwaysShowNumero: this.config.alwaysShowNumero, colorLabel: this.config.colorLabel, bgLabel: this.config.bgLabel, opacityLabel: this.config.opacityLabel, showImage: this.config.showImage, showAltezza: this.config.showAltezza, showPiede: this.config.showPiede, isNew: true });
					this.updateRandomKey();
				};
			},

			addPlayerOpponent(player_id, position) {
				if(!position) {
					position = {
						x: this.bgXOffset + (this.background.width/4) * 3 + Math.floor(Math.random() * this.background.width/4),
						y: (this.background.height/3) + Math.floor(Math.random() * this.background.height/4),
					}
				}
				var player = this.opponents_opts.find(item => item.id == player_id);
				const shirt = new window.Image();
				shirt.src = player.ruolo == 'PO' ? this.opponent.divisa_portiere_base64 : this.opponent.divisa_base64;
				shirt.width = this.player.width;
				shirt.height = this.player.height;
		//	shirt.crossOrigin = 'Anonymous';

				const avatar = new window.Image();
				avatar.src = player.avatar;
		//	avatar.crossOrigin = 'Anonymous';

				avatar.onload = () => {
					var avatarRatio = avatar.height / avatar.width;
			//	var avatar_width = this.background.width / 25;
			//	var avatar_height = avatar_width * avatarRatio;
					var avatar_height = this.background.width / 20;
					var avatar_width = avatar_height / avatarRatio;
					avatar.width = avatar_width;
					avatar.height = avatar_height;
					this.canvas.opponents.push({id: player_id, avatar: avatar, shirt: shirt, altezza: player.altezza, foot: player.foot, number: player.number, x: position.x, y: position.y, label: player.name, name: 'opp_' + player_id + '_' + this.getRandom() + '_' + this.canvas.opponents.length, rotation: 0, scaleX: 1, scaleY: 1, fontSize: this.fontSize, enfasiLabel: this.config.enfasiLabel, enfasiAltezza: this.config.enfasiAltezza, enfasiPiede: this.config.enfasiPiede, zoomLabel: this.config.zoomLabel, zoomAltezza: this.config.zoomAltezza, zoomPiede: this.config.zoomPiede, alwaysShowNumero: this.config.alwaysShowNumero, colorLabel: this.config.colorLabel, bgLabel: this.config.bgLabel, opacityLabel: this.config.opacityLabel, showImage: this.config.showImage, showAltezza: this.config.showAltezza, showPiede: this.config.showPiede, isNew: true });
					this.updateRandomKey();
				};
			},

			handleMouseDown(e) {
				if(this.activeObject) {
					this.isDrawing = true;
					const pos = e.target.getStage().getPointerPosition();
					if(this.activeObject == 'line') {
						this.setLines([...this.canvas.lines, { 
							points: [pos.x, pos.y], 
							color: this.colorSelected, 
							strokeWidth: parseInt(this.strokeWidthSelected), 
							name: 'line_' + this.getRandom() + '_' + this.canvas.lines.length, 
							isNew: true,
							rotation: 0, 
							scaleX: 1, 
							scaleY: 1,
							width: 0, 
							height: 0, 
							x: 0, 
							y: 0,
						}]);
					} else if(this.activeObject == 'arrow') {
						this.setArrows([...this.canvas.arrows, { 
							points: [pos.x, pos.y], 
							color: this.colorSelected, 
							strokeWidth: parseInt(this.strokeWidthSelected), 
							name: 'arrow_' + this.getRandom() + '_' + this.canvas.arrows.length, 
							isNew: true,
							rotation: 0, 
							scaleX: 1, 
							scaleY: 1,
							width: 0, 
							height: 0, 
							x: 0, 
							y: 0,
						}]);
					} else if(this.activeObject == 'rect') {
						this.setRects([...this.canvas.rects, {
							startPointX: pos.x,
							startPointY: pos.y, 
							width: 0, 
							height: 0, 
							name: 'rect_' + this.getRandom() + '_' + this.canvas.rects.length, 
							color: this.colorSelected, 
							strokeWidth: parseInt(this.strokeWidthSelected), 
							opacity: this.opacitySelected, 
							rotation: 0, 
							scaleX: 1, 
							scaleY: 1,
							isNew: true,
							x: pos.x, 
							y: pos.y,
						}]);
					} else if(this.activeObject == 'player_rosa') {
						this.addPlayer(pos, 'rosa');
					} else if(this.activeObject == 'player_opponent') {
						this.addPlayer(pos, 'opponent');
					} else if(this.activeObject.startsWith('obj_')) {
						this.addObject(this.activeObject.split("_")[1], 50, pos);
					}
				} else {
					if (e.target !== e.target.getStage()) {
						return;
					}
					e.evt.preventDefault();
					this.points.x1 = e.target.getStage().getPointerPosition().x;
					this.points.y1 = e.target.getStage().getPointerPosition().y;
					this.points.x2 = e.target.getStage().getPointerPosition().x;
					this.points.y2 = e.target.getStage().getPointerPosition().y;
					this.$refs.selRect[0].getNode().width(0);
					this.$refs.selRect[0].getNode().height(0);
					this.selecting = true;
				}
			},

			handleMouseMove(e) {
				if(this.activeObject) {
					if (!this.isDrawing) {
						return;
					}
					const stage = e.target.getStage();
					const point = stage.getPointerPosition();
					if(this.activeObject == 'line') {
						let lastLine = this.canvas.lines[this.canvas.lines.length - 1];
						lastLine.points = lastLine.points.concat([point.x, point.y]);
						this.canvas.lines.splice(this.canvas.lines.length - 1, 1, lastLine);
					} else if(this.activeObject == 'arrow') {
						let lastArrow = this.canvas.arrows[this.canvas.arrows.length - 1];
						lastArrow.points = [lastArrow.points[0], lastArrow.points[1], point.x, point.y];
						this.canvas.arrows.splice(this.canvas.arrows.length - 1, 1, lastArrow);
					} else if(this.activeObject == 'rect') {
						let curRect = this.canvas.rects[this.canvas.rects.length - 1];
						curRect.width = point.x - curRect.startPointX;
						curRect.height = point.y - curRect.startPointY;
					}
				} else {
					if(!this.selecting) {
						return;
					}
					e.evt.preventDefault();
					this.points.x2 = e.target.getStage().getPointerPosition().x;
					this.points.y2 = e.target.getStage().getPointerPosition().y;	
					this.$refs.selRect[0].getNode().setAttrs({
						visible: true,
						x: Math.min(this.points.x1, this.points.x2),
						y: Math.min(this.points.y1, this.points.y2),
						width: Math.abs(this.points.x2 - this.points.x1),
						height: Math.abs(this.points.y2 - this.points.y1),
					});
				}
			},

			handleClick(e) {
				if(this.player_sel) {
					const pos = e.target.getStage().getPointerPosition();
					if(this.isRosa) {
						this.addPlayerRosa(this.player_sel, pos);
					} else {
						this.addPlayerOpponent(this.player_sel, pos);
					}
					this.player_sel = null;		
				} else {
					if (this.$refs.selRect[0].getNode().visible()) {
						return;
					}
					if (e.target === e.target.getStage()) {
						this.$refs.transformer[0].getNode().nodes([]);
						this.nodesSelected = this.$refs.transformer[0].getNode().nodes();

						this.textEditAreas.forEach((obj) =>  {
							obj.item.label = obj.textarea.value;
							document.body.removeChild(obj.textarea);
						});
						this.textEditAreas = [];

						return;
					}

					var target = null;
					if(e.target.getParent() && e.target.getParent().attrs.name) {
						target = e.target.getParent();
					} else if(e.target.getParent() && e.target.getParent().getParent() && e.target.getParent().getParent().attrs.name) {
						target = e.target.getParent().getParent();
					} else {
						target = e.target;
					}
					const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
					const isSelected = this.$refs.transformer[0].getNode().nodes().indexOf(target) >= 0;
					if (!metaPressed && !isSelected) {
						this.$refs.transformer[0].getNode().nodes([target]);
					} else if (metaPressed && isSelected) {
						const nodes = this.$refs.transformer[0].getNode().nodes().slice(); 
						nodes.splice(nodes.indexOf(target), 1);
						this.$refs.transformer[0].getNode().nodes(nodes);
					} else if (metaPressed && !isSelected) {
						const nodes = this.$refs.transformer[0].getNode().nodes().concat([target]);
						this.$refs.transformer[0].getNode().nodes(nodes);
					}
					this.nodesSelected = this.$refs.transformer[0].getNode().nodes();
				}
			},

			handleMouseUp(e) {
				if(!this.lockedObjects.includes(this.activeObject)) {
					this.activeObject = null;
				}

				this.isDrawing = false;
				this.selecting = false;
				if (!this.$refs.selRect[0].getNode().visible()) {
					return;
				}
				e.evt.preventDefault();
				this.$refs.selRect[0].getNode().visible(false);
				var shapes = [];
				this.canvas.rects.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				this.canvas.lines.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				this.canvas.arrows.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				this.canvas.players.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				this.canvas.objects.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				this.canvas.rosa.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				this.canvas.opponents.forEach((element) =>  shapes.push(e.target.getStage().find('.' + element.name )));
				shapes = _.flatten(shapes);
				var box = this.$refs.selRect[0].getNode().getClientRect();
				var selected = shapes.filter((shape) =>
					Konva.Util.haveIntersection(box, shape.getClientRect())
					);
				this.$refs.transformer[0].getNode().nodes(selected);
				this.nodesSelected = this.$refs.transformer[0].getNode().nodes();
			},

			setRects(element) {
				this.canvas.rects = element;
			},

			setArrows(element) {
				this.canvas.arrows = element;
			},

			setLines(element) {
				this.canvas.lines = element;
			},

			clearSel() {
				var nodes = this.$refs.transformer[0].getNode().nodes();
				nodes.forEach((node) => {
					var items = null;
					if(node.attrs.name.startsWith('rect_')) {
						items = this.canvas.rects;
					} else if(node.attrs.name.startsWith('line_')) {
						items = this.canvas.lines;	
					} else if(node.attrs.name.startsWith('arrow_')) {
						items = this.canvas.arrows;	
					} else if(node.attrs.name.startsWith('obj_')) {
						items = this.canvas.objects;	
					} else if(node.attrs.name.startsWith('pl_')) {
						items = this.canvas.players;	
					} else if(node.attrs.name.startsWith('rosa_')) {
						items = this.canvas.rosa;	
					} else if(node.attrs.name.startsWith('opp_')) {
						items = this.canvas.opponents;	
					}
					if(items) {
						const findIndex = items.findIndex((r) => r.name === node.attrs.name);
						if (findIndex > -1) {
							items.splice(findIndex, 1);
						}
					}
				});
				this.$refs.transformer[0].getNode().nodes([]);	
				this.nodesSelected = this.$refs.transformer[0].getNode().nodes();
			},
			clearSelDraw() {
				var nodes = this.$refs.transformer[0].getNode().nodes();
				nodes.forEach((node) => {
					var items = null;
					if(node.attrs.name.startsWith('rect_')) {
						items = this.canvas.rects;
					} else if(node.attrs.name.startsWith('line_')) {
						items = this.canvas.lines;	
					} else if(node.attrs.name.startsWith('arrow_')) {
						items = this.canvas.arrows;	
					} 
					if(items) {
						const findIndex = items.findIndex((r) => r.name === node.attrs.name);
						if (findIndex > -1) {
							items.splice(findIndex, 1);
						}
					}
				});
				this.$refs.transformer[0].getNode().nodes([]);	
				this.nodesSelected = this.$refs.transformer[0].getNode().nodes();
			},
			revertEdits: function() {
				this.load(this.currentTacticalBoard.id, this.currentPage-1);
			},
			clearAll() {
				if(confirm('Are you sure you want to delete everything?')) {
					this.canvas.lines = [];
					this.canvas.arrows = [];
					this.canvas.rects = [];
					this.canvas.players = [];
					this.canvas.rosa = [];
					this.canvas.opponents = [];
					this.canvas.objects = [];	

					this.updateRandomKey();
				}
			},

			clearAllDraw() {
				if(confirm('Are you sure you want to delete all drawings?')) {
					this.canvas.lines = [];
					this.canvas.arrows = [];
					this.canvas.rects = [];
					this.updateRandomKey();
				}
			},

			svuotaCanvas() {
				this.season_id = this.user.seasons[0].id;
				this.opponent = null;
				this.opponents_opts = [];
				this.canvas_pages = [];
				this.canvas_pages.push({
					config: {
						background: 'orizzontale'
					},
					rosa: [],
					opponents: [],  
					players: [],
					lines: [],
					arrows: [],
					rects: [],
					objects: [],
				});
				this.currentPage = 1;
				this.storedThumbs = [];
				this.loadingThumbs = true;
			},

			handleTransformEnd(evt) {
				var items = null;
				if(evt.target.attrs.name.startsWith('rect_')) {
					items = this.canvas.rects;
				} else if(evt.target.attrs.name.startsWith('line_')) {
					items = this.canvas.lines;	
				} else if(evt.target.attrs.name.startsWith('arrow_')) {
					items = this.canvas.arrows;	
				} else if(evt.target.attrs.name.startsWith('obj_')) {
					items = this.canvas.objects;	
				} else if(evt.target.attrs.name.startsWith('pl_')) {
					items = this.canvas.players;	
				} else if(evt.target.attrs.name.startsWith('rosa_')) {
					items = this.canvas.rosa;	
				} else if(evt.target.attrs.name.startsWith('opp_')) {
					items = this.canvas.opponents;	
				}
				var item = items.find(
					(r) => r.name === evt.target.attrs.name
					);

				item.x = evt.target.x();
				item.y = evt.target.y();
				item.rotation = evt.target.rotation();
				item.scaleX = evt.target.scaleX();
				item.scaleY = evt.target.scaleY();
			},

			handleDragEnd(evt) {
				var items = null;
				if(evt.target.attrs.name.startsWith('rect_')) {
					items = this.canvas.rects;
				} else if(evt.target.attrs.name.startsWith('line_')) {
					items = this.canvas.lines;	
				} else if(evt.target.attrs.name.startsWith('arrow_')) {
					items = this.canvas.arrows;	
				} else if(evt.target.attrs.name.startsWith('obj_')) {
					items = this.canvas.objects;	
				} else if(evt.target.attrs.name.startsWith('pl_')) {
					items = this.canvas.players;	
				} else if(evt.target.attrs.name.startsWith('rosa_')) {
					items = this.canvas.rosa;	
				} else if(evt.target.attrs.name.startsWith('opp_')) {
					items = this.canvas.opponents;	
				}
				var item = items.find(
					(r) => r.name === evt.target.attrs.name
					);
				item.x = evt.target.attrs.x;
				item.y = evt.target.attrs.y;

				if(item.width) {
					item.height = item.height * item.scaleY;
					item.width = item.width * item.scaleX;
					item.scaleX = 1;
					item.scaleY = 1; 
				}
			},

			objUp() {
				var nodes = this.$refs.transformer[0].getNode().nodes();
				if(nodes.length == 1 && nodes[0].attrs.name.startsWith('rect_')) {
					const rectIndex = this.canvas.rects.findIndex((r) => r.name === nodes[0].attrs.name);
					if (rectIndex > -1) {
						var rect = this.canvas.rects[rectIndex];
						this.canvas.rects.splice(rectIndex, 1);
						this.canvas.rects.push(rect);
					}
				}
			},
			objDown() {
				var nodes = this.$refs.transformer[0].getNode().nodes();
				if(nodes.length == 1 && nodes[0].attrs.name.startsWith('rect_')) {
					const rectIndex = this.canvas.rects.findIndex((r) => r.name === nodes[0].attrs.name);
					if (rectIndex > -1) {
						var rect = this.canvas.rects[rectIndex];
						this.canvas.rects.splice(rectIndex, 1);
						this.canvas.rects.unshift(rect);
					}
				}
			},
			saveAsNew() {
				this.currentTacticalBoard.id = 0;
				this.save();
			},
			save() {
				if(!this.currentTacticalBoard.nome || this.currentTacticalBoard.nome == '') {
					alert('You need to enter a name for the tactical board!');
					return;
				}

				this.canvas_pages.forEach((canvas) => {

					_.each(this.canvas.objects, function(i) {
						delete i.isNew;
					});

					_.each(this.canvas.players, function(i) {
						delete i.isNew;
					});

					_.each(this.canvas.lines, function(i) {
						delete i.isNew;
					});

					_.each(this.canvas.arrows, function(i) {
						delete i.isNew;
					});

					_.each(this.canvas.rects, function(i) {
						delete i.isNew;
					});

					_.each(this.canvas.rosa, function(i) {
						delete i.isNew;
					});

					canvas.players.forEach((item) => {
						item.image_attr = {
							width: item.image.width,
							height: item.image.height,
						}
						item.avatar_attr = {
							width: item.avatar.width,
							height: item.avatar.height,
						};
						item.shirt_attr = {
							width: item.shirt.width,
							height: item.shirt.height,
						};
					});
					canvas.rosa.forEach((item) => {
						item.avatar_attr = {
							width: item.avatar.width,
							height: item.avatar.height,
						};
						item.shirt_attr = {
							width: item.shirt.width,
							height: item.shirt.height,
						};
					});
					canvas.opponents.forEach((item) => {
						item.avatar_attr = {
							width: item.avatar.width,
							height: item.avatar.height,
						};
						item.shirt_attr = {
							width: item.shirt.width,
							height: item.shirt.height,
						};
					});
					canvas.objects.forEach((item) => {
						item.image_attr = {
							width: item.image.width,
							height: item.image.height,
						}
					});
				});

				var formData = new FormData();
				formData.append('id', this.currentTacticalBoard.id);
				formData.append('nome', this.currentTacticalBoard.nome);
				formData.append('categoria_id', this.categoriaId);
				formData.append('opponent_id', this.opponent ? this.opponent.id : 0);
				formData.append('config', JSON.stringify(this.config));
				formData.append('canvas', JSON.stringify(this.canvas_pages));

				this.$http.post(this.$store.state.apiEndPoint + '/tacticalboard/save', formData).then(function(data) {
					this.$store.commit(types.ALERTS_PUSH, { msg: data.data.message, status: 1 });
					this.getTacticalBoards();
				});

			},

			load: async function (id, page = null) {

				const thatCanvasPagesBind = []

				if(page === null)
					this.svuotaCanvas();

				var board = this.tacticalBoardFiles.find(item => item.id == id);

				if(this.season_id != board.stagione_id) {
					this.season_id = board.stagione_id;
				} else {
					this.loadRosa(id);
				}

				if(board.opponent) {
					this.setTeam(board.opponent);
				}

				this.currentTacticalBoard.id = board.id;
				this.currentTacticalBoard.nome = board.nome;

				var config = JSON.parse(board.config);
				var array_canvas = JSON.parse(board.canvas);

				this.config.showDivisa = config.showDivisa;
				this.config.showAltezza = config.showAltezza;
				this.config.showPiede = config.showPiede;
				this.config.showNumero = config.showNumero;
				this.config.enfasiLabel = config.enfasiLabel;
				this.config.enfasiAltezza = config.enfasiAltezza;
				this.config.enfasiPiede = config.enfasiPiede;
				this.config.zoomLabel = config.zoomLabel ? config.zoomLabel : 1;
				this.config.zoomAltezza = config.zoomAltezza ? config.zoomAltezza : 1;
				this.config.zoomPiede = config.zoomPiede ? config.zoomPiede : 1;
				this.config.alwaysShowNumero = config.alwaysShowNumero ? config.alwaysShowNumero : false;
				this.config.colorTeam = config.colorTeam ? config.colorTeam : '#FF0000';
				this.config.colorOpponent = config.colorOpponent ? config.colorOpponent : '#0000FF';
				this.config.colorLabel = config.colorLabel ? config.colorLabel : '#FFFFFF';
				this.config.bgLabel = config.bgLabel ? config.bgLabel : '#000000';
				this.config.opacityLabel = config.opacityLabel ? config.opacityLabel : 'FF';
				this.config.playerFromTeams = config.playerFromTeams;
				this.config.showImage = config.showImage;
				this.config.background = config.background ? config.background : 'orizzontale';

				const imagePromises = [];

				array_canvas.forEach((canvas, index) => {

					if(page !== null && page !== index) return false;

					thatCanvasPagesBind[index] = {
						config: {},
						rosa: [],
						opponents: [],  
						players: [],
						lines: [],
						arrows: [],
						rects: [],
						objects: [],
					};

					/** Set default config */
					if( index == 0 )
						this.config.background = ( canvas.config && canvas.config.background ) ? canvas.config.background : 'orizzontale';
	
					thatCanvasPagesBind[index].config = canvas.config ?? { background: 'orizzontale' };

					canvas.players.forEach((item) => {

						const imgPromise = new Promise((resolve, reject) => {
							const pl = new Image();
							pl.src = this.player.src;
							pl.width = item.image_attr.width;
							pl.height = item.image_attr.height;
							if(config.bg_width != this.config.bg_width) {
								var item_x = (item.x - config.bg_offsetX) / config.bg_width;
								var item_y = item.y / config.bg_height;
								var item_scaleX = item.scaleX / config.bg_width;
								var item_scaleY = item.scaleY / config.bg_height;

								item.x = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								item.y = item_y * this.config.bg_height;
								item.scaleX = item_scaleX * this.config.bg_width;
								item.scaleY = item_scaleY * this.config.bg_height;
							}

							pl.onload = () => {
								resolve(pl);
								item.image = pl;
								thatCanvasPagesBind[index].players.push(item);
							};
							pl.onerror = reject;
						});
						imagePromises.push(imgPromise);

						const imgPromise2 = new Promise((resolve, reject) => {
							const shirt = new Image();
							shirt.src = item.from == 'rosa' ? (this.team ? this.team.divisa_base64 : this.player.src) : (this.opponent ? this.opponent.divisa_base64 : this.player.src);
							shirt.width = item.shirt_attr.width;
							shirt.height = item.shirt_attr.height;
							shirt.onload = () => {
								resolve(shirt);
								item.shirt = shirt;
							};
							shirt.onerror = reject;
						});

						imagePromises.push(imgPromise2);

						const imgPromise3 = new Promise((resolve, reject) => {
							const avatar = new Image();
							avatar.src = this.avatar.src;
							avatar.width = item.avatar_attr.width;
							avatar.height = item.avatar_attr.height;
							if(config.bg_width != this.config.bg_width) {
								var item_x = (item.x - config.bg_offsetX) / config.bg_width;
								var item_y = item.y / config.bg_height;
								var item_scaleX = item.scaleX / config.bg_width;
								var item_scaleY = item.scaleY / config.bg_height;

								item.x = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								item.y = item_y * this.config.bg_height;
								item.scaleX = item_scaleX * this.config.bg_width;
								item.scaleY = item_scaleY * this.config.bg_height;
							}

							avatar.onload = () => {
								resolve(avatar);
								item.avatar = avatar;
							};
							avatar.onerror = reject;
						});
						imagePromises.push(imgPromise3);
					});

					canvas.objects.forEach((item) => {

						const imgPromise = new Promise((resolve, reject) => {
							const obj = new Image();
							obj.src = this.ball.src;
							obj.width = item.image_attr.width;
							obj.height = item.image_attr.height;
							if(config.bg_width != this.config.bg_width) {
								var item_x = (item.x - config.bg_offsetX) / config.bg_width;
								var item_y = item.y / config.bg_height;
								var item_scaleX = item.scaleX / config.bg_width;
								var item_scaleY = item.scaleY / config.bg_height;

								item.x = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								item.y = item_y * this.config.bg_height;
								item.scaleX = item_scaleX * this.config.bg_width;
								item.scaleY = item_scaleY * this.config.bg_height;
							}

							obj.onload = () => {
								resolve(obj);
								item.image = obj;
								thatCanvasPagesBind[index].objects.push(item);
							};
							obj.onerror = reject;
						});
						imagePromises.push(imgPromise);
					});

					if(config.bg_width != this.config.bg_width) {

						canvas.rects.forEach((item) => {
							var item_x = (item.x - config.bg_offsetX) / config.bg_width;
							var item_y = item.y / config.bg_height;
							var item_width = item.width / config.bg_width;
							var item_height = item.height / config.bg_height;

							item.x = (item_x * this.config.bg_width) + this.config.bg_offsetX;
							item.y = item_y * this.config.bg_height;
							item.width = item_width * this.config.bg_width;
							item.height = item_height * this.config.bg_height;	
						});

						canvas.lines.forEach((item) => {
							for(var index = 0; index < item.points.length; index++) {
								if(index % 2 == 0) {
									var item_x = (item.points[index] - config.bg_offsetX) / config.bg_width;
									item.points[index] = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								} else {
									var item_y = item.points[index] / config.bg_height;
									item.points[index] = item_y * this.config.bg_height;
								}
							}
						});

						canvas.arrows.forEach((item) => {
							for(var index = 0; index < item.points.length; index++) {
								if(index % 2 == 0) {
									var item_x = (item.points[index] - config.bg_offsetX) / config.bg_width;
									item.points[index] = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								} else {
									var item_y = item.points[index] / config.bg_height;
									item.points[index] = item_y * this.config.bg_height;
								}
							}
						});
					}

					thatCanvasPagesBind[index].rects = canvas.rects;
					thatCanvasPagesBind[index].lines = canvas.lines;
					thatCanvasPagesBind[index].arrows = canvas.arrows;

				});
				
				this.currentPageRevert = false;

				await Promise.all(imagePromises);

				console.log(thatCanvasPagesBind);

				/** Assign object to observer **/
				_.each( thatCanvasPagesBind, ( page_obj, page_index ) => {	
					this.canvasShouldWatch = false

					if( this.canvas_pages[page_index] === undefined ){
						this.addEmptyPage()
						this.actionThumb = null;
					}

					this.canvas_pages[page_index].config    = page_obj.config
					this.canvas_pages[page_index].lines     = page_obj.lines
					this.canvas_pages[page_index].arrows    = page_obj.arrows
					this.canvas_pages[page_index].rects     = page_obj.rects
					this.canvas_pages[page_index].players   = page_obj.players
					this.canvas_pages[page_index].rosa      = page_obj.rosa
					this.canvas_pages[page_index].opponents = page_obj.opponents
					this.canvas_pages[page_index].objects   = page_obj.objects
				})

				this.updateRandomKey();
				this.loadingThumbs = true;
				this.currentPage = 1
			},

			loadRosa: async function(id, page = null) {
				var board = this.tacticalBoardFiles.find(item => item.id == id);
				var config = JSON.parse(board.config);
				var array_canvas = JSON.parse(board.canvas);

				const imagePromises = [];

				array_canvas.forEach((canvas, index) => {

					if(page !== null && page !== index) return false;

					canvas.rosa.forEach((item) => {	

						var player = this.rosa_opts.find(pl => pl.id == item.id);

						const imgPromise = new Promise((resolve, reject) => {
							const shirt = new Image();
							shirt.src = player.ruolo == 'PO' ? this.team.divisa_portiere_base64 : this.team.divisa_base64;
							shirt.width = item.shirt_attr.width;
							shirt.height = item.shirt_attr.height;
							shirt.onload = () => {
								resolve(shirt);
								item.shirt = shirt;
							};
							shirt.onerror = reject;
						});

						imagePromises.push(imgPromise);

						const imgPromise2 = new Promise((resolve, reject) => {
							const avatar = new Image();
							avatar.src = player.avatar;
							avatar.width = item.avatar_attr.width;
							avatar.height = item.avatar_attr.height;
							if(config.bg_width != this.config.bg_width) {
								var item_x = (item.x - config.bg_offsetX) / config.bg_width;
								var item_y = item.y / config.bg_height;
								var item_scaleX = item.scaleX / config.bg_width;
								var item_scaleY = item.scaleY / config.bg_height;

								item.x = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								item.y = item_y * this.config.bg_height;
								item.scaleX = item_scaleX * this.config.bg_width;
								item.scaleY = item_scaleY * this.config.bg_height;
							}

							avatar.onload = () => {
								resolve(avatar);
								item.avatar = avatar;
								this.canvas_pages[index].rosa.push(item);
									//	thatCanvasPagesBind[index].rosa.push(item);
							};
							avatar.onerror = reject;
						});

						imagePromises.push(imgPromise2);
					});

				});

				this.currentPageRevert = false;

				await Promise.all(imagePromises);
				this.updateRandomKey();
				this.loadingThumbs = true;
			},

			loadOpponents: async function(id, page = null) {
				var board = this.tacticalBoardFiles.find(item => item.id == id);
				var config = JSON.parse(board.config);
				var array_canvas = JSON.parse(board.canvas);

				const imagePromises = [];

				array_canvas.forEach((canvas, index) => {

					if(page !== null && page !== index) return false;

					canvas.opponents.forEach((item) => {	

						var player = this.opponents_opts.find(pl => pl.id == item.id);

						const imgPromise = new Promise((resolve, reject) => {
							const shirt = new Image();
							shirt.src = player.ruolo == 'PO' ? this.opponent.divisa_portiere_base64 : this.opponent.divisa_base64;
							shirt.width = item.shirt_attr.width;
							shirt.height = item.shirt_attr.height;
							shirt.onload = () => {
								resolve(shirt);
								item.shirt = shirt;
							};
							shirt.onerror = reject;
						});

						imagePromises.push(imgPromise);

						const imgPromise2 = new Promise((resolve, reject) => {
							const avatar = new Image();
							avatar.src = player.avatar;
							avatar.width = item.avatar_attr.width;
							avatar.height = item.avatar_attr.height;
							if(config.bg_width != this.config.bg_width) {
								var item_x = (item.x - config.bg_offsetX) / config.bg_width;
								var item_y = item.y / config.bg_height;
								var item_scaleX = item.scaleX / config.bg_width;
								var item_scaleY = item.scaleY / config.bg_height;

								item.x = (item_x * this.config.bg_width) + this.config.bg_offsetX;
								item.y = item_y * this.config.bg_height;
								item.scaleX = item_scaleX * this.config.bg_width;
								item.scaleY = item_scaleY * this.config.bg_height;
							}

							avatar.onload = () => {
								resolve(avatar);
								item.avatar = avatar;
								this.canvas_pages[index].opponents.push(item);
							};
							avatar.onerror = reject;
						});

						imagePromises.push(imgPromise2);
					});

				});

				this.currentPageRevert = false;

				await Promise.all(imagePromises);
				this.updateRandomKey();
				this.loadingThumbs = true;
			},
			deleteTb(id) {
				if(confirm("Are you sure you want to remove this tactical board?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/tacticalboard/delete/' + id).then((data) => {
						this.$store.commit(types.ALERTS_PUSH, { msg: data.data.message, status: 1 });
						if(this.currentTacticalBoard.id == id) {
							this.resetCurrentTacticalBoard();
						}
						this.getTacticalBoards();
					});
				}
			},
			newBoard() {
				if(this.currentTacticalBoard.id) {
					if(confirm("Are you sure you want to close the current tactical board?")) {
						this.resetCurrentTacticalBoard();
						this.svuotaCanvas();
					}
				} else {
					this.currentTacticalBoard.nome = null;
					this.svuotaCanvas();
				}
			},
			resetCurrentTacticalBoard() {
				this.currentTacticalBoard.id = 0;
				this.currentTacticalBoard.nome = null;
			},
			download() {
				let stage = this.$refs.stage[0].getNode();
				const uri = stage.toDataURL({ pixelRatio: 2 });
				this.downloadURI(uri, this.currentTacticalBoard.nome ? this.currentTacticalBoard.nome + '.png' : 'tacticalboard.png');
			},
			downloadAssets() {
				
				const zip       = new JSZip();
				const imgFolder = zip.folder("assets");
				
				let promises = []
				
				let rosaPlayersPromises = []
				let oppoPlayersPromises = []
				
				console.log(this.$refs);
				
				if( this.canvas.rosa && this.canvas.rosa.length > 0 ){
					rosaPlayersPromises = this.canvas.rosa.map((rosaEl, index) => {
						return new Promise((resolve) => {
							let rosaPlayerLayer = this.$refs[`extRosaPlayer${index}`][0].getNode();
							const uri = rosaPlayerLayer.toDataURL({ pixelRatio: 2 });

							fetch(uri)
								.then(res => res.blob())
								.then(blob => {
									imgFolder.file(`player_${rosaEl.id}.png`, blob);
									resolve();
								});
						});
					});
				}

				if( this.canvas.opponents && this.canvas.opponents.length > 0 ){
					oppoPlayersPromises = this.canvas.opponents.map((oppoEl, index) => {
						return new Promise((resolve) => {
							let oppoPlayerLayer = this.$refs[`extOppoPlayer${index}`][0].getNode();
							const uri = oppoPlayerLayer.toDataURL({ pixelRatio: 2 });

							fetch(uri)
								.then(res => res.blob())
								.then(blob => {
									imgFolder.file(`opponent_player_${oppoEl.id}.png`, blob);
									resolve();
								});
						});
					});
				}

				promises = rosaPlayersPromises.concat( oppoPlayersPromises )

				/** Generate zip from promises */
				if( promises.length > 0 ){
					Promise.all( promises ).then(() => {
						zip.generateAsync({ type: "blob" }).then((content) => {
							saveAs(content, "players_assets.zip");
						});
					});
				}else{
					alert( 'Non hai ancora inserito nessun giocatore nella Lavagna tattica' )
				}
			},			
			downloadURI(uri, name) {
				var link = document.createElement('a');
				link.download = name;
				link.href = uri;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			downloadAllPage() {
				var doc = new jsPDF("l", "mm", "a4");
				var width = doc.internal.pageSize.getWidth();
				var height = doc.internal.pageSize.getHeight();	

				let newWidth;
				let newHeight;

				var scaleX = width / this.background.width;
				var scaleY = height /  this.background.height;

				const imageRatio = this.background.height / this.background.width;

				if(scaleX > scaleY) {
					newWidth = height / imageRatio;
					newHeight = height;
				} else {
					newWidth = width;
					newHeight = width * imageRatio;
				}

				const xOffset = (width - newWidth) / 2;
				const yOffset = (height - newHeight) / 2;

				this.thumbnails.forEach((thumb, index) => {
					doc.addImage(thumb, 'JPEG', xOffset, yOffset, newWidth, newHeight);
					if(index < this.thumbnails.length - 1) {
						doc.addPage();
					}
				});
				doc.save(this.currentTacticalBoard.nome ? this.currentTacticalBoard.nome + '.pdf' : 'tacticalboard.pdf');
			},

			editText(evt) {

				var groupNode = evt.target.getParent();

				if(groupNode.getParent() && groupNode.getParent().attrs.name) {
					groupNode = groupNode.getParent();
				}

				var items = null;
				if(groupNode.attrs.name.startsWith('pl_')) {
					items = this.canvas.players;	
				} else if(groupNode.attrs.name.startsWith('rosa_')) {
					items = this.canvas.rosa;	
				} else if(groupNode.attrs.name.startsWith('opp_')) {
					items = this.canvas.opponents;	
				}
				var item = items.find(r => r.name === groupNode.attrs.name);

				var textNode = groupNode.children[1];
				var textPosition = textNode.getAbsolutePosition();
				let stage = this.$refs.stage[0].getNode();
				var stageBox = stage.container().getBoundingClientRect();
				var areaPosition = {
					x: stageBox.left + textPosition.x,
					y: stageBox.top + textPosition.y,
				};
				var textarea = document.createElement('textarea');
				document.body.appendChild(textarea);

				textarea.value = item.label;
				textarea.style.position = 'absolute';
				textarea.style.top = areaPosition.y + 'px';
				textarea.style.left = areaPosition.x + 'px';
				textarea.style.width = textNode.width();

				textarea.focus();

				this.textEditAreas.push({item: item, textarea: textarea});

				textarea.addEventListener('keydown', function (e) {
					if (e.keyCode === 13) {
						item.label = textarea.value;
						document.body.removeChild(textarea);
						var index = this.textEditAreas.findIndex(obj => obj.item.name == item.name);
						if(index > -1) {
							this.textEditAreas.splice(index, 1);
						}
					}
				}.bind(this)); 	
			},

			addEmptyPage() {
				this.canvas_pages.push({
					config: {
						background: 'orizzontale'
					},
					rosa: [],
					opponents: [],  
					players: [],
					lines: [],
					arrows: [],
					rects: [],
					objects: [],
				});
				this.currentPage = this.canvas_pages.length;
				this.actionThumb = 'add';
				this.loadingThumbs = true;
			},

			copyCurrentPage() {
				this.canvas_pages.push(_.cloneDeep(this.canvas_pages[this.currentPage - 1]));
					//	this.currentPage = this.canvas_pages.length;
				this.actionThumb = 'copy';
				this.loadingThumbs = true;
			},

			removeCurrentPage() {
				if(confirm('Are you sure you want to delete the current page?')) {
					this.canvas_pages.splice(this.currentPage - 1, 1);
						/*	if(this.currentPage > 1) {
								this.currentPage--;
							}  */
					this.actionThumb = 'remove';
					this.loadingThumbs = true;
				}
			},
			removeSelectedPages() {
				if(confirm("Are you sure you want to remove the selected pages?")) {
					var sorted = _.sortBy(this.pages_selected).reverse();
					sorted.forEach(page => {
						this.canvas_pages.splice(page - 1, 1);
						this.storedThumbs.splice(page - 1, 1);
					});
					if(this.canvas_pages.length == 0) {
						this.canvas_pages.push({
							config: {
								background: 'orizzontale'
							},
							rosa: [],
							opponents: [],  
							players: [],
							lines: [],
							arrows: [],
							rects: [],
							objects: [],
						});
					}
					this.switchPageSelection();
					this.currentPage = this.canvas_pages.length;
				}
			},
			switchPageSelection() {
				this.hasPageSelection = !this.hasPageSelection;
				if(!this.hasPageSelection) {
					this.pages_selected = [];
				}
			},
			replaceRosaSel() {
				if(this.replaceRosa == 'sel') {
					this.replaceRosa = null;
				} else {
					this.replaceRosa = 'sel';
				}
			},
			replaceRosaAll() {
				if(this.replaceRosa == 'all') {
					this.replaceRosa = null;
				} else {
					this.replaceRosa = 'all';
				}
			},
			replaceOpponentSel() {
				if(this.replaceOpponent == 'sel') {
					this.replaceOpponent = null;
				} else {
					this.replaceOpponent = 'sel';
				}
			},
			replaceOpponentAll() {
				if(this.replaceOpponent == 'all') {
					this.replaceOpponent = null;
				} else {
					this.replaceOpponent = 'all';
				}
			},
			zoomLabel(sign) {
				var increment = (sign == '-' ? -0.1 : 0.1);
				this.config.zoomLabel = Math.round((this.config.zoomLabel += increment) * 10) / 10;	
			},
			zoomAltezza(sign) {
				var increment = (sign == '-' ? -0.1 : 0.1);
				this.config.zoomAltezza = Math.round((this.config.zoomAltezza += increment) * 10) / 10;	
			},
			zoomPiede(sign) {
				var increment = (sign == '-' ? -0.1 : 0.1);
				this.config.zoomPiede = Math.round((this.config.zoomPiede += increment) * 10) / 10;	
			}
	},
	watch: {

		'config.background': { 
			handler: function() {				
				if( this.canvas_pages[this.currentPage-1].config === undefined )
					this.canvas_pages[this.currentPage-1].config = {}

				this.canvas_pages[this.currentPage-1].config.background = this.config.background
			}
		},
		'nodesSelected': { 
			handler: function() {

				if(this.hasSelRosa && this.nodesSelected.length == 1) {
					var hasSelected = null;
					var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
					if(players.length > 0) {
						hasSelected = true;
					}
					var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
					if(rosa.length > 0) {
						hasSelected = true;
					}
					var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
					if(opponents.length > 0) {
						hasSelected = true;
					}
					if(hasSelected) {

						var foundPlayer = null
						players.forEach(node => {
							foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
						});
						
						if(!foundPlayer) {
							rosa.forEach(node => {
								foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
							});
						}
						
						if(!foundPlayer) {
							opponents.forEach(node => {
								foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
							});
						}

						_.each(foundPlayer, function(v,k) {
							if(this.config[k] !== undefined)
								this.config[k] = _.cloneDeep(v);
						}.bind(this));

						console.log('Item Found', foundPlayer, this.config);

					}

				}

			}
		},
		'config.enfasiLabel': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiLabel = this.config.enfasiLabel;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiLabel = this.config.enfasiLabel;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiLabel = this.config.enfasiLabel;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.enfasiLabel = this.config.enfasiLabel;
				});
				this.canvas.rosa.forEach(player => {
					player.enfasiLabel = this.config.enfasiLabel;
				});
				this.canvas.opponents.forEach(player => {
					player.enfasiLabel = this.config.enfasiLabel;
				});
			}
		},

		'config.enfasiAltezza': function () {
			var hasSelected = false;
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiAltezza = this.config.enfasiAltezza;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiAltezza = this.config.enfasiAltezza;
					}
				});
			} else {
				this.canvas.rosa.forEach(player => {
					player.enfasiAltezza = this.config.enfasiAltezza;
				});
				this.canvas.opponents.forEach(player => {
					player.enfasiAltezza = this.config.enfasiAltezza;
				});
			}
		},

		'config.enfasiPiede': function () {
			var hasSelected = false;
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiPiede = this.config.enfasiPiede;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.enfasiPiede = this.config.enfasiPiede;
					}
				});
			} else {
				this.canvas.rosa.forEach(player => {
					player.enfasiPiede = this.config.enfasiPiede;
				});
				this.canvas.opponents.forEach(player => {
					player.enfasiPiede = this.config.enfasiPiede;
				});
			}
		},

		'config.zoomLabel': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomLabel = this.config.zoomLabel;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomLabel = this.config.zoomLabel;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomLabel = this.config.zoomLabel;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.zoomLabel = this.config.zoomLabel;
				});
				this.canvas.rosa.forEach(player => {
					player.zoomLabel = this.config.zoomLabel;
				});
				this.canvas.opponents.forEach(player => {
					player.zoomLabel = this.config.zoomLabel;
				});
			}
		},

		'config.zoomAltezza': function () {
			var hasSelected = false;
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomAltezza = this.config.zoomAltezza;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomAltezza = this.config.zoomAltezza;
					}
				});
			} else {
				this.canvas.rosa.forEach(player => {
					player.zoomAltezza = this.config.zoomAltezza;
				});
				this.canvas.opponents.forEach(player => {
					player.zoomAltezza = this.config.zoomAltezza;
				});
			}
		},

		'config.zoomPiede': function () {
			var hasSelected = false;
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomPiede = this.config.zoomPiede;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.zoomPiede = this.config.zoomPiede;
					}
				});
			} else {
				this.canvas.rosa.forEach(player => {
					player.zoomPiede = this.config.zoomPiede;
				});
				this.canvas.opponents.forEach(player => {
					player.zoomPiede = this.config.zoomPiede;
				});
			}
		},
		'config.alwaysShowNumero': function () {
			var hasSelected = false;
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.alwaysShowNumero = this.config.alwaysShowNumero;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.alwaysShowNumero = this.config.alwaysShowNumero;
					}
				});
			} else {
				this.canvas.rosa.forEach(player => {
					player.alwaysShowNumero = this.config.alwaysShowNumero;
				});
				this.canvas.opponents.forEach(player => {
					player.alwaysShowNumero = this.config.alwaysShowNumero;
				});
			}
		},

		'config.colorLabel': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.colorLabel = this.config.colorLabel;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.colorLabel = this.config.colorLabel;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.colorLabel = this.config.colorLabel;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.colorLabel = this.config.colorLabel;
				});
				this.canvas.rosa.forEach(player => {
					player.colorLabel = this.config.colorLabel;
				});
				this.canvas.opponents.forEach(player => {
					player.colorLabel = this.config.colorLabel;
				});
			}
		},

		'config.bgLabel': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.bgLabel = this.config.bgLabel;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.bgLabel = this.config.bgLabel;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.bgLabel = this.config.bgLabel;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.bgLabel = this.config.bgLabel;
				});
				this.canvas.rosa.forEach(player => {
					player.bgLabel = this.config.bgLabel;
				});
				this.canvas.opponents.forEach(player => {
					player.bgLabel = this.config.bgLabel;
				});
			}
		},

		'config.opacityLabel': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.opacityLabel = this.config.opacityLabel;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.opacityLabel = this.config.opacityLabel;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.opacityLabel = this.config.opacityLabel;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.opacityLabel = this.config.opacityLabel;
				});
				this.canvas.rosa.forEach(player => {
					player.opacityLabel = this.config.opacityLabel;
				});
				this.canvas.opponents.forEach(player => {
					player.opacityLabel = this.config.opacityLabel;
				});
			}
		},

		'config.showImage': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showImage = this.config.showImage;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showImage = this.config.showImage;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showImage = this.config.showImage;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.showImage = this.config.showImage;
				});
				this.canvas.rosa.forEach(player => {
					player.showImage = this.config.showImage;
				});
				this.canvas.opponents.forEach(player => {
					player.showImage = this.config.showImage;
				});
			}
		},

		'config.showAltezza': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showAltezza = this.config.showAltezza;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showAltezza = this.config.showAltezza;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showAltezza = this.config.showAltezza;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.showAltezza = this.config.showAltezza;
				});
				this.canvas.rosa.forEach(player => {
					player.showAltezza = this.config.showAltezza;
				});
				this.canvas.opponents.forEach(player => {
					player.showAltezza = this.config.showAltezza;
				});
			}
		},

		'config.showPiede': function () {
			var hasSelected = false;
			var players = this.nodesSelected.filter(item => item.attrs.name.startsWith('pl_'));
			if(players.length > 0) {
				hasSelected = true;
			}
			var rosa = this.nodesSelected.filter(item => item.attrs.name.startsWith('rosa_'));
			if(rosa.length > 0) {
				hasSelected = true;
			}
			var opponents = this.nodesSelected.filter(item => item.attrs.name.startsWith('opp_'));
			if(opponents.length > 0) {
				hasSelected = true;
			}
			if(hasSelected) {
				players.forEach(node => {
					var foundPlayer = this.canvas.players.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showPiede = this.config.showPiede;
					}
				});
				rosa.forEach(node => {
					var foundPlayer = this.canvas.rosa.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showPiede = this.config.showPiede;
					}
				});
				opponents.forEach(node => {
					var foundPlayer = this.canvas.opponents.find(el => el.name === node.attrs.name);
					if(foundPlayer) {
						foundPlayer.showPiede = this.config.showPiede;
					}
				});
			} else {
				this.canvas.players.forEach(player => {
					player.showPiede = this.config.showPiede;
				});
				this.canvas.rosa.forEach(player => {
					player.showPiede = this.config.showPiede;
				});
				this.canvas.opponents.forEach(player => {
					player.showPiede = this.config.showPiede;
				});
			}
		},

		colorSelected: function () {
			var nodes = this.$refs.transformer[0].getNode().nodes();
			nodes.forEach((node) => {
				var items = null;
				if(node.attrs.name.startsWith('rect_')) {
					items = this.canvas.rects;
				} else if(node.attrs.name.startsWith('line_')) {
					items = this.canvas.lines;
				} else if(node.attrs.name.startsWith('arrow_')) {
					items = this.canvas.arrows;
				}
				if(items) {
					var item = items.find(el => el.name === node.attrs.name);
					item.color = this.colorSelected;
				}
			});
		},

		strokeWidthSelected: function () {
			var nodes = this.$refs.transformer[0].getNode().nodes();
			nodes.forEach((node) => {
				var items = null;
				if(node.attrs.name.startsWith('rect_')) {
					items = this.canvas.rects;
				} else if(node.attrs.name.startsWith('line_')) {
					items = this.canvas.lines;
				} else if(node.attrs.name.startsWith('arrow_')) {
					items = this.canvas.arrows;
				}
				if(items) {
					var item = items.find(el => el.name === node.attrs.name);
					item.strokeWidth = parseInt(this.strokeWidthSelected);
				}
			});
		},

		opacitySelected: function () {
			var nodes = this.$refs.transformer[0].getNode().nodes();
			nodes.forEach((node) => {
				var items = null;
				if(node.attrs.name.startsWith('rect_')) {
					items = this.canvas.rects;
				}
				if(items) {
					var item = items.find(el => el.name === node.attrs.name);
					item.opacity = this.opacitySelected;
				}
			});
		},

		season_id: function() {
			this.getPlayers();
		},

		categoriaId: function() {
			this.getTeam();
			this.getPlayers();
			this.resetCurrentTacticalBoard();
			this.svuotaCanvas();
			this.getTacticalBoards();
		},

		isOnline: function() {
					/* Fai qualcoas sul change del flag Online */
			if( !!!this.isOnline ){
				alert('Attenzione!!! Sei offline')
			}
		},

		canvas: {
			handler: _.debounce( function( curr_canvas ) {

				if(!this.loadingThumbs) {
					this.actionThumb = 'update';
					this.loadingThumbs = true;
				}

				/** Skip on Undo/Redo */
				if( !!!this.canvasShouldWatch ){
					this.canvasShouldWatch = true
					return 
				}

				let curr_step = 1
				if( this.currentPageSteps[this.currentPage] !== null && this.currentPageSteps[this.currentPage] !== undefined ){
					curr_step = this.currentPageSteps[this.currentPage]['current_step'] + 1	

						/* Remove all successive steps */
					_.each( this.currentPageSteps[this.currentPage], ( step_canvas, step_key ) => {
						if( step_key == 'current_step' )
							return

						const step_number = parseInt(step_key.replace('step_',''))

						if( step_number >= curr_step ){
							delete this.currentPageSteps[this.currentPage][step_key]
						}
					})
				}

				/* Limit reached - Shift object 1 back  */
				const undoRedoLimit = 30
				if( curr_step > undoRedoLimit ){

					curr_step = undoRedoLimit

					/* Remove oldest step */
					delete this.currentPageSteps[this.currentPage][1]

					_.each( this.currentPageSteps[this.currentPage], ( step_canvas, step_key ) => {
						if( step_key == 'current_step' )
							return

						const step_number = parseInt(step_key.replace('step_',''))

						/* Put step n in step n-1 { step_25 become step_24 so step} */
						if( step_number > 1 ){
							const old_key = `step_${step_number}`
							const new_key = `step_${step_number-1}`
							this.currentPageSteps[this.currentPage][new_key] = _.cloneDeep(this.currentPageSteps[this.currentPage][old_key])
						}
					})
				}

				/** Update undo - redo object */
				const key = `step_${curr_step}`
				this.currentPageSteps[this.currentPage] = {
					...(_.cloneDeep(this.currentPageSteps[this.currentPage])),
					'current_step': curr_step,
					[key]: _.cloneDeep(curr_canvas)
				}
			}, 400 ),
			deep: true
		},

		currentPage: function () {
			this.updateRandomKey();
			this.canvasShouldWatch = false;			
			this.config.background = this.canvas.config.background
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		canvas: function() {
			return this.canvas_pages[this.currentPage - 1];
		},
		isDraggable: function() {
			return !this.activeObject;
		},
		hasSelRect: function () {
			if(this.nodesSelected.length == 1 && this.nodesSelected[0].attrs.name.startsWith('rect_')) {
				return true;
			}
			return false;
		},
		hasSelRosa: function () {
			if(this.nodesSelected.length == 1 && this.nodesSelected[0].attrs.name.startsWith('rosa_')) {
				return true;
			}
			return false;
		},
		hasSelOpponent: function () {
			if(this.nodesSelected.length == 1 && this.nodesSelected[0].attrs.name.startsWith('opp_')) {
				return true;
			}
			return false;
		},
		hasEditDraw: function () {
			if(this.nodesSelected.length > 0) {
				var filtered = this.nodesSelected.filter(item => !item.attrs.name.startsWith('rect_') && !item.attrs.name.startsWith('line_')  && !item.attrs.name.startsWith('arrow_'));
				if(filtered.length == 0) {
					return true;
				}
			}
			return false;
		},
		hasEditOpacity: function () {
			if(this.nodesSelected.length > 0) {
				var filtered = this.nodesSelected.filter(item => !item.attrs.name.startsWith('rect_'));
				if(filtered.length == 0) {
					return true;
				}
			}
			return false;
		},
		hasRevert: function() {
			return this.currentTacticalBoard.id;
		},
		hasUndoRedo: function() {
			return this.currentTacticalBoard.id;
		},
		hasClearAll: function () {
			if(!this.canvas) {
				return false;
			}
			if(this.canvas.rosa.length > 0) {
				return true;
			}
			if(this.canvas.opponents.length > 0) {
				return true;
			}
			if(this.canvas.players.length > 0) {
				return true;
			}
			if(this.canvas.lines.length > 0) {
				return true;
			}
			if(this.canvas.arrows.length > 0) {
				return true;
			}
			if(this.canvas.rects.length > 0) {
				return true;
			}
			if(this.canvas.objects.length > 0) {
				return true;
			}
			return false;
		},

		hasClearAllDraw: function () {
			if(!this.canvas) {
				return false;
			}
			if(this.canvas.lines.length > 0) {
				return true;
			}
			if(this.canvas.arrows.length > 0) {
				return true;
			}
			if(this.canvas.rects.length > 0) {
				return true;
			}
			return false;
		},

		hasSelDraw: function () {
			if(this.nodesSelected.length > 0) {
				var filtered = this.nodesSelected.filter(item => item.attrs.name.startsWith('rect_') || item.attrs.name.startsWith('line_')  || item.attrs.name.startsWith('arrow_'));
				if(filtered.length > 0) {
					return true;
				}
			}
			return false;
		},

		teamModuliGrouped: function() {
			return _.groupBy(this.team_moduli, 'modulo');
		},

		teamModuliSorted: function() {
			var keys = _.keys(this.teamModuliGrouped);
			return _.sortBy(keys, function(a) {
				return this.teamModuliGrouped[a].length;		
			}.bind(this)).reverse();
		},

		opponentModuliGrouped: function() {
			return _.groupBy(this.opponent_moduli, 'modulo');
		},

		opponentModuliSorted: function() {
			var keys = _.keys(this.opponentModuliGrouped);
			return _.sortBy(keys, function(a) {
				return this.opponentModuliGrouped[a].length;		
			}.bind(this)).reverse();
		},
		thumbnails: function() {
			var thumbnails = this.storedThumbs;
			if(this.loadingThumbs) {
				if(this.actionThumb) {
					if(this.actionThumb == 'add') {
						thumbnails.push(this.background.src);
					} else if(this.actionThumb == 'copy') {
						thumbnails.push(_.cloneDeep(thumbnails[this.currentPage - 1]));
						this.currentPage = this.canvas_pages.length;
					} else if(this.actionThumb == 'remove') {
						thumbnails.splice(this.currentPage - 1, 1);
						if(this.currentPage > 1) {
							this.currentPage--;
						}
					} else if(this.actionThumb == 'update') {
						let stage = this.$refs.stage[0].getNode();
						const thumb = stage ? stage.toDataURL({ pixelRatio: 2 }) : this.background.src;
						thumbnails[this.currentPage - 1] = thumb;	
					}
					this.actionThumb = null;
					this.loadingThumbs = false;
				} else {
					thumbnails = [];
					this.canvas_pages.forEach((item, index) => {
						let stage = this.$refs.stage[index] ? this.$refs.stage[index].getNode() : null;
						const thumb = stage ? stage.toDataURL({ pixelRatio: 2 }) : this.background.src;
						thumbnails.push(thumb);

						if(this.canvas_pages.length == index + 1) {
							this.loadingThumbs = false;
						}	
					});
				}
				this.storedThumbs = thumbnails;
			}
			return thumbnails;
		},
		...mapState(['rosaTeamId', 'categoriaId']),
	},
	filters: {
		dateTimeFormat: function (date) {
			return moment(date).format('DD/MM/YYYY HH:mm');
		},
		dateFormat: function (date) {
			return moment(date).format('DD/MM/YY');
		}
	}
}
</script>

<style>
html, body {
	overscroll-behavior-x: none;
}
</style>