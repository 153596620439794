<template>
	<div>
		<!-- <p class="lead ml-1 mb-0">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(valutazione)}}</span></p> -->
		<div
			:read="true"
			:evaluation="valutazione"
			:hideTitle="true"
			:is="valutazione.valutazione_glo_id == 2 ? calculateTypePartita(valutazione) : calculateTypePlayer(valutazione)" 
		>
		</div>
	</div>
</template>

<script>

export default {
	props: [ 'valutazione' ],
	components: {
		ValutazionePartitaBreve: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazionePartitaEstesa: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazioneGiocatoreBreve: require('@/components/layout/Valutazioni/ValutazioneGiocatoreBreve.vue').default,
		ValutazioneGiocatoreEstesa: require('@/components/layout/Valutazioni/ValutazioneGiocatoreEstesa.vue').default,
		ValutazioneGiocatoreConfigReport: require('@/components/layout/Valutazioni/ValutazioneGiocatoreConfigReport.vue').default,
	},
	methods: {
		generateName: function(name) {
			return this.valutazione.glossario.nome;
		},
		calculateTypePartita: function(what) {
			return 'ValutazionePartita' + this.valutazione.glossario.component;
		},
		calculateTypePlayer: function() {
			// @TODO-MANDARINO adattare al componente del DB - al momento è statico per disallineamento tra DB e visualizzazione
			// return 'ValutazioneGiocatore' + this.valutazione.glossario.component;
			return 'ValutazioneGiocatoreConfigReport';
		}
	},
	data: function() {
		return {
		}
	},
	mounted: function() {
		// this.$refs.mod.show();
	}
}

</script>