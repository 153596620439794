<template>

	<b-form @submit.prevent="inviaValutazioni">

		<div v-if="valutazioneGiocatore.length > 0">
			<div v-for="a,k in valutazioneGiocatore" class="mb-3" style="background-color: rgba(0,0,0,.05); border-radius: 5px; padding: 0.5em; border: 1px solid rgba(0,0,0,.1)">
				<!-- <b-row class="d-flex align-items-center mb-2">
					<b-col>
						<p class="lead ml-1 mb-0">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(a)}}</span></p>
					</b-col>
					<b-col class="text-right"><a href="javascript:;" @click.prevent="removePlayer(k)">&times; Rimuovi</a></b-col>
				</b-row> -->
				<div 
					:evaluation="a" 
					:is="calculateTypePlayer(a)"
					@remove="removePlayer(a)"
				>
				</div>
			</div>
		</div>

		<hr v-if="valutazioneGiocatore.length > 0" />
		<div v-else class="py-1" style="width: 5px"></div>
		<div class="lead text-muted my-2 text-center" v-if="valutazioneGiocatore.length > 0">Inserisci un altra valutazione</div>

		<b-row class="align-items-center" :class="{ 'my-2': valutazioneGiocatore.length == 0 }">
			<b-col cols="3" class="mb-1" style="height: 100%" v-for="val in typeOfPlayerValutations">
				<b-card 
					align="center" 
					@click.prevent="addValutazioneGiocatore(val)" 
					:style="(!isPlayerClick(val.id) ? 'cursor:pointer;height:100%;' : 'opacity: .5;') + 'line-height: 1; height: 72px;'"
				>
					{{ val.nome }}
				</b-card>
			</b-col>
		</b-row>

		<center class="my-3">
			<b-button type="submit" v-if="valutazioneGiocatore.length > 0">Salva Valutazion{{ valutazioneGiocatore.length == 1 ? 'e':'i' }}</b-button>
			<!--b-button @click="$emit('refresh', player.id)">Refresh</b-button-->
		</center>

	</b-form>

</template>

<script>

export default {
	props: [ 'player' ],
	components: {
		ValutazioneGiocatoreBreve: require('@/components/layout/Valutazioni/ValutazioneGiocatoreBreve.vue').default,
		ValutazioneGiocatoreEstesa: require('@/components/layout/Valutazioni/ValutazioneGiocatoreEstesa.vue').default,
		ValutazioneGiocatoreConfigReport: require('@/components/layout/Valutazioni/ValutazioneGiocatoreConfigReport.vue').default,
	},
	methods: {
		generateName: function(name) {
			return _.startCase(name.kind);
		},
		isPlayerClick: function(k) {
			return _.find(this.valutazioneGiocatore, { valutazione_glo_id: k });
		},
		removePlayer: function(evaluation) {			
			this.valutazioneGiocatore = this.valutazioneGiocatore.filter( 
				( valutazione ) => evaluation.glossario.id !== valutazione.valutazione_glo_id 
			)
		},
		calculateTypePlayer: function(what) {
			// @TODO-MANDARINO adattare al componente del DB - al momento è statico per disallineamento tra DB e visualizzazione
			// return 'ValutazioneGiocatore' + what.kind;
			return 'ValutazioneGiocatoreConfigReport';
		},
		addValutazioneGiocatore: function(tipo) {
			// if(!this.isPlayerClick(tipo.component))
			// 	this.valutazioneGiocatore.push({ kind: tipo.component, evaluation: null, valutazione_glo_id: tipo.valutazione_glo_id });
			if(!this.isPlayerClick(tipo.id)){
				this.valutazioneGiocatore.push({ 
					kind: tipo.component,
					glossario: tipo,
					player: this.player.persona,
					evaluation: null,
					valutazione_glo_id: tipo.id
				});
			}
		},		
		inviaValutazioni: function() {
			this.ux.load = true
			var relazioni = [{  entita: 'Persona', entita_id: this.player.persona.id }];
			
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/create', { persona: this.valutazioneGiocatore, relazioni }).then(function(data) {
				this.$emit('refresh', this.player.persona.id);

				/** Reset evaluation datas **/
				this.valutazioneGiocatore = []
				this.ux.load = false
				this.ux.show = false
			});

		},
		setPlayersScoutingGlossary: function() {
			this.$http.get( this.$store.state.apiEndPoint + `/fr-valutazione/glo?area=scouting&entity=Persona` )
			.then( function({data}){	
				this.valutazioniPlayer = data.map( ( glossary ) => glossary )
			});
		},
	},
	computed: {
		typeOfPlayerValutations: function() {
			return _.filter(this.valutazioniPlayer, function(i) {
				console.log(this.player);
				
				// const role = i?.config?.role ?? null
				// return role === null || role.indexOf(this.player.role) !== -1;
				/* @TODO-MANDARINO aggiungere role a this.player proveniente da DB */
				return true
			}.bind(this));
		},
	},
	data: function() {
		return {
			ux: {
				show: false,
				load: false,
			},
			valutazioneGiocatore: [],
			valutazioniPlayer: null,
		}
	},
	mounted: function() {
		// this.$refs.mod.show();
	},
	created: function() {
		this.setPlayersScoutingGlossary()
	}
}
</script>