<template>
	<div style="max-width: 50%; margin: auto" v-if="sessione && sessione.id">

		<b-overlay :show="!match" style="min-height: 150px">

			<b-card v-if="match" @click="goToPartita(match.id)" style="cursor:pointer">

				<b-row class="align-items-center">
					<b-col cols="5">
						<b-row class="align-items-center">
							<b-col cols="9" class="text-center">
								<img :src="match.home.logo_url" style="max-height: 60px" />
							</b-col>
							<b-col cols="3" class="text-center" style="font-size: 230%">
								{{ match.risultato.result.home }}
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="2" class="text-center" style="font-size: 200%">
						-
					</b-col>
					<b-col cols="5">
						<b-row class="align-items-center">
							<b-col cols="3" class="text-center" style="font-size: 230%">
								{{ match.risultato.result.away }}
							</b-col>
							<b-col cols="9" class="text-center">
								<img :src="match.away.logo_url" style="max-height: 60px" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<b-row class="align-items-center mt-2">
					<b-col cols="5">
						<b-row class="align-items-center">
							<b-col cols="9" class="text-center" style="line-height: 1; font-weight: bold">
								{{ match.home.officialName }}
							</b-col>
							<b-col cols="3" class="text-center" style="font-size: 230%">
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="2" class="text-center text-muted small">
						<div style="line-height: 1" v-if="match.competizione" class="mb-1"><em>{{ match.competizione.nome }}</em></div>
						<div style="line-height: 1">{{ match.data | matchDate }}</div>
					</b-col>
					<b-col cols="5">
						<b-row class="align-items-center">
							<b-col cols="3" class="text-center" style="font-size: 230%">
							</b-col>
							<b-col cols="9" class="text-center" style="line-height: 1; font-weight: bold">
								{{ match.away.officialName }}
							</b-col>
						</b-row>
					</b-col>
				</b-row>

			</b-card>

		</b-overlay>
		
	</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import _ from 'lodash'
import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	props: [ 'sessione' ],
	computed: {
		
	},
	filters: {
		matchDate: function(d) {
			return moment(d).format('DD/MM/YY HH:mm')
		}
	},
	data: function() {
		return {
			match: null
		}
	},
	watch: {
		'sessione.partita_rel': {
			immediate: true,
			handler: function() {
				if(this.sessione.partita_rel)
					this.getPartita(this.sessione.partita_rel.partita_id);
			}
		}
	},
	methods: {
		getPartita: function(partitaId) {
			this.$http.get(this.$store.state.apiEndPoint + '/football/partita-lite/' + partitaId).then(function(data) {
				this.match = data.data;
			})
		},
		goToPartita(id){
			this.$router.push({ path: `/football/partita/${id}` })
		},
	}
}

</script>