<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<b-overlay :show="loading" no-wrap></b-overlay>
			<b-card :title="'Modificati (' + Object.keys(modificati).length + ')'" title-tag="span">
				<b-card-body class="px-0" style="font-size:0.9rem">
					<div class="d-flex align-items-center mb-2"><span><em> Filtra per: </em></span>
						<b-form-checkbox class="ml-3" v-model="filters.hasScoutValutazioni" :value="1" :unchecked-value="0">Con valutazioni scouting</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.hasFootballPartite" :value="1" :unchecked-value="0">Con partite football</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.hasVideotags" :value="1" :unchecked-value="0">Con videotags</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.showRisolti" :value="1" :unchecked-value="0">Mostra anche le modifiche chiuse ({{ persone_check.length }})</b-form-checkbox>
						<span class="ml-3">Dal:</span><b-form-input class="ml-1" size="sm" style="width:150px" type="date" v-model="filters.from"></b-form-input>
						<span class="ml-3">Al:</span><b-form-input class="ml-1" size="sm" style="width:150px" type="date" v-model="filters.to"></b-form-input>
					</div>
					<b-row>
						<b-col cols="3" v-for="item in modificati" class="mb-1">
							<b-card no-body class="h-100 p-1" :border-variant="filters.showRisolti && isRisolto(item.subject_type, item.subject_id) ? 'warning' : ''">
								<div style="cursor:pointer;" @click="getInfoModificati(item.subject_type, item.subject_id)">
									<b-row>
										<b-col>
											<div>Causer id: {{ item.causer_id }} - type: {{ item.causer_type }}</div>
											<div>Subject id: {{ item.subject_id }} - type: {{ item.subject_type }}</div>
										</b-col>
										<b-col cols="2">
											<b-button v-if="filters.showRisolti && isRisolto(item.subject_type, item.subject_id)" size="xs" @click.stop="riapri(item.subject_type, item.subject_id)" variant="warning">Riapri <i class="fa fa-times" aria-hidden="true"></i></b-button>
											<b-button v-else size="xs" @click.stop="chiudi(item.subject_type, item.subject_id)">Chiudi <i class="fa fa-check" aria-hidden="true"></i></b-button>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<div><em>OLD</em></div>
											<div v-for="elem, key in item.properties.old">
												{{ key }}: 
												<span v-if="key == 'updated_at'">{{ elem | dateTimeFormat }} </span>
												<span v-else-if="key == 'data_nascita'">{{ elem | dateFormat }} </span>
												<span v-else-if="key == 'cognome'"><strong>{{ elem }} </strong></span>
												<span v-else>{{ elem }}</span>
											</div>
										</b-col>
										<b-col cols="6">
											<div><em>NEW</em></div>
											<div v-for="elem, key in item.properties.attributes">
												{{ key }}: 
												<span v-if="key == 'updated_at'">{{ elem | dateTimeFormat }} </span>
												<span v-else-if="key == 'data_nascita'">{{ elem | dateFormat }} </span>
												<span v-else-if="key == 'cognome'"><strong>{{ elem }} </strong></span>
												<span v-else>{{ elem }}</span>
											</div>
										</b-col>	
									</b-row>
								</div>
							</b-card>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>

			<b-modal ref="infoModal" :title="titleModal" size="xl" ok-only>
				<b-row>
					<b-col v-for="persona in dettaglio_persone" class="mb-1" :key="persona.id">
						<cardinfo :persona="persona"></cardinfo>
					</b-col>
				</b-row>
			</b-modal>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('@/auth.js');
import * as types from '@/store/types';
import moment from 'moment';
import _ from 'lodash';
export default {

	data: function() {
		return {
			loading: false,
			modificati: [],
			dettaglio_persone: [],
			titleModal: null,
			filters: {
				hasScoutValutazioni: 0,
				hasFootballPartite: 0,
				hasVideotags: 0,
				showRisolti: 0,
				from: null,
				to: null,
			},
			persone_check: [],
			persona_ids: [],
			giocatore_ids: [],
		}
	},

	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		cardinfo: require('@/components/layout/persona/card_info.vue').default,
	},

	created: function () {   
		this.getModificati();
		this.getPersoneCheck();
	},

	watch: {
    filters: {
      handler() {
        this.getModificati();
      },      
      deep: true,
    },
  },
	methods: {
		getModificati() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/persona/modificati', {params: this.filters}).then(function(res) {
				this.modificati = res.data;
				this.loading = false;
			});
		},
		getPersoneCheck() {
			this.$http.get(this.$store.state.apiEndPoint + '/persone/check', {params: {modificata: true}}).then(function(res) {
				this.persone_check = res.data;
				this.persona_ids = _.map(this.persone_check, 'persona_id');
				this.giocatore_ids = _.map(this.persone_check, 'id_giocatore');
			});
		},

		getInfoModificati(type, ids) {
			var url = '/persone/info/';
			if(type == "App\\Models\\Giocatori") {
				url = '/persone/info/by/id/giocatore/';
			} 
			this.$http.get(this.$store.state.apiEndPoint + url + ids).then(function(res) {
				this.dettaglio_persone = res.data;
				if(this.dettaglio_persone.length > 0) {
					this.titleModal = this.dettaglio_persone[0].cognome + ' ' + this.dettaglio_persone[0].nome;
					this.$refs.infoModal.show();
				} else {
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Nessuna persona trovata!', status: 0 });
				}
			});
		},

		chiudi(type, id) {
			if(confirm('Confermi di voler "chiudere" la modifica?')) {
				var campo = 'persona_id';
				if(type == "App\\Models\\Giocatori") {
					campo = 'id_giocatore';
				} 
				this.$http.get(this.$store.state.apiEndPoint +'/persona/modificata/chiudi/' + id, {params: {campo: campo}} ).then(function(res) {
					this.getModificati();
					this.getPersoneCheck();
				});
			}
		},

		riapri(type, id) {
			if(confirm('Confermi di voler "riaprire" la modifica?')) {
				var campo = 'persona_id';
				if(type == "App\\Models\\Giocatori") {
					campo = 'id_giocatore';
				} 
				this.$http.get(this.$store.state.apiEndPoint +'/persona/modificata/apri/' + id, {params: {campo: campo}} ).then(function(res) {
					this.getModificati();
					this.getPersoneCheck();
				});
			}
		},

		isRisolto(type, id) {
			if(type == "App\\Models\\Persona" && this.persona_ids.includes(id)) {
				return true;	
			} else if(type == "App\\Models\\Giocatori" && this.giocatore_ids.includes(id)) {
				return true;			
			} 
			return false;
		},
	},

	filters: {
		dateFormat: function (date) {
			if(date) {
				return moment(date).format('DD/MM/YYYY');
			} 
			return null;
		},
		dateTimeFormat: function (date) {
			return moment(date).format('DD/MM/YYYY HH:mm');
			
		}
	}

}
</script>
