<template>
	<span>
		<b-row @click.prevent="results && results.length ? ux.show = true : ''" style="cursor: pointer;height: 28px" v-if="showGrade">
			<b-col :style="'line-height: 1;' + (showAmount(5)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold;font-size:1.5rem"><b-badge style="background-color: #46bdc6;width:90%;" class="p-1">{{ showAmount(5) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(4)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold;font-size:1.5rem"><b-badge style="background-color: #34a853;width:90%;" class="p-1">{{ showAmount(4) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(3)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold;font-size:1.5rem"><b-badge style="background-color: #ff6d01;width:90%;" class="p-1">{{ showAmount(3) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(2)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold;font-size:1.5rem"><b-badge style="background-color: #ea4336;width:90%;" class="p-1">{{ showAmount(2) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(1)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold;font-size:1.5rem"><b-badge style="background-color: #674ea7;width:90%;" class="p-1">{{ showAmount(1) }}</b-badge></div></b-col>
		</b-row>
		<b-button size="sm" @click.prevent="ux.show = true" block variant="outline-secondary" v-else-if="results && results.length">Valutazioni {{ results.length }}</b-button>

		<b-sidebar @hidden="ux.show = false" ref="mod" :visible="ux.show" width="80%" backdrop class="text-left" :title="thePlayer.nome_breve">
			<div class="mx-2 mb-3">
				<b-row>
					<b-col>
						<b-list-group v-for="valutazione in results">
							<b-list-group-item style="cursor:pointer;">
								<div @click.prevent="selectValutazione(valutazione)">
									<p class="lead mb-0"><span class="text-muted">Valutazione</span> {{ valutazione.glossario.nome }}</p>
									<b-row align-v="center">
										<b-col cols="3">{{ valutazione.persona.nomeCompleto }}<br/>{{valutazione.created_at | date}}</b-col>
										<b-col cols="1"><div style="font-weight: bold;font-size:2rem"><b-badge :style="'width:90%;background-color:' + getBgColor(getVotoValutazione(valutazione))" class="p-1">{{ getVotoValutazione(valutazione) }}</b-badge></div></b-col>
										<b-col cols="7">{{ getGiudizioValutazione(valutazione) }}</b-col>
										<b-col cols="1" class="text-right"><b-button size="sm" @click.stop="deleteValutazione(valutazione.id)"><i class="fa fa-trash"></i> </b-button></b-col>
									</b-row>
								</div>
								<b-row v-if="currentValutazione && currentValutazione.id == valutazione.id">
									<b-col>
										<singola-valutazione v-if="currentValutazione" :valutazione="currentValutazione"></singola-valutazione>
										<b-alert v-else variant="secondary" show>Seleziona una valutazione dalla lista.</b-alert>
										<div class="text-right my-1"><b-button size="sm" @click.stop="currentValutazione = null">Chiudi</b-button></div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
			</div>
		</b-sidebar>
	</span>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment'

export default {
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY')
		}
	},
	components: {
		SingolaValutazione: require('@/components/layout/Valutazioni/SingolaValutazione.vue').default,
	},
	data: function() {
		return {
			currentValutazione: null,
			results: null,
			ux: {
				show: false
			}
		}
	},
	props: ['address', 'refresh', 'showGrade', 'player'],
	watch: {
		address: {
			immediate: true,
			handler: function() {
				this.fetchAddress();
			}
		},
		refresh: function() {

		}
	},
	computed: {
		overallEvaluation: function() {
			return _.map(_.map(this.results, 'evaluation.conclusion.total.value'), function(i) {
				return _.ceil(i);
			});
		},
		thePlayer: function () {
			var player = {
				nome_breve: this.player.shortName		
			}
			return player;	
		}
	},
	methods: {
		getBgColor(value) {
			var color = '#FFFFFF';
			var valore = _.ceil(value);
			if(valore == 5) {
				color = '#46bdc6';
			} else if(valore == 4) {
				color = '#34a853';
			} else if(valore == 3) {
				color = '#ff6d01';
			} else if(valore == 2) {
				color = '#ea4336';
			} else if(valore == 1) {
				color = '#674ea7';
			}
			return color;
		},
		getVotoValutazione(val) {
			return val.evaluation.conclusion.total.value;
		},
		getGiudizioValutazione(val) {
			return val.evaluation.conclusion.total.note;
		},
		selectValutazione(val) {
			if(this.currentValutazione && this.currentValutazione.id == val.id) {
				this.currentValutazione = null;
			} else {
				this.currentValutazione = val;
			}
		},
		deleteValutazione: function(id) {
			if(confirm("Confermi di voler eliminare la valutazione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/delete/' + id).then(function(data) {
					if(this.currentValutazione && this.currentValutazione.id == id) {
						this.currentValutazione = null;
					}
					this.fetchAddress();
				});
			}
		},

		fetchAddress: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: this.address }).then(function(data) {
				this.results = data.data;
			});
		},

		showAmount: function(amount) {
			return this.overallEvaluation ? _.filter(this.overallEvaluation, function(p) { return p == amount; }).length : 0;	
		//	var i = _.filter(this.overallEvaluation, function(p) { return p == amount; }).length;
		//	if(i) return '('+i+')'
		},
	}
}

</script>