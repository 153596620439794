<template>
	<div>
		<!-- Header -->
		<ValutazioneGiocatoreHeading v-if="!!heading && !hideTitle"
			:heading="heading"
			:isOnlyRead="isOnlyRead"
			@remove="$emit('remove')"
		/>
		
		<!-- Groups -->
		<template v-if="!!groups">
			<ValutazioneGiocatoreGroup 
				v-for="group in groups"
				class="mb-2"
				:key="group.slug"
				:isOnlyRead="isOnlyRead"
				:group="group"
				:evaluation="evaluationMap"
			/>
		</template>

		<!-- Footer -->
		<ValutazioneGiocatoreFooter v-if="!!footer"
			:footer="footer"
			:evaluation="evaluationMap"
			:isOnlyRead="isOnlyRead"
			@remove="$emit('remove')"
		/>

	</div>
</template>

<script>

export default {
	props: [ 'evaluation', 'glossario', 'isOnlyRead', 'hideTitle' ],
	components: {
		ValutazioneGiocatoreHeading: require('@/components/layout/Valutazioni/GloConfigPartials/ValutazioneGiocatoreHeading.vue').default,
		ValutazioneGiocatoreFooter: require('@/components/layout/Valutazioni/GloConfigPartials/ValutazioneGiocatoreFooter.vue').default,
		ValutazioneGiocatoreGroup: require('@/components/layout/Valutazioni/GloConfigPartials/ValutazioneGiocatoreGroup.vue').default,
	},
	data: function() {
		return {
			config: {},
			heading: null,
			groups: null,
			evaluationMap: null
		}
	},
	created: function() {
		this.setConfig()
	},
	methods: {
		setConfig: function() {			
			this.config = this.glossario.config
			this.buildEvaluationModel()
						
			this.setEvaluationHeading()
			this.setEvaluationGroups()
			this.setEvaluationFooter()
		},
		buildEvaluationModel: function() {
			if( this.evaluation === null ){	
				this.evaluationMap = _.reduce(this.config.groups, (groupReducer, group) => {
					
					const kpisMap = _.reduce(group.kpis, (kpiReducer, kpi) => {
						kpiReducer[kpi.slug] = { value: null, note: null };
						return kpiReducer;
					}, {});
					
					kpisMap['total'] = { value: null, note: null }

					groupReducer[group.slug] = kpisMap
					return groupReducer;
				}, {});
				
				const totalSlug = this.config.result.slug
				this.evaluationMap[totalSlug] = {}
				this.evaluationMap[totalSlug]['total'] = { value: null, note: null }
				
				this.$emit( 'update:evaluation', {...this.evaluationMap})
			}else{
				this.evaluationMap = {...this.evaluation}
			}
		},
		setEvaluationHeading: function() {
			this.heading = {
				label: this.config.label,
				label_style: this.config.label_style,
				slug: this.config.slug,
				has_description: this.config.has_description,
				input_value: this.config.input_value,
			}
		},
		setEvaluationGroups: function() {
			this.groups = this.config.groups
		},
		setEvaluationFooter: function() {				
			this.footer = {
				...this.config.result
			}
		},
		calculateTotals: function() {
			let media = 0
			let count = 0

			_.each(_.keys(this.evaluationMap), function(groupKey) {
				if(groupKey != 'total') {
					const value = this.evaluationMap[groupKey].total.value;					
					
					if(value !== null) {
						media += parseFloat(value);
						count++;
					}
				}	
			}.bind(this));
			
			if(count > 0) {
				media = Math.round(media/count * 100) / 100;
				this.evaluationMap[this.footer.slug].total.value = media
			} else {
				this.evaluationMap[this.footer.slug].total.value = null
			}

			this.$emit( 'update:evaluation', {...this.evaluationMap})
		}
	},
	watch: {
		evaluationMap: {
			deep: true,
			handler: function() {
				if(this.evaluationMap) {
					this.calculateTotals()
				}
			}
		}
	},
}

</script>