<template>
	<div>
		<b-overlay :show="ux.loading">
			<b-row>
				<b-col>
					<b-list-group v-for="valutazione in filteredResults" :key="valutazione.id">
						<b-list-group-item style="cursor:pointer;">
							<div @click.prevent="selectValutazione(valutazione)">
								<b-row>
									<b-col>
										<p class="lead mb-0"><span class="text-muted">Valutazione</span> {{ valutazione.glossario.nome }}</p>
									</b-col>
									<b-col class="text-right">
										<p class="mb-0">{{ valutazione.persona.nomeCompleto }}<br/>{{valutazione.created_at | date}}</p>
									</b-col>
								</b-row>
								<b-row align-v="center">
									<b-col cols="3">
										<div v-if="valutazione.partita_valutata">
											<div>{{ valutazione.partita_valutata.partita.competizione.paese.paese }} - {{ valutazione.partita_valutata.partita.competizione.nome }}</div>
											<div>{{ getTeamHome(valutazione.partita_valutata.partita).officialName }} - {{ getTeamAway(valutazione.partita_valutata.partita).officialName}}</div>
											<div>{{ valutazione.partita_valutata.partita.data | formatDateHour }}</div>
										</div>
										<!--div>{{ valutazione.persona.nomeCompleto }}<br/>{{valutazione.created_at | date}}</div-->
									</b-col>
									<b-col cols="1"><div style="font-weight: bold;font-size:2rem"><b-badge :style="'width:90%;background-color:' + getBgColor(getVotoValutazione(valutazione))" class="p-1">{{ getVotoValutazione(valutazione) }}</b-badge></div></b-col>
									<b-col cols="7">{{ getGiudizioValutazione(valutazione) }}</b-col>
									<b-col cols="1" class="text-right"><b-button size="sm" @click.stop="deleteValutazione(valutazione.id)"><i class="fa fa-trash"></i> </b-button></b-col>
								</b-row>
							</div>
							<b-row v-if="currentValutazione && currentValutazione.id == valutazione.id">
								<b-col>
									<singola-valutazione v-if="currentValutazione" :valutazione="currentValutazione"></singola-valutazione>
									<b-alert v-else variant="secondary" show>Seleziona una valutazione dalla lista.</b-alert>
									<div class="text-right my-1"><b-button size="sm" @click.stop="currentValutazione = null">Chiudi</b-button></div>
								</b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>
				</b-col>
			</b-row>
		</b-overlay>
	</div>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment'
import _ from 'lodash'

export default {
	props: [ 'player', 'evaluationFilters' ],
	watch: {
		player: {
			immediate: true,
			handler: function() {
				this.fetchAddress();
			}
		}
	},
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY')
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},

	},
	components: {
		SingolaValutazione: require('@/components/layout/Valutazioni/SingolaValutazione.vue').default,
	},
	data: function() {
		return {
			currentValutazione: null,
			results: null,
			ux: {
				loading: false
			}
		}
	},
	computed: {
		filteredResults: function() {
			let filteredResults = []

			if( this.results !== null ) {
				const total_from = this.evaluationFilters ? this.evaluationFilters.total_from : null
				const total_to = this.evaluationFilters ? this.evaluationFilters.total_to : null
				
				filteredResults = _.cloneDeep(this.results)

				if( total_from !== null && total_to !== null && !isNaN(total_from) && !isNaN(total_to) ){
					filteredResults = filteredResults.filter( 
						( result ) => result.evaluation.conclusion.total.value > total_from && result.evaluation.conclusion.total.value <= total_to
					)
				}

			}

			return filteredResults;
		}
	},
	methods: {
		getBgColor(value) {
			var color = '#FFFFFF';
			var valore = _.ceil(value);
			if(valore == 5) {
				color = '#46bdc6';
			} else if(valore == 4) {
				color = '#34a853';
			} else if(valore == 3) {
				color = '#ff6d01';
			} else if(valore == 2) {
				color = '#ea4336';
			} else if(valore == 1) {
				color = '#674ea7';
			}
			return color;
		},
		getVotoValutazione(val) {
			return val.evaluation.conclusion.total.value;
		},
		getGiudizioValutazione(val) {
			return val.evaluation.conclusion.total.note;
		},
		selectValutazione(val) {
			if(this.currentValutazione && this.currentValutazione.id == val.id) {
				this.currentValutazione = null;
			} else {
				this.currentValutazione = val;
			}
		},
		getTeamHome(partita) {
			if(!partita)
				return null; 
			var squadre = partita.squadre;
			if(squadre && squadre.length > 1) {
				if(squadre[0].pivot.side == 'home') {
					return squadre[0];
				} else {
					return squadre[1];
				}
			}
			return null;
		},
		getTeamAway(partita) {
			if(!partita)
				return null; 
			var squadre = partita.squadre;
			if(squadre && squadre.length > 1) {
				if(squadre[0].pivot.side == 'home') {
					return squadre[1];
				} else {
					return squadre[0];
				}
			}
			return null;
		},
		deleteValutazione: function(id) {
			if(confirm("Confermi di voler eliminare la valutazione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/delete/' + id).then(function(data) {
					if(this.currentValutazione && this.currentValutazione.id == id) {
						this.currentValutazione = null;
					}
					this.fetchAddress();
					this.$emit('evalutationDeleted', id);
					this.$emit('refresh', this.player.persona.id);
				});
			}
		},
		fetchAddress: function() {
			this.ux.loading = true;
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: { Persona: this.player.persona.id } }).then(function(data) {
				this.ux.loading = false;
				this.results = data.data;
			});
		}
	}
}

</script>