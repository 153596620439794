<template>

    <b-card>
        <!-- Group Heading Table -->
        <template slot="header">
            <b-row align-v="center">
                <b-col>
                    <span :style="group.label_style">
                        {{group.label}} 
                    </span>
                </b-col>
                <b-col>
                    <b-row align-v="center">
                        <b-col cols="9">
                            <b-input 
                                v-if="group.has_description" 
                                placeholder="Descrizione" size="sm" 
                                :readonly="isOnlyRead"
                            />
                        </b-col>
                        <b-col cols="3">
                            <Smart-Tag
                                v-if="group.input_value.visible"
                                placeholder="Value"
                                :readonly="!group.input_value.editable || isOnlyRead" 
                                v-model="evaluation[group.slug].total.value"
                            >
                            </Smart-Tag>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </template>
        
        <!-- KPIS Table -->
        <div v-for="kpiGroup in groupedKpis">
            <b-table-simple small striped>
                <b-thead>
                    <b-tr>
                        <b-th width="50%"></b-th>
                        <b-th width="35%"></b-th>
                        <b-th width="15%">Val</b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <ValutazioneGiocatoreKpiRow 
                        v-if="!!kpiGroup.headingKpi"
                        :key="kpiGroup.headingKpi.slug"
                        :kpi="kpiGroup.headingKpi"
                        :group="group"
                        :evaluation="evaluation"
                        :isOnlyRead="isOnlyRead"
                    />
                    <ValutazioneGiocatoreKpiRow 
                        v-for="kpi in kpiGroup.kpis"
                        :key="kpi.slug"
                        :kpi="kpi"
                        :group="group"
                        :evaluation="evaluation"
                        :isOnlyRead="isOnlyRead"
                        @update-subgroup-kpi="subgroupValue( kpiGroup, group.slug )"
                    />
                </b-tbody>
            </b-table-simple>
        </div>
    </b-card>

</template>

<script>

export default {
	props: [ 'group', 'evaluation', 'isOnlyRead' ],
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default,
		ValutazioneGiocatoreKpiRow: require('@/components/layout/Valutazioni/GloConfigPartials/ValutazioneGiocatoreKpiRow.vue').default
	},
	methods: {
        groupValue: function( groupKey, hasGroupedKeys ){      
                  
            let media = 0
            let count = 0
            
            let headings = this.group.kpis
                                .filter( ( kpi ) => {
                                    return kpi.is_heading
                                } )
                                .map( ( heading ) => heading.slug )
            
            _.each(_.keys(this.evaluation[groupKey]), function(kpiKey) {
                if( kpiKey != 'total' && ( !hasGroupedKeys || ( hasGroupedKeys && headings.includes(kpiKey) ) ) ) {
                    const value = this.evaluation[groupKey][kpiKey].value;
                    if(value !== null && !isNaN(value) ) {
                        media += parseFloat(value);
                        count++;
                    }
                }
            }.bind(this));

            if(count > 0) {
                media = Math.round(media/count * 100) / 100;
                this.evaluation[groupKey].total.value = media
            } else {
                this.evaluation[groupKey].total.value = null
            }

            return this.evaluation[groupKey].total.value
        },
        subgroupValue: function( kpiGroup, groupKey ){
        
            if( kpiGroup.is_heading ) {
                const headingKey = kpiGroup.headingKpi.slug
                let media = 0
                let count = 0

                _.each( kpiGroup.kpis , function( kpi ) {
                    const kpiKey = kpi.slug
                    const value = this.evaluation[groupKey][kpiKey].value;
                    
                    if(value !== null && !isNaN(value) ) {
                        media += parseFloat(value);
                        count++;
                    }
                }.bind(this));

                if(count > 0) {
                    media = Math.round(media/count * 100) / 100;
                    this.evaluation[groupKey][headingKey].value = media
                } else {
                    this.evaluation[groupKey][headingKey].value = null
                }
            }
            
            this.groupValue(groupKey, kpiGroup.is_heading)
        }
	},
    computed: {
        groupedKpis: function(){
            let groupedKpis = []
            let currentBlock = { is_heading: false, headingKpi: false, kpis: [] };

            this.group.kpis.forEach((kpi) => {
                
                if (kpi.is_heading) {
                    if (currentBlock.kpis.length > 0) {
                        groupedKpis.push(currentBlock);
                    }
                    currentBlock = {
                        is_heading: true,
                        headingKpi: kpi,
                        kpis: []
                    };
                } else {
                    currentBlock.kpis.push(kpi);
                }

            });

            if (currentBlock.kpis.length > 0) {
                groupedKpis.push(currentBlock);
            }

            return groupedKpis
        }
    }
}

</script>